.thanku_msgs{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.availability-filter .date-col{width: auto;}
.availability-records .log-head-items
{
    padding:10px 10px;
}
.availability-records .log-head-items .col
{
    font-weight: 900;
    font-size: 15px;
    font-weight: 600;
}
.availability-records .log-body-items
{
    padding:10px 10px;
    margin-top:10px;
}
.availability-records .rbt input[type="text"]
{
    height:33px;
}

.availability-records #filter .search-btn button {
    height: 49px;
    width: 49px;
    padding: 0px;
    margin-top: 0;
}
.devcie-detail{cursor: pointer;}
.devcie-detail.mobile, .availability-records .time-exceeded
{font-weight: 900; font-weight: 600;}
.availability-filter .rbt .dropdown-menu{width: 99.8% !important;}
.online_check_box{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}
.online_check_box .min-2{
    padding: 8px !important;
    border-radius: 8px;
    background: #F0F6FA;
    height: auto;
    width: auto;
    display: flex;
    margin-right: 15px;
}
.online_check_box .form-check-input{
    top: -2px;
}
.online_check_box .device-mobile{
    padding: 8px !important;
    border-radius: 8px;
    background: #F0F6FA;
    height: auto;
    width: auto;
    display: flex;
    
}