.section_title {
    text-align: left;
    font-size: 24px !important;
    padding:20px 0 15px 0!important;
    letter-spacing: .25px;
    padding-top:0px;
    font-weight: 600;
}
.dashboard-card{
    max-width: 400px;
    padding: 35px 20px;
}
.dashboard-card-title {
    font-size: 16px;
    color: #212529;
  
    letter-spacing: .25px;
    text-align: center;
    padding-bottom: 25px;
    border-bottom: 1px solid #D3D3D3;
}
.qa-card .das-qa {
    font-size: 14px;
    font-weight: 600;
    color: #212529;
    letter-spacing: .25px;
    margin: 0;
    padding: 0;
    line-height: 24px;
    margin-top: -4px;
    padding-right: 10px;
    width: 80%;
    float: left;
    display: flex;
    align-items: center;
    min-height: 48px;
  
}
.qa-card {
    padding: 30px 0;
    justify-content: space-between;
    border-bottom: 1px solid #DEE2E6;
    align-items: center;

}
.checkqa {
	width: 64px;
	height: 48px;
  font-weight: 600;
	cursor: pointer;
	color: #212529;
	border-radius: 2px;
	background: #EFF5FA;
  font-size: 14px;
	border: none;
	-webkit-transition: 0.3s;
	transition: 0.3s;
	margin: 0 0 0 10px !important;
  border-radius: 8px;
  margin: 0;
  padding: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  }
  .checkqa:hover{
    background: #129482;
    color: #fff;
    border: 1px solid #129482;
  }
  
 .button-wrap{
  display: flex;
  justify-content: flex-end;

 }
 .qa_addcomment {
  float: left;
  width: 100%;
}
.textareabox {
  font-size: 14px !important;
  border: none;
  resize: none;
  background: #F3FAFF;
  width: 100%;
  padding: 15px;
  color: #A0A0A0;
  letter-spacing: .10px;
  margin-top: 10px;
  outline: none;
  min-height: 105px;
  border-radius: 8px;
}
.textareabox::placeholder{
  color: #83909D !important;
  font-size: 14px;
  font-weight: 400;
}
.add_comment_btn {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  border-radius: 8px;
  padding: 5px 8px;
  background: #129482;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  margin-top: 15px;
  max-width: 120px;
  width: 100%;
  min-height: 40px;
  font-family: 'Poppins', sans-serif !important;
}
.desiger_listchoose{
  background: #F7F7F7;
  padding: 15px 20px;
}
.chos-designr label{
  margin-bottom: 0;
  color: #636363;
  font-size: 14px;
  letter-spacing: .25px;
}
.chos-designr {
  border-bottom: 1px solid #D3D3D3;
  padding: 5px 0;
  
}
.chos-designr input[type="checkbox"]:after{
  top: 3px;
}
.qa-tab-wrapper{
  background: #fff;
  border-radius: 5px;
  margin-bottom: 60px;
  padding-bottom: 30px;
}
.qa-tab-wrapper .react-tabs__tab{
  width: 33.33%;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 16px;
  color: #219186;
  letter-spacing: .25px;
  border: 0;
  font-weight: 400 !important;
}
.qa-tab-wrapper .react-tabs__tab-list{
  border: 0;
  margin: 0;
}
.qa-tab-wrapper .react-tabs__tab--selected{
  background: #129482;
  color: #fff;
  border: 0 !important;
  border-radius: 8px !important;
  font-weight: 500 !important;
}
/* .qa-tab-wrapper .react-tabs__tab-panel{
   border-bottom:1px solid #DEE2E6;
} */
.checkqa.green {
  background-color: #129482;
  color: #fff;
  border: 0;
}
.outof{
  color: #636363;
  font-size: 16px;
  padding: 15px 0;
 
  text-align: center;
  display: block;
}

.second-level-questions
{
  margin-top: 40px;
}

.answers-part{
  margin-top: 30px;
}

.answers-part .textareabox{
  margin-left: 10px;
  margin-top: 0px;
 
}
.answers-part .react-tags__search-input {
  width: 100%!important;
}
.answers-part .react-tags__search{width: 260px;}
.single-type-qr{visibility: hidden; margin-bottom: 10px;}
.card.without-tabs{padding:0px; margin-bottom: 40px; padding-bottom: 30px;}
.card.without-tabs.no-questions{padding:40px; text-align: center; font-size: 15px;}
.cant-edit .react-tags__selected-tag::after{
  display: none;
}
.cant-edit .react-tags__selected-tag
{
  cursor: not-allowed;
}
.cant-edit .react-tags__search-input{display:none;}
.qr-report .react-tags{background: #f7f7f7!important;}
.qa_aire_card{
  padding: 30px;
  background: #fff;
  border-radius: 16px;
}
.qa_aire_card h2.section_title{
  margin: 0;
  padding: 0 0 30px 0 !important;
  text-align: left;
}
.qa_aire_card .react-tabs__tab-list{
  padding: 10px !important;
  background: #E0F9F7;
  border-radius: 8px;
}