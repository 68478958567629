.inter_timer_btn{
    min-height: 44px;
    border-radius: 8px;
    background: #EFF5F9;
    border: 0 !important;
    box-shadow: none;
    padding: 10px;
    position: relative;
    margin-left: 15px;
    display: flex;
    align-items: center;
    color: #212529;
    min-width: 210px;
}
.inter_timer_btn span.play_icon{
    margin: 0 10px 0 0;
    padding: 0;
    line-height: 0;
    }
.inter_timer_btn span.drop_dowm_icon{
    margin: 0 0 0 10px;
    padding: 0;
    line-height: 0;
}
.inter_timer_btn:hover{
    background: #212529;
    color: #fff;
}
.inter_timer_btn:hover .material-icons{
    color: #fff;
}
.inter_timer_btn:focus{
    box-shadow: none !important;
    background: #212529 !important;
    color: #fff !important;
}
.inter_timer_btn:focus .material-icons{
    color: #fff;
}
.it_dropdownbox{
    position: absolute;
    width: calc(500px - 50px);
    max-width: 500px;
    border-radius: 12px;
    -webkit-box-shadow: 0px 12px 24px 0px rgba(108,161,206,0.08);
    -moz-box-shadow: 0px 12px 24px 0px rgba(108,161,206,0.08);
    box-shadow: 0px 12px 24px 0px rgba(108,161,206,0.08);
    background: #fff;
    padding: 25px;
    right:0;
    margin-top: 15px;
    z-index: 2;
}
.overlay_it{
    position: fixed;
    background: rgba(0, 0, 0, 0.16);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
}
.paly_stop_icon{
    width: 40px;
    height: 40px;
    background: #EFF5F9;
    border-radius: 6px;
    box-shadow: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
ul.intermal_timer_dropdown{
    margin: 0;
    padding: 0;
}
ul.intermal_timer_dropdown li{
    display: flex;
    list-style: none;
    padding:10px 0;
    border-bottom: 1px solid #DEE2E6;
    justify-content: space-between;
}
ul.intermal_timer_dropdown li:last-child{
    border-bottom: none !important;
}
.runtion_time_it{
    font-size: 14px;
    color: #212529;
    display: flex;
    align-items: center;

}
.it_dropdownbox h3{
    color: #212529;
    margin: 0;
    padding: 0 0 10px 0;
    font-size: 16px;
    font-weight: 600;
}
h4.task_titile_it{
    font-size: 14px;
    color: #212529;
    font-weight: 400;
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin: 0;
}
.paly_stop_icon .material-icons{
    color: #6C757D;
}
li.active_task_it .runtion_time_it{
    font-weight: 600;
}
li.active_task_it span.paly_stop_icon{
    background: #FFEFF2 !important;
}
li.active_task_it span.paly_stop_icon .material-icons{
    color: #E72626 !important;
}
.active_task_it .inter_timer_btn{
 background: #E72626;
 color: #fff;
}
.active_task_it .inter_timer_btn .material-icons{
    color: #fff;
}
.active_task_it .stop_icon{
    display: block;

}
.active_task_it .play_icon{
    display: none;
}
.stop_icon{
    margin: 0 10px 0 0;
    padding: 0;
    line-height: 0;
    display: none;
}
.stoptask{
  display: none;
}
.active_task_it .paly_stop_icon{
    display: none;
}
.active_task_it .stoptask{
    display: flex !important;
    align-items: center;
    justify-content: center;
}
.active_task_it  .task_titile_it{
    font-weight: 600;
}
.assisting_tm_car h3{
    display: flex;
    align-items: center;
   line-height: 0;
}
.assisting_tm_car h3 button{
    background: transparent;
    margin: 0;
    padding: 0;
    border: 0;
}
.assisting_tm_car h3 button .material-icons{
    font-size: 20px;
    color: #212529;
    margin-right: 10px;

}
.assisting_tm_car label{
    color: #495057;
    font-size: 13px;
    font-weight: 500;
    margin: 0;
    padding: 0;
}
.assisting_tm_car .form-control{
    border: 1px solid #ADB5BD;
    font-size: 14px !important;
    color: #212529;
    border-radius: 8px !important;
}
.it_timerbtn{
    margin: 0 auto;
    min-width: 180px;
    background: #219186;
    border: #219186;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 48px;
    border-radius: 8px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}
.assisting_tm_car{
    display: none;
}
.active_choose_member .assisting_tm_car{
    display: block;
}
.active_choose_member .intermal_timer_card{
    display: none;
}

.activity_filterbox label{
    color: #495057;
    font-size: 13px;
    font-weight: 500;
    margin: 0;
    padding: 0
}
.activity_filterbox .form-control{
    border: 1px solid #ADB5BD;
    font-size: 14px !important;
    color: #212529;
    border-radius: 8px !important;
    min-height: 48px;
}
.team_member_list{
    position: relative;
}
.team_member_list .row{
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
}
.team_member_list .row::before{
    content: "";
    position: absolute;
    bottom: 0;
    left: 15px;
    right: 15px;
    background: #DEE2E6;
    height: 1px;
}
.team_member_list_card{
    display: flex;
    align-items: center;
}
.team_member_list_card .user_pic{
    min-width: 56px;
    height: 56px;
    border-radius: 8px;
    background: #219186;
    display: flex;
    margin-right: 15px;
}
.team_member_list_card h2{
    font-size: 16px;
    font-weight: 400;
    color: #212529;
}
.button_grp_activitys{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
}
.button_grp_activitys button{
    background: transparent;
    border: 1px solid #CED4DA;
    border-radius: 8px;
    color: #495057;
    font-size: 14px;
    font-weight: 600;
    margin-left: 10px;
    min-height: 44px;
    padding-left: 15px;
    padding-right: 15px;
}
.button_grp_activitys button:hover{
    background: #219186;
    border: 1px solid #219186;
    color: #fff;
}
.button_grp_activitys button:focus{
    border: 1px solid #004c3f !important;
}
.all_activity_poper{
    display: none;
}
.active_all_activity_poper .all_activity_poper{
    display: block;
    background: #fff;
    position: absolute;
    top: 0;
    width: 100%;
    padding: 30px;
    width: calc(102% - -29px);
    left: -30px;
    right: -30px;
    border-radius: 12px;
}
.all_activity_poper h3{
    padding: 0 !important;
    margin: 0 !important;
    font-weight: 600;
    font-family: 'Poppins', sans-serif !important;
    text-align: left !important;
    color: #212529 !important;
    font-size: 20px;
    letter-spacing: 0.00938em;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.all_activity_poper .back_arow_icon{
    background: #EFF5F9;
    width: 32px;
    height: 32px;
    border-radius: 6px;
    border-color: #EFF5F9;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.all_activity_poper .back_arow_icon:hover{
    background: #219186;
    border-color: #219186;
}
.all_activity_poper .back_arow_icon:hover .material-icons, .all_activity_poper .back_arow_icon:focus .material-icons{
    color: #fff;
    
}
.all_activity_poper .back_arow_icon .material-icons{
    color:#6C757D;
    font-size: 18px;
}
.current_activity_list_content h3{
    margin: 0 ;
    padding: 0 0 5px 0 !important;
    font-size: 16px;
    color: #212529;
    font-weight: 400;

}
.client_name_bold{
    font-weight: 600;
    display: contents;
}
.date_n_time_calc{
    color: #212529;
    font-size: 12px;
    font-weight: 300;
}
.active_all_activity_poper .current_activity_list .row::before{
   display: none;
}
.all_old_activities h4{
    padding: 25px 0 !important;
    margin: 0;
    font-weight: 600;
    font-family: 'Poppins', sans-serif !important;
    text-align: left !important;
    color: #212529 !important;
    font-size: 20px;
    letter-spacing: 0.00938em;
 
}
.urgnet_message_modal{
    position: relative;
}
.urgnet_message_modal .modal-content{
    border: none !important;
    padding: 30px;
    border-radius: 12px;
    -webkit-box-shadow: 0px 12px 24px 0px rgba(108,161,206,0.08);
    -moz-box-shadow: 0px 12px 24px 0px rgba(108,161,206,0.08);
    box-shadow: 0px 12px 24px 0px rgba(108,161,206,0.08);
}
.urgnet_message_modal .modal-header{
    padding: 0;
    border-bottom: none !important;

}
.urgnet_message_modal .modal-header h5{
    color: #212529;
    font-size: 20px;
    font-weight: 600 !important;
    letter-spacing: .3px;
}
.urgnet_message_modal .modal-body{
    padding: 25px 0 0 0;
    border-bottom: 0 !important;
}
.add_message_box{
    border-radius: 8px !important;
    background: #F7F9FA !important;
    min-height: 110px;
    border-color: #fff !important;
    resize: none;
    padding: 15px !important;
}
.add_message_box::placeholder{
    color: #ADB5BD;
    font-size: 14px;
    font-weight: 400;
}
.form-control.add_message_box:focus{
    border-radius: 8px !important;
    background: #F7F9FA !important;
    min-height: 110px;
    border-color: #fff !important;
    resize: none;
}
.urgnet_message_modal .modal-footer{
    border-top: 0;
}
.close_btn_message{
    position: absolute;
    right: 30px;
    top: 30px;
    background: #F4F6F9;
    border: navajowhite;
    width: 40px;
    height: 40px;
    padding: 0;
    margin: 0;
    line-height: 0;
}
.close_btn_message .material-icons{
    font-size: 18px !important;
    color: #000 !important;
}
button.close_btn_message:hover{
    background: #219186;
    border-color: #219186;
}
button.close_btn_message:hover .material-icons{
    color: #fff !important;
}
button.close_btn_message:focus{
    box-shadow: none;
    border-color: #219186;
}
button.send_message_btn{
    width: 200px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 48px;
    background: #219186;
    border-color: #219186;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    margin: 15px auto 0 auto;
    border-radius: 8px;
}
button.send_message_btn:hover{
    background: #004c3f;
    border-color: #004c3f;
    box-shadow: none;
    color: #fff;
}
button.send_message_btn:focus{
    border-color: #004c3f !important;
}

.all_assignment_activity_card .react-tabs__tab-list{
    background: #E0F9F7;
    padding: 10px;
    min-height: 60px;
    border-radius: 8px;
    border: none;
    max-width: 500px;
    width: 100%;
}
.all_assignment_activity_card .react-tabs__tab{
    width: 50%;
    text-align: center;
    font-size: 16px;
    color: #219186;
    padding-top: 12px;
    padding-bottom: 12px;
}
.all_assignment_activity_card .react-tabs__tab--selected{
    background: #219186;
    color: #fff;
    border: none;
    border-radius: 8px;
}
.laa_search_filter{
    max-width: 380px;
    width: 100%;
    position: relative;
}
.laa_serchbox{
    position: relative;
    border-radius: 8px !important;
    border: 1px solid #ADB5BD !important;
    padding-left: 15px !important;
    padding-right: 40px !important;
    font-weight: 14px !important;
    color: #212529 !important;
    font-weight: 400 !important;
    min-height: 50px;
}
button.search_icon_laa{
    position: absolute;
    top: 0;
    right: 0;
    background: none !important;
    border: none;
    min-height: 50px;
    border: none !important;
    line-height: 0;
}
button.search_icon_laa .material-icons{
    color: #000;
    font-size: 20px;
}
button.search_icon_laa:hover, button.search_icon_laa:focus{
    background: none !important;
    border: none !important;
}
.laa_search_filter{
    margin: 20px 0;
}
.assignments_user_list .user_list_detail_laa{
     position: relative;
  
}

.assignments_user_list .user_laa_list::before{
    content: "";
    position: absolute;
    height: 1px;
    left: 0;
    right: 0;
    bottom: 0;
    background: #DEE2E6;
}
.assignments_user_list .user_list_detail_laa h4{
    margin: 0;
    padding: 0 0 5px 0;
    font-size: 16px;
    font-weight: 600;

}
.assignments_user_list .user_pic_laa{
    margin-right: 15px;
    min-width: 50px;
    height: 50px;
    border-radius: 8px;
    background: #E0F9F7;
}
.assignments_user_list .user_list_detail_laa span.jobrole_box{
    font-weight: 300;
    font-size: 14px;
    color: #212529;
    margin: 0;
    padding: 0;

}
.user_laa_list{
    display: flex;
    align-items: center;
    padding: 20px 0;
    position: relative;
}
.your_plate_card{
    background: transparent !important;
    box-shadow: none !important;
}
.plate_card{
    border-radius: 12px;
    background: #fff;
    padding: 25px;
    cursor: pointer;
    transition: all .4s ease-in-out;
    margin-bottom: 30px;
    min-height:193px
}
.plate_card:hover{
    -webkit-box-shadow: 0px 12px 24px 0px rgba(108,161,206,0.16);
    -moz-box-shadow: 0px 12px 24px 0px rgba(108,161,206,0.16);
    box-shadow: 0px 12px 24px 0px rgba(108,161,206,0.16);
}
.plate_card span.task-client-detail-plate{
    color: #495057;
    font-size: 14px;
    font-weight: 500;
    display: block;
}
.plate_card .task-project-name-palate{
    color: #212529;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    padding: 8px 0 0 0;
    display: block;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp:2;
    overflow: hidden;
    min-height: 65px;
    line-height: 26px;
}
.plate_card .date_and_time_platecard{
    margin: 0;
    padding:0;
    font-size: 14px;
    font-weight: 300;
    color: #212529;
    display: flex;
    align-items: center;
}
.plate_card .date_and_time_platecard .material-icons{
    font-size: 18px;
    color: #39C0ED;
    margin-right: 5px;
}

.labels-priority
{
    margin-top:10px;
    display: flex;
}

.labels-priority .task-label
{
    border: 1px solid #ccc;
    padding: 1px 5px;
    margin-right: 10px;
    border-radius: 4px;
    color:#495057
}

.labels-priority  .task-priority
{
    border: 1px solid #f11111;
    background: #f11111;
    padding: 1px 5px;
    margin-right: 10px;
    color: #fff;
    border-radius: 4px;

}

.plate-filters
{
    gap:15px;
}
.no-plate-task
{
    margin-bottom:25px;
}
.plate-filters .pl_list select.form-control{
    border: 1px solid #ADB5BD;
    min-height: 49px;
    border-radius: 8px !important;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 15px;
    font-weight: 400;
    color: #495057;
}
.plate-filters .pl_list select.form-control:focus{
    border: 1px solid #ADB5BD !important;
}
.pmTeamLeadSelector
{
    width: 180px;
    border: 1px solid #ADB5BD;
    min-height: 49px;
    border-radius: 8px !important;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 15px;
    font-weight: 400;
    color: #495057;
}