/*
 * List head styles
*/

#list-head {
  /* margin-top: 35px; */
  padding: 12px 0;
}

#list-head p {
  font-weight: 600;
}

#list-head .name {
  width: 500px;
}

#list-head .time-entered {
  width: 290px;
}

#list-head .total-rate {
  width: 140px;
}

#list-head .total-rate p {
  margin-right: 6px;
}

#list-head .total-rate label {
  font-size: 16px;
  color: #636363;
  font-weight: 600;
  margin-left: -8px;
}

#list-head .total-rate input:after {
  margin-left: 5px;
}

#list-head .projects {
  width: 250px;
}

#list-head .projects {
  margin-right: 40px;
}

#list-head .name {
  margin-left: 40px;
}

#list-head p {
  margin-bottom: 0;
  color: #636363;
  font-size: 16px;
}

#list-head .total-rate input:after {
  margin-top: 3px;
}