/* 
  Name: FeedComments
  Path: components/FeedComments/FeedCommentItem
*/

.feed_comment_wrapper {
  padding: 20px 40px;
  display: block;
  width: 100%;
}

#feed_general .feed_comment_wrapper {
    padding: 20px 15px;
}

.feed_comment_action p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  color: #212529;
}

.feed_comment_input {
  background: #fff;
	min-height: 67px !important;
	margin-bottom: 17px;
	border: none;
	font-size: 13px;
  border: 1px solid #ADB5BD !important;
  border-radius: 8px !important;
  resize: none;
}

.feed_comment_input:focus {
  box-shadow: none;
  border: 1px solid #aae4e4;
  background: #fcffff;
}

.feed_comment_input::placeholder {
  color: #ADB5BD;
  font-size: 14px;
  font-weight: 400;
}

.feed_comment_items_wrapper {
  padding: 20px;
  background: #F4F8FC;
  /* height: 160px; */
  height: auto;
  overflow: hidden;
  border-radius:8px;
}

.feed_comment_items_wrapper.fixed_height {
  /* height: 160px; */
  height: auto;
}

.feed_comment_items_wrapper.feed_comment_open {
  overflow: auto;
  height: 100%;
}

.loadMore_comment {
  width: 30px;
  height: 30px;
  background: #dfdfdf url(../assets/arrow.png) no-repeat center;
  background-size: 50% auto;
  display: block;
  margin:-18px auto 0 auto;
  border-radius: 30px;
  cursor: pointer;
}