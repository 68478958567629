/* LeaveCalender */
#leave_calender {
   background: #fff;
   border-radius: 16px;
   padding-bottom: 30px;
}

.leave_calender_details {
 margin: 30px 30px 0 30px;
 border-top:1px solid #DEE2E6;
 padding: 30px 0 0 0;
 overflow: hidden;
 position: relative;
}
#leave_calender  .LoadingBoxSmall{
  width: 100% !important;
  overflow: hidden;
  max-width: 100% !important;
  position: relative;
  left: 0;
  right: 0;
  height: 50px;
}
.leave_calender_details .category {
   padding-bottom: 24px;

}
.full-present{
  text-align: center;
  display: flex;
  flex-direction: column;
}
.full-present span{
  font-size: 40px;
}
.leave_calender_details .category.QA {
  border: none;
  margin-bottom: 5px;
}

.leave_calender_details * {
  margin-bottom: 0;
}

.leave_calender_details h4 {
  color: #212529;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 15px;
}

.leave_calender_details h5 {
  font-size: 16px;
  color: #495057;
  margin: 0 0 15px 0;
}

.leave_calender_details p {
  margin-bottom: 6px;
  font-size: 14px;
  color: #212529;
  font-weight: 400;
}
.leave_status_wrapper .category h5{
  display: flex;
  align-items: center;
}
.leave_status_wrapper .category h5 img{
  width: 32px;
  height: 32px;
  margin-right: 10px;
}
#leave_calender .react-calendar {
  width: 100%;
  color: #636363;
  border: none !important;
  padding: 0 30px;
  font-family: 'Poppins', sans-serif !important;
  box-shadow: none;
 }
 #leave_calender .react-calendar__navigation{
  display: flex;
  flex-direction: row;
 }
 #leave_calender .react-calendar .react-calendar__navigation__prev-button{
  width: 32px;
  height: 32px;
  font-size: 0 !important;
  background: url('../../../images/rcalen_prevbtn_icon.png') no-repeat right center !important;
  background-size: 80% !important; 
 }
 #leave_calender .react-calendar .react-calendar__navigation__next-button{
  width: 32px;
  height: 32px;
  font-size: 0 !important;
  background: url('../../../images/rcalen_next_btn.png') left center no-repeat !important;
  background-size: 80% !important; 
 }
 #leave_calender .react-calendar__navigation button:nth-child(2){order: 2; }
 #leave_calender .react-calendar__navigation button:nth-child(3){
   order: 1;
   color: #212529 !important;
   font-size: 16px !important;
   font-weight: 500 !important;
   text-align: left !important;
  }
 #leave_calender .react-calendar__navigation button:nth-child(4){order: 3;}
 #leave_calender .react-calendar__navigation button:hover{
  background: transparent;
}

 
#leave-react-calender .react-calendar [type=button]:not(:disabled), 
#leave-react-calender .react-calendar [type=reset]:not(:disabled),
#leave-react-calender .react-calendar [type=submit]:not(:disabled),
#leave-react-calender .react-calendar button:not(:disabled) {
 height: auto;
}

#leave-react-calender .react-calendar .react-calendar__month-view__weekdays__weekday {
  padding: 0;
  font-size: 14px;
  color: #212529;
  font-weight: 500;
  text-transform: capitalize !important;
}
#leave-react-calender .react-calendar .react-calendar__tile{
  padding:10px !important;
}
/* .react-calendar .react-calendar__month-view__days__day {
  width: 37px;
  height: 37px;
} */

.react-calendar .react-calendar__month-view__days__day--neighboringMonth {
  opacity: 0.2;
}

.react-calendar .react-calendar__tile--now abbr,
.react-calendar .react-calendar__month-view__days__day abbr {
  background: #069482;
  border-radius: 8px;
  width: 37px;
  height: 37px;
  line-height: 36px;
  text-align: center;
  display: block;
  margin: 0 auto;
}

.react-calendar .react-calendar__month-view__days__day abbr {
  background: transparent
}

.react-calendar .react-calendar__tile--now abbr {
  background: #069482;
  
}

.react-calendar .react-calendar__tile--now abbr {
  color: #fff;
}

.react-calendar .react-calendar__month-view__days {
  flex-basis: auto;
  max-width: auto;
  height: auto;
}

.react-calendar .react-calendar__tile--now:hover {
  background: #069482;
}

/* #leave-react-calender .react-calendar .react-calendar__navigation__label {
  font-size: 24px !important;
} */

.react-calendar__month-view__weekdays__weekday abbr {
  font-size: 14px;
  text-decoration: none;
}

.react-calendar__navigation__next2-button,
.react-calendar__navigation__prev2-button {
  display: none !important;
}

/* #leave-react-calender .react-calendar .react-calendar__navigation button {
  min-width: 141px;
} */

.react-calendar .react-calendar__navigation__arrow{
  color: #bdbdbd !important;
  font-size: 31px !important;
}

.react-calendar .react-calendar__navigation {
  margin-bottom: 24px;
}

.react-calendar .react-calendar__month-view__weekdays {

}

.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus{
  background: transparent !important
}

.react-calendar__tile:enabled:hover abbr, .react-calendar__tile:enabled:focus abbr,
.react-calendar .react-calendar__tile--active abbr {
  background: #069482;
  color: #fff;
}

.react-calendar .react-calendar__tile--active {
  background: transparent
}

/* Media Quries */
@media only screen and ( max-width: 1280px ) {
  #leave-react-calender .react-calendar .react-calendar__tile{
    padding:5px 0 !important;
  }
  .react-calendar .react-calendar__tile--now abbr, .react-calendar .react-calendar__month-view__days__day abbr{
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
}
@media only screen and ( max-width: 576px ) {
  #leave_calender, #leave_calender .react-calendar {
    padding: 15px;
  }

  #leave-react-calender .react-calendar .react-calendar__navigation button {
    width: auto !important;
    min-width: 40px !important
  }

  .react-calendar__tile {
    padding: 0;
  }

  .leave_calender_details {
    margin: 20px;
  }

  .leave_body .title, #leave_balence .title, #leave-balence .title {
    padding: 15px 0;
  }
}