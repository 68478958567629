.countries_box input
{
 color:#111!important;
 text-align: center;
 border-radius: 0px;
}
.team-updating.team_inner_box
{
 opacity: 0.6;
}
.team_control_wrapper{
    position: relative;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
 

}
.box_relative{
    position: relative;
    max-width: 200px;
width: 100%;
}
.team_fixed_box{
   background: #079483;
    color: #fff;
    width: 100%;
    max-width: 200px;
    display: flex;
    flex-direction: column;
    -webkit-border-top-left-radius: 5px;
    -webkit-border-bottom-left-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-bottomleft: 5px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    top: 0;
    left: 0;
    z-index: 999;
}
.team_frist_box{
    padding:10px 15px;
    font-weight: 600;
    border-bottom: 1px solid #038a7a;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.team_frist_box:last-child{
    border: none !important;
    height: 42px;
}
.team_inner_box {
    width: 100%;
    min-width: 141px;
    display: flex;
    flex-direction: column;
}
.team_inner_box span{
    padding: 10px 15px;
    color: #6c6c6c;
font-size: 14px;

min-height: 41px;
text-align: center;

    
}
.team_inner_box span:last-child{
    border-bottom: none !important;
}
.team_result_box{
    border-top: 1px solid #079483;
 
}
.team_name_box{
    color: #fff !important;
    font-weight: 800;
    background: #9e5676;
    border-right: 1px solid #44172c24;
    border-bottom: 1px solid #44172c24;
}
.shift_time_box{
    color: #fff !important;
    font-weight: 800;
    background: #9e5676;
    border-right: 1px solid #44172c24;
    border-bottom: 1px solid #44172c24;

}
.countries_box{
color: #fff  !important;
font-weight: 800;
background: #72294c;
    border-right: 1px solid #44172c24;
    border-bottom: 1px solid #44172c24;
}
.pmtl_name{
color: #fff !important;
font-weight: 800;
background: #4e808a;
border-right: 1px solid #44172c24;
border-bottom: 1px solid #44172c24;
}
.pm_name{
    color: #fff !important;
    font-weight: 800;
    background: #4e808a;
    border-right: 1px solid #44172c24;
    border-bottom: 1px solid #44172c24;
    white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
}
.desiner_name{
    background: #fff !important;
    border-right: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    white-space: nowrap;
text-overflow: ellipsis;
}
.design_bg{
    background: #296f66;
}
.save_btn{
    background: #079483;
border: 0;
margin: 10px auto;
display: flex;
max-width: 90px;
width: 100%;
height: 30px;
outline: none;
border-radius: 5px;
text-align: center;
align-content: center;
justify-content: center;
line-height: 30px;
color: #fff;
font-weight: 700;
font-size: 13px;
cursor: pointer;
}
.save_btn:hover{
    background: #000;
    color: #fff;
}
.team_result_box{
    display: flex;
    max-width: 1000px;
overflow: hidden;
overflow-x: scroll;
}
.team_control_wrapper span .form-control{
    margin:0;
    padding: 0;
    height: auto;
    border:none;
    color: #fff;
    background-size: 10px !important;
    font-size: 14px;
}
span.desiner_name .form-control{
    color:#111 !important;
}

.team_control_wrapper select.form-control{
    background-image: none !important;
   }
.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after{
display: none;
}
span.team_name_box{
    padding: 0;
}
span.team_name_box .form-control{
    padding: 10px 15px !important;
}
span.shift_time_box{
    padding: 0;
}
span.shift_time_box .form-control{
    padding: 10px 15px !important;
}
span.pmtl_name{
    padding: 0;
}
span.pmtl_name .form-control{
    padding: 10px 15px !important;
}
span.pm_name{
    padding: 0;
}
span.pm_name .form-control{
    padding: 10px 15px !important;
}
span.desiner_name{
    padding: 0;
}
span.desiner_name .form-control{
    padding: 10px 15px !important;
}

.desiner_name{
    background: #fff !important;
    border-right: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    white-space: nowrap;
text-overflow: ellipsis;
position: relative;
}
.note_add_here{
    width: 0;
    height: 0;
    padding-left:7%;
    padding-top: 7%;
    overflow: hidden;
    position: absolute;
    right: 0;
    z-index: 9999;
}
.note_add_here:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    margin-left:-500px;
    margin-top:-500px;
    
    border-left: 500px solid transparent;
    border-top: 500px solid #038a7a;
}

.note_text{
    position: absolute;
    bottom: 0;
    left: 0;
    background: #fff;
    width: 94%;
    z-index: 999;
    height: 10px;
}
.note_text:hover::after{
    content: "Add Note";
    font-size: 8px;
    top: 0;
    position: absolute;
    color: #079483;
    width: 93%;
    padding-right: 10px;
}