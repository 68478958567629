/* 
  Name: FeedCommentCount
  Path: components/Feeds/FeedComments/FeedCommentAction/FeedCommentCount
*/

.feed_comment_action_icon {
  width: 15px;
  height: 15px;
  display: block;
  background-size: 100% auto !important;
  margin-right: 7px;
  cursor: pointer;
}

/* .feed_comment_action_icon_liked {
  background: url('../../../assets/comment_like_icon_fill.svg');
}

.feed_comment_action_icon_not_liked {
  background: url('../../../assets/comment_like_icon_outline.svg');
} */

.feed_comment_action_icon_comment {
  background: url('../../../../../images/comment_icon_line.svg');
}

.feed_comment_action {
  margin-right: 17px;
  align-items: center;
}

.feed_comment_action_wrapper {
  margin-bottom: 17px;
}