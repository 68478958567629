/*
* ApprovalAvatarHead styles
*/

.appr-head {
  margin-bottom: 10px;
}

.appr-head, .appr-avatar-head {
  align-items: center;
}

.appr-head h5, .appr-head p {
  margin: 0;
  padding: 0;
}

.appr-brk-time-took {
  margin-left: auto
}

.appr-avatar {
  margin-right: 8px;
}
