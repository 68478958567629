.css-10yc0wa{
    z-index: 99999 !important;
  }
  .post_date .react-datetime-picker__inputGroup{
    font-weight: 500;
  
  }
  .relates-area, .issue_dis_input{
    font-weight: 500;
  
  }
  #ticket_details [type="file"] {
      height: auto;
      overflow: hidden;
      width: auto;
    }
  
    .post_date .ticket-date-title{
      font-size: 12px;
      color: #495057;
      font-weight: 500;
      margin: 0;
      padding: 0;
      display: block;
    }
    .post_date .react-datetime-picker{
      width: 100%;
      border-radius:8px !important;
      border: 1px solid #ADB5BD !important;
      outline: none !important;
      min-height: 49px !important;
      padding:11px 10px !important;
    }
    /* .post_date .react-datetime-picker .react-datetime-picker__wrapper{
      line-height: 35px;
    } */
    img.calender-icon{
      width: 50%;
      float: right;
    }
  .relates-area{
    width: 100%;
    height: 35px;
    border: 1px solid #D9DEE4;
    border-radius: 2px !important;
    padding: 2px 20px;
    background-color: #fff;
    outline: none !important;
  }
  .issue_dis_input {
    width: 100%;
    min-height: 49px;
    border: 1px solid #ADB5BD;
    border-radius: 8px;
    background: #fff !important;
    align-items: center;
    padding:0 .75rem;
    position: relative;
  }
  .issue_dis_input::after{
    width: 95%;
    height: 1px;
    background: #DEE2E6;
    content: "";
    position: absolute;
    top: 41px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .ticket-status-box #LoadingWrapper{margin-top: 10px;}
  
  .ulchekbox label{
    margin: 0 !important;
    color: #495057;
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    width: 89% !important;
  }
  .ulchekbox .ch-item{
     padding: 10px 10px 10px 15px;
     margin-top: 8px;
     background: #EDF4FA;
     border-radius: 8px;
  }
  button.add_cmd_btn {
    border: none;
    float: right;
    background: #219186;
    border-radius: 2px;
    min-width: 120px;
    height: 30px;
    font-size: 14px;
    color: #fff;
    font-weight: 600;
    margin: 0;
    transition: all .7s ease-in-out;
    outline: none;
  }
  button.add_cmd_btn:hover {
    background: #19786F;
  }
  
  .ticket-subjet-txtbox{
    width: 100%;
    border-radius: 2px;
    border: 1px solid #D9DEE4;
    outline: none !important;
    height: 40px;
    margin-bottom: 15px;
    border-radius: 2px;
  }
  .cmd_viewbox {
    padding: 30px;
    border-radius: 18px;
    margin-bottom: 0px;
    box-shadow: 0px 12px 24px 0px rgba(108,161,206,0.08);
    background: #fff;
    margin-bottom: 30px;
  }
  .issue_chat_detail{
    background: none;
    box-shadow: none;
  }
  .issue_chat_detail h2{
      color: #212529;
      font-size: 20px;
      font-weight: 700;
      letter-spacing: .25px;
      margin: 0;
      padding: 0 0 15px 0;
      font-weight: 600;
  
  }
  .new-ticket-details .ql-toolbar.ql-snow{
    border: none !important;
  }
  .new-ticket-details {
    padding: 30px;
  border-radius: 18px;
  margin-bottom: 30px;
  box-shadow: 0px 12px 24px 0px rgba(108,161,206,0.08);
  background: #fff;
  }
  .new-ticket-details .ql-container.ql-snow{
    border: none !important;
  }
  .new-ticket-details .ql-editor{
    margin: 0;
    background: #EDF4FA;
    border-radius: 8px;
    border: 1px solid #ADB5BD !important;
    padding: 15px;
    min-height: 160px;
  }
  .edit_btn{
    width: 30px;
    height: 30px;
    background: #069482;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
  .edit_btn .material-icons{
    color: #fff;
    font-size: 18px;
  }
  .edit_btn:hover{
    background: #004c3f;
  }
  .edit_btn:hover .material-icons{
    color: #fff;
  }
  .edit-ticket-desc, .edit-ticket-comment
  {
      font-size: 14px;
      
      font-weight: 500;
      position: relative;
      margin-left: -8px;
      cursor: pointer;
  }
  /* span.edit-ticket-desc::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 16px;
    background: #A0A0A0;
    left: -4px;
    top: 1px;
  } */
  
  .edit-ticket-desc .ql-toolbar.ql-snow{
    border-bottom: none !important;
  }
  
  .edit-ticket-desc.edit_btn
  {
  margin-left: -18px;
  }
  .edit-ticket-desc .ql-editor{
    border: none !important;
      box-shadow: none;
      margin: 0 15px 15px 15px;
      background: #F7F7F7;
      min-height: 300px;
      font-size: 14px;
      color: #A0A0A0;
  }
  .new-ticket-details .ql-editor.ql-blank{
    margin: 0;
    background: #fff;
    border-radius: 8px;
    border: 1px solid #ADB5BD !important;
    padding: 15px;
  }
  .new-ticket-details .ql-editor.ql-blank::before {
    padding:0;
    font-size: 14px;
    color: #A0A0A0;
    font-style: normal;
    letter-spacing: .5px;
  }
  .cmd_viewbox .multi-preview {
    display: inline;
  }
  .cmd_viewbox .multi-preview img{
    width: 80px;
    margin: 10px 6px 6px 0;
    float: left;
    height: 60px;
    overflow: hidden;
  }
  .cmd_viewbox .file-wrapper{
    display: block;
    clear: both;
    margin: 15px 0;
  }
  .upload-btn-wrapper {
      position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    }
    
    .btn_uplod {
    color: #A0A0A0;
    border: 1px solid #fff;
    background-color: white;
    padding: 8px 33px 8px 33px;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer !important;
    min-width: 180px;
    margin-right: 30px;
    background-image: url(../TaskTickets/attach_imgicon.png);
    background-repeat: no-repeat;
    background-position: 0px 6px;
    text-align: left;
    border-radius: 2px !important;
    background-size: 24px;
    
    } 
    
    .upload-btn-wrapper input[type=file] {
      font-size: 100px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      cursor: pointer;
    }
    
    .rbstyle label:before {
      position: absolute;
      content: "";
      display: inline-block;
      top: 50%;
      transform: translate(-50%, -50%);
      border: 1px solid transparent;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      background-color: transparent;
      right: -3.25rem;
      transform-origin: -550%;
    
    }
    .rbstyle label:after {
      position: absolute;
      content: "";
      display: inline-block;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 16px;
      height: 16px;
      border: 1px solid #D9DEE4;
      border-radius: 50%;
      background-color: #fff;
      right: -2.25rem;
      transition: 0.2s;
    }
    .rbstyle input[type=radio] {
      display: none;
    }
    .rbstyle input[type=radio]:checked + label {
      color: #333;
    }
    .rbstyle input[type=radio]:checked + label:before {
      border: 1px solid #219186;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      background-color: transparent;
      right: -2.25rem;
    }
    .rbstyle input[type=radio]:checked + label:after {
      border-color: #219186;
      width: 8px;
      height: 8px;
      background-color:#219186;
      right: -1.75rem;
      transform-origin: 0;
    }
    .rbstyle label {
      position: relative;
      user-select: none;
      vertical-align: middle;
      cursor: pointer;
      transition: 0.2s;
      width: 85%;
    }
  .backbtn{
    width: 48px;
    height: 48px;
    border: 0 !important;
    border-radius: 8px;
    min-width: 48px;
    margin-left: 10px;
    background: #EDF4FA;
    background-color: rgb(237, 244, 250);
    color: #6C757D;
    display: flex;
    align-items: center;
    justify-content: center;
    }
  .backbtn:hover{
    background-color: #004c3f !important; 
    border: 1px solid #004c3f !important;
  }
  .backbtn:hover .material-icons{
    color: #fff !important;  
  }
  
  .ticket_list:hover{
      background-color: #069482 !important; 
      border: 1px solid #069482 !important;
    }
  
  .ticket-detail-preview{
    margin-top: 20px;
  }
  
  .preview-title h2{
    font-size: 16px;
    color: #636363;
    font-weight: 700;
    letter-spacing: .50px;
    margin: 0;
    padding: 0 0 15px 0;
    font-weight: 600;
  
  }
  .ticket-detail-preview .preview-description p span{
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: #A0A0A0 !important;
    line-height: 21px;
    text-align: left;
    background: none !important;
  }
  /* .tick_detailhead button {
    background-image: url(../../images/add_ticket.svg);
    background-repeat: no-repeat;
    background-color: #a1a1a1;
    border: 1px solid #a1a1a1;
    border-radius: 5px;
    color: #fff;
    width: 40px;
    min-height: 35px;
    outline: none;
    letter-spacing: .25px;
    background-size: 23px;
    background-position: 8px 9px;
  }
  .tick_detailhead button:hover{
    background-color: #069482;
    border: 1px solid #069482;
  } */
  .tick_detailhead{
    align-items: center;
    padding-bottom: 40px;
    justify-content: flex-end;
  }
  /*.tick_detailhead button:hover {
    background: #077567 url(../../images/add_ticket.svg) !important;
    background-size: 23px;
    background-position: 8px 9px;
  }*/
  .tick_detailhead .backbtn{
    margin-bottom: 0 !important;
    background-position: 6px 6px !important;
  }
  .tick_detailhead h2{
    padding: 0;
  }
  .comd_pop_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 15px;
    width: 100%;
  }
  .comd_pop_head .owner{
    font-size: 14px;
    color: #A0A0A0;
    font-weight: 600;
  
  }
  .comd_pop_head .posted-on{
    font-size: 14px;
      color: #A0A0A0;
      font-weight: 500;
      display: flex;
  }
  .posted-time{
    font-size: 14px;
    color: #A0A0A0;
    font-weight: 500;
    display: flex;
    position: relative;
    margin-left: 8px;
  }
  .post-date-time{
    display: flex;
  }
  .posted-time::before{
    content: "";
      position: absolute;
      width: 1px;
      height: 16px;
      background: #A0A0A0;
      left: -4px;
      top: 1px;
  }
  .comment_content{
    padding: 20px 0;
    border-top: 1px solid #CDD3DB;
  }
  .comment_content p span{
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: #000000 !important;
    line-height: 21px;
    text-align: left;
  }
  .comment_content p{
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: #000000 !important;
    line-height: 21px;
    text-align: left;
  }
  .comment_content p a{
    padding: 10px 0;
    display: block;
  }
  .comd_pop_head .user_detail {
    display: flex;
    flex: 0 0 100%;
  }
  .comd_pop_head .user_pic {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    overflow: hidden;
  }
  .comd_pop_head .date_n_time{
    padding-left: 10px;
    max-width: 500px;
    width: 100%;
  }
  .comd_pop_head .date_n_time h1{
    color: #212529;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }
  .comd_pop_head .date_n_time .posted-on, .comd_pop_head .date_n_time .posted-time{
    color: #6C757D;
    font-size: 14px;
    font-weight: 400;
  }
  
  .ticket-attachments img {
    width: 100px;
    margin-right: 8px;
    float: left;
    margin-bottom: 8px;
  }
  .ticket-attachments{
    display: inline;
  }
  .comment-attachments img {
    width: 152px;
    margin-right: 5px;
    float: left;
    margin-bottom: 5px;
  }
  .comment-attachments{
    display: inline;
  }
  
  .replay_upload .multi-preview {
    display: inline;
  }
  .replay_upload .multi-preview img{
    width: 80px;
    margin: 10px 6px 6px 0;
    float: left;
    height: 60px;
    overflow: hidden;
    border-radius: 8px;
  }
  .comment-preview .preview-description{
    padding: 20px 0 0 0;
  }
  .upload-btn-wrapper{
    clear: both;
  }
  .comd_pop_head .posted-by{
    font-size: 14px;
    color: #A0A0A0;
    font-weight: 600;
  
  }
  .ticket_filter_header  .dropfilt{
    border: 1px solid #ADB5BD;
    min-height: 49px;
    border-radius: 8px !important;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 15px;
    font-weight: 400;
    color: #6c757d !important;
    width: 100%;
  }
  .filter_drop_wrap{
    width: 88%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .ticket_lits_head {
    min-height: 65px;
    background: #F0F6FA;
    display: flex;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
    border-bottom: 1px solid #DEE2E6;
  }
  .head_text {
    font-size: 15px;
    color: #6C757D;
    font-weight: 600 !important;
    letter-spacing: 0.00938em;
   }
  .ticket_lits_head .row{
    width: 100%;
  }
  .list_row {
    padding-right: 30px;
    padding-left: 30px;
    min-height: 75px;
    display: flex;
    align-items: center;
    position: relative;
  }
  .list_row::after{
    content: "";
    position: absolute;
    width: auto;
    height: 1px;
    background: #DEE2E6;
    bottom: 0;
    z-index: 99;
    left: 30px;
    right: 30px;
  }
  .tlist_text {
    font-size: 15px;
    color: #212529;
    font-weight: 400;
    letter-spacing: .25px;
    position: relative;
    top: 2px;
  }
  .list_row .row{
    width: 100%;
  }
  .pending{
    color: #FF7800;
  }
  .new {
    color: #2447c5;
  }
  .in-progress{
    color: #f1c40f;
  }
  .on-hold{
    color: #EA2027;
  }
  .resolved {
    color: #069482;
  }
  .back_btns{
    margin-left: 30px;
    align-items: flex-end;
    justify-content: flex-end;
    display: flex;
  }
  
  
  .ticket-subjet-txtbox
  {
    font-size: 16px!important;
    color: #636363!important;
    font-weight: 700;
  }
  
  .quill .ql-editor p, .preview-description p, .description.comment_content p
  {
    font-size: 17px;
  }
  
  #list .subject_text{cursor: pointer;}
  
  
  .field-error, .new-ticket-details .quill.field-error
  {
    border:1px solid #ff1100!important;
  }
  .assignees-field .field-error-msg
  {
    margin:15px 0px;
  }
  
  .assign-group.ulchekbox
  {
    height:auto;
    height: 100%;
    position: relative;
    margin: 0 !important;
    top: -16px;
    background: #fff;
    border: 1px solid #adb5bd;
    border-top: 0;
    position: relative;
    padding: 0 15px 20px 15px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding-top: 2px;
  }
  
  .assign-group.ulchekbox input[type="checkbox"]
  {
    cursor: pointer;
  }
  .no-records-found
  {
    margin-top:30px;
  }
  .react-tags{
    padding:20px;
    border: 1px solid #D9DEE4;
    width: 100%;
    position: relative;
  }
  .react-tags__search-input{
    border: none;
    width:100px!important;
  }
  .read-field, .read-field:focus, .read-field:active{background: #efefef; cursor:not-allowed;}
  .filter_drop_wrap .react-tags{
    position: relative;
      z-index: 1;
      cursor: pointer;
      background:transparent;
      border: 1px solid #D9DEE4;
      min-height: 35px;
      width: 250px;
     border-radius: 0.2rem;
     line-height: 26px;
      justify-content: space-between;
      align-items: center;
      color: #A0A0A0;
      transition: 0.3s all ease;
      outline: none;
      padding: 0px 12px;
      margin-right: 15px;
     
   
  }
  .filter_drop_wrap .react-tags::-webkit-scrollbar {
    width: 1px;
    height: 1px;
  }
  
  
  .filter_drop_wrap .react-tags::-webkit-scrollbar-thumb {
  background-color: #F1F1F1;
  outline: 1px solid #fff;
  }
  
  
  .filter_drop_wrap .react-tags__search{
    width: auto;
    margin-top: 8px;
    margin: 0;
    padding: 3px;
  }
  .filter_drop_wrap .react-tags__selected-tag,
   .filter_drop_wrap_sidebar .react-tags__selected-tag{
    width: auto !important;
    background-image: none !important;
    padding: 5px;
    margin: 0;
    font-size: 13px;
    min-height: auto;
    background-color: #ded6d6 !important;
    border: 0;
    color: #323232;
    margin-right: 4px;
    margin-bottom: 4px;
    line-height: 10px;
  }
  @media screen and (max-width: 1100px) {
    .dropfilt{
      width: 120px;
    }
    .filter_drop_wrap .back_btns {
      margin-left: 25px;
    }
    }
  
  
    .react-tags {
      position: relative;
      padding: 6px 0 0 6px;
      border: 1px solid #D1D1D1;
      border-radius: 1px;
    
      /* shared font styles */
      font-size: 1em;
      line-height: 1.2;
    
      /* clicking anywhere will focus the input */
      cursor: text;
    }
    
    .react-tags.is-focused {
      border-color: #B1B1B1;
    }
    
    .react-tags__selected {
      display: inline;
    }
    
    .react-tags__selected-tag {
      display: inline-block;
      box-sizing: border-box;
      margin: 0 6px 6px 0;
      padding: 6px 8px;
      border: 1px solid #D1D1D1;
      border-radius: 2px;
      background: #F1F1F1;
    
      /* match the font styles */
      font-size: inherit;
      line-height: inherit;
    }
    
    .react-tags__selected-tag:after {
      content: '\2715';
      color: #AAA;
      margin-left: 8px;
    }
    
    .react-tags__selected-tag:hover,
    .react-tags__selected-tag:focus {
      border-color: #B1B1B1;
    }
    
    .react-tags__search {
      display: inline-block;
    
      /* match tag layout */
      padding: 7px 2px;
      margin-bottom: 6px;
    
      /* prevent autoresize overflowing the container */
      max-width: 100%;
    }
    
    @media screen and (min-width: 30em) {
    
      .react-tags__search {
        /* this will become the offsetParent for suggestions */
        position: relative;
      }
    
    }
    
    .react-tags__search-input {
      /* prevent autoresize overflowing the container */
      max-width: 100%;
    
      /* remove styles and layout from this element */
      margin: 0;
      padding: 0;
      border: 0;
      outline: none;
    
      /* match the font styles */
      font-size: inherit;
      line-height: inherit;
    }
    
    .react-tags__search-input::-ms-clear {
      display: none;
    }
    
    .react-tags__suggestions {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
    }
    
    @media screen and (min-width: 30em) {
    
      .react-tags__suggestions {
        width: 240px;
      }
    
    }
    
    .react-tags__suggestions ul {
      margin: 4px -1px;
      padding: 0;
      list-style: none;
      background: white;
      border: 1px solid #D1D1D1;
      border-radius: 2px;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
    }
    
    .react-tags__suggestions li {
      border-bottom: 1px solid #ddd;
      padding: 6px 8px;
    }
    
    .react-tags__suggestions li mark {
      text-decoration: underline;
      background: none;
      font-weight: 600;
    }
    
    .react-tags__suggestions li:hover {
      cursor: pointer;
      background: #eee;
    }
    
    .react-tags__suggestions li.is-active {
      background: #b7cfe0;
    }
    
    .react-tags__suggestions li.is-disabled {
      opacity: 0.5;
      cursor: auto;
    }
  
    .cant-edit .react-tags__selected-tag::after{
      display: none;
    }
    .cant-edit .react-tags__selected-tag
    {
      cursor: not-allowed;
    }
  
    .cant-edit .react-tags__search-input{display:none;}
    .ticket-list-row{cursor: pointer;}
    .ql-editor{color:#636363!important;}
    select#relatedto{
      background-position: 98%;
    }
    .back_btns .deletbtn{
      width: 48px;
      height: 48px;
      border-radius: 8px;
      background: #EDF4FA;
      border-color: #EDF4FA;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
    }
    .back_btns .deletbtn .material-icons{
      color:#6C757D;
      font-size: 22px;
    }
    .back_btns .deletbtn:hover,   .back_btns .deletbtn:focus{
      background-color: #EA2027 !important;
      border: 1px solid #EA2027 !important; 
    }
    .back_btns .deletbtn:hover .material-icons, .back_btns .deletbtn:focus .material-icons{
      color: #fff;
    }
    .warning-count
    {
      background: #a1a1a1;
      padding: 14px;
      align-items: center;
      border-radius: 50%;
      font-size: 18px;
      font-weight: 700;
      display: flex;
      height: 35px;
      width: 35px;
      color: #fff;
      align-items: center;
      justify-content: center;
      line-height: 20px;
      margin: 0;
      text-align: center;
      padding: 0;
    }
  
    .prv_imgs{
      position: relative;
      width: 80px;
      display: inline-block;
      margin-right: 5px;
      margin-bottom: 5px;
    }
    .ticket-summary-description h2{
      color: #212529;
      font-size: 16px;
      font-weight: 600;
      margin: 0;
      padding:10px 0 15px 0
    }
    .comment-text p, .ticket-summary-description p{
     color: #6C757D;
     font-size: 15px;
     font-weight: 400;
     line-height: 25px;
    }
    
    .ticket-attachments, .comment-attachments{    
      margin-top: 20px;
      display: block;
    }
  .ticket-attachments a img{
    border-radius: 8px;
    overflow: hidden;
  }
    #ticket_details select
    {
      color: #6c757d;
    }
  .ticket-list-row .meeting-agenda
  {
    color:#7500f3
  }
  
  .tick_detailhead   .rbt-input-main {
    margin: 0 15px 0 0;
    height: 35px;
    width: 100%;
    max-width: 200px;
  }
  .tick_detailhead .dropdown-menu{
    
    width: 300px !important;
  
  }
  .ticket-wrapper{
    position: relative;
  }
  
  .outclose{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .health-datas .card{
    padding:0 30px;
    min-height: 60px;
    display: flex;
    justify-content: center;
    border-radius: 0 !important;
    border-top: 1px solid #DEE2E6!important;}
  .health-img{
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background: #FFEFF2;
    border-color: #FFEFF2;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .health-img .material-icons{
    color: #F93154;
  }
  .health-img:hover{
    background: #F93154 !important;
    border-color: #F93154 !important;
    
  }
  .health-img:hover .material-icons{
    color: #FFEFF2;
  }
  button.health-img:focus{
    background: #F93154 !important;
    border-color: #F93154 !important;
   
  }
  .health-img:focus .material-icons{
    color: #FFEFF2;
  }
  
  .health-datas h4{margin-bottom: 30px;}
  .close-health{
    cursor: pointer; 
    position: absolute;
    right: 20px;
    top: 20px;
    width: 40px;
    height: 40px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    background:#F4F6F9;
  }
  
  @media screen and (max-width: 768px) {
    .health-data-content {
      padding: 20px;
      background: #fff;
      font-size: 15px;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      max-width: 100%;
      z-index: 999;
      height: 100%;
      box-shadow: 12.287px 8.604px 25px 0px rgb(179 179 179 / 10%);
      overflow-x: hidden;
      overflow-y: scroll;
  }
  
    .list_row .row {
      width: 100%;
      flex-wrap: inherit;
  }
  
  .tick_detailhead .rbt-input-main {
      margin: 0 0 8px 0;
      height: 35px;
      width: 100%;
      max-width: 100%;
  }
    #LoadingWrapper{
      overflow-x: scroll;
      overflow-y: hidden;
    }
    .ticket-items{
  
      width: 1000px;
    }
    .list_row {
      padding-right: 0;
      padding-left: 0;
      min-height: 60px;
      display: flex;
      align-items: center;
      border: 1px solid #CDD3DB;
      padding: 10px;
      margin-bottom: 10px;
  }
    .filter_drop_wrap .react-tags{
      width: 100%;
      margin: 8px 0;
    }
    .dropfilt {
      width: 100%;
      margin-right: 0;
      margin-bottom: 8px;
  }
  .filter_drop_wrap .back_btns {
    margin-left: 0;
    width: 90px;
    float: right;
    position: absolute;
    top: 10px;
    right: 25px
  }
  .tick_detailhead {
    align-items: center;
    padding-bottom: 40px;
    flex-direction: column;
  }
  .ticket_lits_head{
    display: none;
  }
  .filter_drop_wrap {
    flex-direction: column;
    width: 100%;
    display: block;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
  }
  .tick_detailhead {
    align-items: center;
    padding-bottom: 40px;
    display: block !important;
    flex-direction: column;
  }
  .filter_drop_wrap {
    width: 100%;
    display: block;
    flex-direction: column;
  }
  .ticket-wrapper #feed-sidebar
  {
  margin:0px;
  }
  
  
  
  }
  
  .ticket-wrapper .single-line-data
  {
    display: block;
    margin-top:15px;
  }
  
  .ticket-wrapper .single-line-data span
  {
    display: inline-block;
    font-weight: bold;
    margin-right: 5px;
  }
  
  .filter_drop_wrap_sidebar
  {
     position: fixed;
      top: 0;
      right: 0;
      width:50%;
      background: #fff;
      padding:60px 30px 30px 30px !important;
      height: 100%;
      z-index: 99999;
      bottom:0;
      -webkit-box-shadow: 0px 4px 32px 0px rgba(108,161,206,0.12);
      -moz-box-shadow: 0px 4px 32px 0px rgba(108,161,206,0.12);
      box-shadow: 0px 4px 32px 0px rgba(108,161,206,0.12);
  }
  .filter_drop_wrap_sidebar .close_btn{
    width: 40px;
    height: 40px;
    border-radius: 8px;
    border-color: #F4F6F9;
    background: #F4F6F9;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .filter_drop_wrap_sidebar .close_btn .material-icons{
    color: #000;
  }
  .filter_drop_wrap_sidebar .close_btn:hover{
    background: #004c3f;
    border-color: #004c3f !important;
  }
  .filter_drop_wrap_sidebar .close_btn:hover .material-icons{
    color: #fff;
  }
  .filter_drop_wrap_sidebar .react-tags, .filter_drop_wrap_sidebar .rbt, 
  .filter_drop_wrap_sidebar select
  {
    margin-bottom:24px!important;
    color: #000;
  }
  .filter_drop_wrap_sidebar .dropfilt{
    width:100%;
    height: 40px;
    border: 1px solid #ADB5BD;
    min-height: 49px;
    border-radius: 8px !important;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 15px;
    font-weight: 400;
    color: #495057;
    width: 100%;
    margin-bottom: 24px !important;
    background-color: #fff;
  }
  .filter_drop_wrap_sidebar .react-tags {
    position: relative;
    z-index: 1;
    cursor: pointer;
    background: transparent;
    border: 1px solid #D9DEE4;
    min-height: 35px;
    width: 100%;
    border-radius: 0.2rem;
    line-height: 18px;
    justify-content: space-between;
    align-items: center;
    color: #A0A0A0;
    transition: 0.3s all ease;
    outline: none;
    padding: 0px 12px;
    margin-right: 15px;
  }
  .filter-icon{
    width: 48px;
    height: 48px;
    border: 0 !important;
    border-radius: 8px;
    min-width: 48px;
    margin-right: 10px;
    background: #EDF4FA;
    color: #6C757D;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .filter-icon:hover
  {
    background: #004c3f;
  }
  .filter-icon:hover .material-icons{
    color: #fff;
  }
  .filter_sidebar_overlay
  {
     width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1000;
      background: rgba(33, 37, 41, 16%);
  }
  /* .filter_drop_wrap_sidebar .label-field
  {
    margin-bottom: 15px;
  } */
  .filter_drop_wrap_sidebar .react-datetime-picker {
    border: 1px solid #ADB5BD !important;
    border-radius: 8px !important;
    width:100%;
    height: 49px;
  }
  
  .filter_drop_wrap_sidebar .label-field label
  {
    width:30%;
  }
  .filter_drop_wrap_sidebar .react-tags__search-input
  {
    background: transparent;
  }
  
  .filter_drop_wrap_sidebar .rbt-input-main.rbt-input{
    border: 1px solid #ADB5BD;
    min-height: 49px;
    border-radius: 8px !important;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 15px;
    font-weight: 400;
    color: #495057;
    width: 100%;
    background-color: #fff;
  
  }
  .advance-actions .btn {
    margin-right: 15px;
    width: 130px;
    padding: 5px;
    margin-top: 15px;
  }
  .filter_drop_wrap_sidebar input[type="text"] {
    color: #6c757d;
    background: transparent;
  }
  
  .filter_drop_wrap_sidebar input[type="text"]::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #6c757d;
    opacity: 1; /* Firefox */
  }
  
  .filter_drop_wrap_sidebar input[type="text"]:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #6c757d;
  }
  
  .filter_drop_wrap_sidebar input[type="text"] ::-ms-input-placeholder { /* Microsoft Edge */
    color: #6c757d;
  }
  .seperator-text{
    display: block;
    margin:10px 0px;
  }
  
  .sort-option-wrap{
    margin-bottom: 20px;
  }
  
  .responsible-person
  {
    margin-bottom: 10px;
      font-weight: bold;
  }
  
  .tickets-listing .filter_drop_wrap select {
    color: #333;
    width: 200px!important;
    padding: 0px 10px!important;
    height: 37px;
  }
  
  .preview-with-existing .comment-attachments img {
    width: 80px;
  }
  .attachment-bg
  {
      background: #f1f1f1;
      padding: 30px;
      width: 150px;
      display: inline-block;
      text-align: center;
      height: 130px;
      padding-top: 45px;
  }
  .responsible-person .rbt-input-multi
  {
    height: 100px;
  }
  .responsible-person .rbt-input-multi .rbt-token-removeable
  {
    display: flex;
    justify-content: space-between;
    background: #F4F6F9 !important;
    box-shadow: none !important;
    border-radius: 5px;
    margin-right: 5px;
    font-weight: 400;
    color: #212529;
    margin: 0 6px 6px 0;
    padding: 6px 8px;
    align-items: center;
  
  }
  
  .responsible-person .rbt-input-multi .rbt-token-remove-button{
    font-weight: 200;
    color: #aaa;
    opacity: 1 !important;
    margin-left: 5px;
  }
  .edit-ticket-desc .btn-grey
  {
  margin-right: 15px;
  }
  .edit-act-buttons
  {
    margin-top: 20px;
  }
  .update-comment .btn-grey
  {
  border: none;
  float: right;
  border-radius: 2px;
  min-width: 120px;
  height: 30px;
  margin-top: 15px;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  transition: all .7s ease-in-out;
  outline: none;
  margin-right: 20px;
  }
  
  .ticket-incident-field{
  margin-bottom: 15px;
  }
  
  
  .ticket-incident-field label{
      color: #777d84;
      font-weight: 700;
      font-size: 15px;
      margin-left: 15px;
  }
  
  .ticket-incident-field .react-datetime-picker
  {
    border-radius: 2px;
    width:582px;
    margin-left: 15px;
    border:1px solid #E8E8E8;
    padding: 12px 5px;
  }
  
  .health-data-overlay
  {
    position: fixed;
      width: 100%;
      height: 100%;
      background: rgba(104, 104, 104, 0.47);
      z-index: 9991;
      top: 0;
      left: 0;
      cursor: pointer;
  }
  .health-data-content
  {
      background: #fff;
      font-size: 15px;
      width: 100%;
      max-width: 95%;
      z-index: 9992;
      height: 600px;
      box-shadow: 12.287px 8.604px 25px 0px rgb(179 179 179 / 10%);
      overflow-x: hidden;
      overflow-y: scroll;
      position: absolute;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
      border-radius: 8px;
      margin: 30px 0 !important;
  }
  .health-data-content h5{
    padding: 40px 30px  30px 30px;
    text-align: left !important;
    color: #212529;
    font-size: 20px;
    font-weight: 600;
  }
  .ticket-items .mass-checkbox{
    margin-right: 40px!important;
    cursor: pointer;
  }
  
  .ticket-action-buttons .btn{
      color: #fff;
      background-color: #a1a1a1;
      border-color: #a1a1a1;
      padding: 2px 10px;
      font-size: 15px;
      margin-right: 20px;
  }
  .ticket-action-buttons .btn:hover{
    background-color: #069482;
    border-color: #069482;
  }
  .assignee-change-popup
  {
    position: absolute;
    top:20%;
    width:100%;
  }
  .assigne-change-wrapper
  {
    background: #f5f5f5;
    width: 600px;
    padding: 30px;
    padding-top:25px;
    box-shadow: 0 0 10px 10px rgba(0,0,0,.02);
    border-radius: 5px;
    min-height: 300px;
    overflow: hidden;
    overflow-y: scroll;
    position: absolute;
    top: 5%;
    left: -6%;
    right: 0;
    margin: 0 auto;
    z-index: 9999;
    text-align: center;
  }
  .assignee-change-overlay
  {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 47%);
  z-index: 999;
  top: 0;
  left: 0;
  cursor: pointer;
  }
  .react-datetime-picker__inputGroup__input{
    outline: 0 !important;
  }
  .react-datetime-picker {
    border: 1px solid #ccc !important;
    background: transparent;
    padding: 5px 16px;
    height: 100%;
    display: inline-block;
    min-width: 200px !important;
    max-width: 100% !important;
    border-radius: 5px !important;
    min-height: 39px !important;
    margin-right: 20px !important;
    margin-bottom: 20px;
  }
  .assigne-change-wrapper .error-text{
    display: block;
    padding-bottom: 20px;
  }
  .tickets-lists .assigne-change-wrapper .react-datetime-picker__clear-button{
    display: block !important;
  }
  .assigne-change-wrapper .react-tags
  {
    text-align: left;
    margin-bottom: 30px;
  }
  .assigne-change-wrapper .dropfilt {
    margin-bottom: 30px;
  }
  
  .assigne-change-wrapper .btn
  {
    width: 200px;
  }
  
  .assigne-change-wrapper .btn:hover
  {
    background-color: #069482;
    border-color: #069482;
  }
  .assigne-change-wrapper h4{
    margin-bottom: 0px;
    min-height: 80px;
  }
  
  .assigne-change-wrapper  .error-text
  {
  color:red;
  }
  
  .remind-at{
    margin-top:15px!important;
  }
  
  .reminder-options
  {
    font-weight: bold;
    margin-bottom: 10px;
  }
  .reminder-options input.form-check-input, .reminder-actions-wrap input.form-check-input
  {
    box-sizing: border-box;
      padding: 0;
      margin-left: 30px;
      margin-right: 5px;
      position: relative;
      margin-top:-3px;
      -webkit-appearance: auto;
      appearance: auto;
  }
  .reminder-options input.form-check-input.first-item, .reminder-actions-wrap input.form-check-input.first-item
  {
    margin-left: 0px;
  }
  .reminder-actions-wrap label
  {
    color: #666;
    font-weight: normal;
    font-style: italic;
    font-size: 16px;
    margin-top: 7px;
    color: #0008ff;
  }
  .reminder-action-question
  {
    margin-top: 15px;
    margin-bottom: 5px;
    font-size: 16px;
    font-style: italic;
    color: #0008ff;
  }
  .new-reminder-comment
  {
    font-weight: bold;
      font-size: 15px;
      display: block;
      margin-bottom: 15px;
      margin-top: 10px;
  }
  
  .assigne-change-wrapper  .error-text
  {
  color:red;
  }
  .reminder-actions-wrap .choosen-action 
  {
    font-style: italic;
      font-size: 16px;
  }
  .pending-reminder-info
  {
    margin: 15px 0px;
    border-top: 1px solid #ccc;
    padding-top: 15px;
    margin-bottom:0px;
    font-weight: 600;
  }
  .pending-reminder-info label{
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    color: #495057;
    position: relative;
    top: 1px;
    padding-left: 15px;
  }
  .pending-reminder-info input[type="checkbox"]::after{
    left: 0;
  }
  .pending-reminder-info .form-check{padding-left: 0;}
  .pending-reminder-info input[type="checkbox"] {
  margin-right: 36px !important;
  cursor: pointer;
  margin-top: -20px;
  }
  .ticket-client-name
  {
    margin-bottom: 15px;
  }
  
  .filter_drop_wrap_sidebar  .seperator-text
  {
    display: block;
    font-weight: 600;
    font-size: 18px;
    margin: 0;
    padding: 15px 0 24px 0;
    color: #212529
  }
  
  .filter_drop_wrap_sidebar .picker-field
  {
     flex: 1;
     margin-left: 15px;
     margin-right: 15px;
  }
  .filter_drop_wrap_sidebar .react-datetime-picker__wrapper{
    line-height: 35px;
  }
  .assigned-only.d-flex {
    margin-bottom: 10px;
   }
   .assigned-only.d-flex label{
     margin: 0;
     padding: 0;
     position: relative;
     top: 4px;
     font-size: 15px;
     font-weight: 500;
   }
  .disable-container
  {
    pointer-events: none;
      opacity: 0.4;
  }
  
  
  .ticket-sidebar-wrapper .ticket-id-value
  {
    font-size: 16px;
    color: #212529;
    font-weight: 600;
    margin: 0;
    padding:0;
  }
  
  
  .reminder-wrapper
  {
    margin-top:15px;
  }
  .reminder-to-options
  {
    margin-top:20px;
  }
  .reminder-wrapper .react-datetime-picker
  {
    margin-top:10px;
  }
  .reminder-wrapper .form-check-input
  {
    box-sizing: border-box;
      padding: 0;
      margin-left: 5px;
      margin-right: 5px;
      position: relative;
      margin-top:2px;
      -webkit-appearance: auto;
      appearance: auto;
  }
  
  @media only screen and (max-width: 768px) {
    .filter_drop_wrap input[type="text"]{
      width: 100% !important;
      margin-top: 15px !important;
    }
    .tickets-listing .filter_drop_wrap select{
      width: 100% !important;
      margin-top: 10px;
    }
    .filter_drop_wrap .back_btns{
      width: 128px;
    }
    .reminder-options{
      flex-wrap: wrap;
    }
  }
  
  .due_date_field{
    margin-top:8px;
  } 
  .optional-label
  {
    font-size: 14px;
    color:#a8a1a1;
  }
  
  .ticket-sidebar-wrapper .due_date_field .react-datetime-picker__clear-button
  {
    display: block;
    width: 15%;
    height: 15%;
  }
  .assigne-change-wrapper{
    overflow: inherit !important;
  }
  .react-datetime-picker__button {
    width: 20px !important;
    padding: 0;
    margin: 0 0 0 10px;
  }
  .react-datetime-picker__button img{
    width: 90% !important;
  }
  
  .ticket-sidebar-wrapper .due_date_field .react-datetime-picker__clear-button{
    width: 20px;
    margin-top: 3px;
  }
  
  .btn.add-multiple-members
  {
  border: none;
  background: #8e8e8e;
  border-radius: 2px;
  min-width: 120px;
  height: 30px;
  margin-top: 15px;
  font-size: 14px;
  color: #fff;
  font-weight: 600;
  transition: all .7s ease-in-out;
  outline: none;
  margin-right: 15px;
  }
  
  .ticket_section_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 30px 0 30px;
  }
  .ticket_section_header h1{
    margin: 0 !important;
    font-weight: 600;
    font-family: 'Poppins', sans-serif !important;
    text-align: left !important;
    color: #212529 !important;
    font-size: 20px;
    letter-spacing: 0.00938em;
  }
  .back_btns .add_ticket_btn{
    color: #fff;
    display: flex;
    align-items: center;
    background: #219186 !important;
    border:1px solid #219186 !important;
    border-radius: 8px;
    max-width: 200px;
    width: 100%;
    min-height: 48px;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.00938em;
  }
  .back_btns .add_ticket_btn:hover{
    background: #004c3f !important;
    border-color: #004c3f !important;
    border: none;
    color: #fff;
  }
  .back_btns .add_ticket_btn:focus{
    background: #004c3f !important;
    border-color: #004c3f !important;
    border: none;
    color: #fff !important;
  }
  .back_btns .add_ticket_btn .material-icons{
    color: #fff;
    display: none;
   }
   .ticket_filter_header{
     margin-top: 30px;
     margin-bottom: 30px;
     margin-left: 15px;
     margin-right: 15px
   }
   .ticket_filter_header .ticket-id-field,  .ticket_filter_header .rbt-input-main{
      border: 1px solid #ADB5BD;
      min-height: 49px;
      border-radius: 8px !important;
      padding-left: 15px;
      padding-right: 15px;
      font-size: 15px;
      font-weight: 400;
      color: #495057;
      width: 100%;
   }
   .ticket_filter_header .rbt-input-main:focus{
    border-color: #ADB5BD !important;
   }
   .ticket_filter_header .search_but_filt {
      width: 49px;
      height: 49px;
      min-width: 49px;
      border-radius: 8px;
      background-color:#219186;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
   }
   .date_choosefilter{
     display: flex;
     width: 100%;
     flex-wrap: wrap;
   }
   .date_choosefilter .picker-field label{
    width: 100%;
    display: block;
    margin: 0 !important;
    padding: 0 !important;
    font-size: 12px;
    color: #495057;
    font-weight: 500;
    letter-spacing: 0.00938em;
   }
  .filter_drop_wrap_sidebar .react-tags{
    margin-top: 20px;
    min-height: 49px !important;
    border-radius: 8px;
    border: 1px solid #ADB5BD !important;
  }
  .advance-actions .reset_btn{
      min-width:200px;
      background: #E42807;
      border-color: #E42807 !important;
      min-height: 49px;
      border-radius: 8px;
      padding-left: 15px;
      padding-right: 15px;
      font-weight: 500;
      font-size: 15px;
      color:#FDDAD8;
      -webkit-box-shadow: 0px 3px 6px 0px rgba(249,204,202,1);
      -moz-box-shadow: 0px 3px 6px 0px rgba(249,204,202,1);
      box-shadow: 0px 3px 6px 0px rgba(249,204,202,1) !important;
      }
  .advance-actions .reset_btn:hover{
    background: #D01F00;
    border-color: #D01F00 !important;
    color: #fff;
  }
  .advance-actions .reset_btn:focus{
    background: #D01F00 !important;
    border-color: #D01F00 !important;
    color: #fff;
  }
  .advance-actions .search_btn_adv{
    min-width:200px;
    background: #219186;
    border-color: #219186 !important;
    min-height: 49px;
    border-radius: 8px;
    padding-left: 15px;
    padding-right: 15px;
    font-weight: 500;
    font-size: 15px;
    color:#dcfffc;
    -webkit-box-shadow: 0px 3px 6px 0px rgb(202, 249, 237);
    -moz-box-shadow: 0px 3px 6px 0px rgb(202, 249, 237);
    box-shadow: 0px 3px 6px 0px rgb(202, 249, 237)!important;
    }
  .advance-actions .search_btn_adv:hover{
  background: #004c3f;
  border-color: #004c3f !important;
  color: #fff;
  }
  .advance-actions .search_btn_adv:focus{
  background: #004c3f !important;
  border-color: #004c3f !important;
  color: #fff;
  }
  .filter_drop_wrap_sidebar .react-tags__search-input::placeholder{
    font-size: 15px;
    color: #6c757d !important;
    opacity: 1;
  }
  .ticket-sidebar-wrapper {
    padding: 30px;
    border-radius: 18px;
    margin-bottom: 30px;
    box-shadow: 0px 12px 24px 0px rgba(108,161,206,0.08);
  
  }
  .ticket-sidebar-wrapper .balck_btn{
    width: 32px;
    height: 32px;
    background: #EDF4FA;
    border-color: #EDF4FA;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
  .ticket-sidebar-wrapper .balck_btn .material-icons{
    color: #000;
    font-size: 18px;
  }
  .ticket-sidebar-wrapper .balck_btn:hover{
    background: #004c3f !important;
    border: #004c3f !important;
  }
  .ticket-sidebar-wrapper .balck_btn:hover .material-icons{
    color: #fff;
  }
  .ticket-sidebar-wrapper .balck_btn:focus{
    background: #004c3f !important;
    border: #004c3f !important;
  }
  .ticket_header_left{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .ticket_header_left h1.card-title{
    color: #212529;
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0 0 15px 0;
  }
  .health_img{
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
  }
  .ticket-id-wrap{
    margin: 0;
    padding: 24px 0;
  }
  .ticket-id-wrap label, .choose_related .ticket-date-title{
    font-size: 12px;
    margin: 0;
    padding: 0;
    display: block;
    color: #495057;
    font-weight:500;
  }
  .ticket_detail_left .text-data-view{
    font-size: 16px;
    color: #212529;
    font-weight: 600;
    margin: 0;
    padding: 0;
  }
  .choose_related{
    margin-top: 24px;
  }
  .ticket_detail_left .relates-area.form-control{
    min-height: 49px;
    border: 1px solid #ADB5BD;
    border-radius: 8px !important; 
  
  }
  .ticket_detail_left .field-error-msg{
    font-size: 12px;
    position: relative;
    top: -15px;
    color: #495057;
  }
  .ticket_detail_left .client_details{
    margin: 24px 0;
  }
  .ticket_detail_left .client_details h3{
    font-size: 16px;
    font-weight: 600;
    color: #212529;
    margin: 0;
    padding: 0;
  }
  .ticket_detail_left  .client_feedback{
    margin-bottom: 24px;
  }
  .ticket_detail_left .assignees-field{
    margin-bottom: 24px;
  }
  .ticket_detail_left .add-multiple-members{
    min-width: 120px;
    height: 40px;
    border-radius: 8px;
    background: #219186;
    color: #fff;
    font-size: 15px;
    font-weight: 500;
  }
  .ticket_detail_left .add-multiple-members:hover{
    background: #004c3f;
    color: #fff;
  }
  .ticket_detail_left .responsible-person{
    margin: 24px 0;
  }
  .ticket_detail_left .responsible-person .rbt-input{
    border-radius: 8px;
    border: 1px solid #ADB5BD !important;
    min-height: 49px !important;
    height: auto !important;
  }
  .ticket_detail_left .responsible-person .rbt-input .rbt-input-main{
    line-height: 38px;
  }
  .ticket_detail_left .rbt-input-wrapper{
    display: flex;
    flex-wrap: wrap;
  }
  .reminder-options .form-check{
    padding-left: 0;
  }
  .reminder-options .form-check-label{
    font-size: 14px;
    font-weight: 500;
    color:#495057;
  }
  /* input[type="radio"] {
  
    -webkit-appearance: none !important;
  
    appearance: none !important;
  
    background-color: #fff !important;
  
    margin: 0 !important;
  
    font: inherit;
    color: #000;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid #ADB5BD;
    border-radius: 50%;
    transform: translateY(-0.075em);
  
    display: grid;
    place-content: center;
  }
  
  input[type="radio"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
  
    background-color: CanvasText;
  }
  
  input[type="radio"]:checked::before {
    transform: scale(1);
  }
  
  input[type="radio"]:focus {
    outline: max(2px, 0.15em) solid currentColor;
    outline-offset: max(2px, 0.15em);
  } */
  .reminder-wrapper .form-control{
    width: 100%;
    border-radius: 8px !important;
    border: 1px solid #ADB5BD !important;
    outline: none !important;
    min-height: 49px !important;
    padding: 11px 10px !important;
    }
  .reminder-to-options label{
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-weight: 500;
    color: #495057;
    position: relative;
    top: 1px;
  }
  .remove-pending-reminders{
    display: flex;
    align-items: center;
  }
  .remove_inner_text{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 24px;
  }
  .remove_inner_text label{
    margin: 0;
    padding: 0;
    color: #212529;
    font-size: 16px;
    font-weight: 400;
  }
  .remove_inner_text .add_cmd_btn{
    margin: 0 !important;
  
  }
  .remove_inner_text .conform{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .remove_inner_text button{
      background: #E6FAF8 !important;
      min-width: 200px;
      height: 49px;
      border-radius: 8px;
      color: #219186 !important;
  
  }
  .add-ticket{
  position: relative;
  top: -80px;
  }
  .ticket_detail_left .post_date{
    margin-top: 24px;
  }
  .ticket-reminder .reminder-options .form-check{
    width: 30%;
    flex: 0 0 30%;
  }
  .ticket-reminder .reminder-options{
    flex-wrap: wrap;
  }
  .ticket-reminder .reminder-options input.form-check-input{
    margin-left: 0;
  }
  .ticket-reminder .ticket-date-title{
  color: #212529;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 0 24px 0;
  }
  .ticket-reminder .reminder-options .form-check{
    margin-bottom: 10px;
  }
  .ticket-reminder .form-control{
    width: 100%;
  border-radius: 8px !important;
  border: 1px solid #ADB5BD !important;
  outline: none !important;
  min-height: 49px !important;
  padding: 11px 10px !important;
  }
  .add_new_tickets{
    padding: 30px;
    border-radius: 18px;
    margin:30px 0 30px 0;
    box-shadow: 0px 12px 24px 0px rgba(108,161,206,0.08);
    background: #fff;
  }
  .add_new_tickets .form-control{
    width: 100%;
    border-radius: 8px !important;
    border: 1px solid #ADB5BD !important;
    outline: none !important;
    min-height: 49px !important;
  }
  .add_new_tickets .form-group{
    margin-bottom: 24px;
  }
  .add_new_tickets .ticket-subject .ticket-subjet-txtbox{
    font-size: 14px !important;
    padding: 0 15px;
  }
  .add_new_tickets .new-ticket-details{
    padding: 0;
    box-shadow: none;
  }
  button.edit-ticket-cancel{
    background: #EDF4FA;
    min-height: 50px;
    color: #212529 !important;
    border-radius: 8px !important;
  }
  button.edit-ticket-cancel:hover{
    background: #ff1100;
    color: #000;
  }
  .editcmd_view{
    padding: 0 !important;
    box-shadow: none;
  }
  
  
  .ticket-posted-comments .new-ticket-details {
    padding: 0px;
    border-radius: 18px;
    margin-bottom: 30px;
    box-shadow: none;
    background: #fff;
  }
  .ticket-posted-comments .btn-update-cancel
  {
      border-radius: 8px;
      min-width: 200px;
      height: 48px;
  }

  .task-ticket-wrap-content
  {
    position: absolute;
    z-index: 999992;
    top: 0;
    left: 50%;
    transform: translate(-50%, 5%);
    max-width: 1300px;
    width: 100%;
    background: #fff;
    border-radius: 8px;
    padding: 30px;
   
  }
  .task-ticket-wrap-content .add_new_tickets{
    margin-top: 20px 0 0 0;
    padding: 0;
    box-shadow: none;
  }
  .task-ticket-wrap-content  .ticket-sidebar-wrapper{
    padding:0 10px 0 0;
    box-shadow: none !important;
  }
  .task-ticket-wrap-content  .ticket_detail_left .post_date{
    margin-top: 10px;
  }
  .task-ticket-wrap-content  .ticket_detail_left .react-tags{
    min-height: 35px !important;
    border-radius: 8px;
  }
  .task-ticket-wrap-content .ticket_detail_left .add-multiple-members {
    min-width: 85px;
    height: 35px;
    font-size: 13px;
   
  }
  .task-ticket-wrap-content .ticket_detail_left  .participant-fields .react-tags{
    max-height:112px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .task-ticket-wrap-content .ticket_detail_left  .assign-group{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
    grid-auto-rows: minmax(49px, auto);
  }
  .task-ticket-wrap-content .ticket_detail_left .rbstyle label::after{
    right: -1.30rem;
  }
  .task-ticket-wrap-content .ticket_detail_left .rbstyle input[type="radio"]:checked + label::after
  {
    right: -.8rem;
  }
  .task-ticket-wrap-content .ticket_detail_left .rbstyle input[type="radio"]:checked + label::before{
    right: -1.30rem;
  }
  .overlay-task-ticket
  {
    position: fixed;
    background: #111;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    opacity: 0.4;
    z-index: 999991;
  }
  #ticket_details .field-error
  {
      border: 1px solid red!important;
  }
 .task-ticket-items
 {
     margin-bottom: 15px;
 }
  .task-tickets
  {
      margin-top:20px;
  }
  
  .task-tickets a
  {
      color: #000;
  }
  .task-tickets a:hover
  {
      text-decoration: underline!important;
  }
  .task-tickets .task-status
  {
      font-weight: 500;
  }
  .task-tickets .ticket-subject
  {
      width: 72%;
  }

  .btn-task-ticket
  {
    font-size: 14px;
  }

  .task-ticket-wrap-content .add_new_tickets input[type="text"]
  {
    font-weight: normal;
  }


  .btn-task-ticket.plus{
    width: 30px;
    height: 30px;
    border-radius: 8px;
    line-height: 30px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
  }
  .btn-task-ticket.plus .material-icons{
    color: #fff;
    font-size: 18px;
  }
  .btn-task-ticket.plus:hover{
    background: #004c3f;
  }
  .task-ticket-wrap{
    position: fixed;
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
    overflow-x: hidden;
    overflow-y: scroll;
  }
#fixed-sidbar{
  height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  top: 12%;
  flex-grow: 1;
  align-self: start;
  margin-bottom: 120px;
}


