/*
 * Log Time
*/

.log-container {
  height: 50px;
	line-height: 50px;
	font-size: 16px;
	background: #f9fafa;
	border-radius: 3px;
	color: #636363;
	border-left: 4px solid #f2f2f2;
	display: block;
  width: 100%;
  padding-left: 25px;
  margin-bottom: 15px;
}

.log-container p {
  text-align: left;
  font-weight: 400;
}

.log-container .login-time {
  margin-right: 15px;
}

.late-time {
  text-decoration: underline;
}

.log-total {
  font-size: 20px;
  font-weight: 500;
  margin-top: 30px;
}

.log-total span {
  width: 50%;
  display: inline
}
.btn-gray-outline, .btn-gray-outline:hover {
  background-color: #fff!important;
  color: #000!important;
}
.log-container.late{	border-left: 4px solid #f00;}


/* Logs container */
.all-logs {
  height: 315px;
  overflow: hidden;
}

.all-logs:hover {
  overflow-y: scroll
}

/* width */
.all-logs::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.all-logs::-webkit-scrollbar-track {
  background: #fff; 
}
 
/* Handle */
.all-logs::-webkit-scrollbar-thumb {
  background: #e8e8e8;
  border-radius: 10px;
}

/* Handle on hover */
.all-logs::-webkit-scrollbar-thumb:hover {
  background: #e8e8e8; 
}

.in-mobile
{
  height: calc(100vh - 90px);
    background: #e85b5b;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    font-size: 25px;
    color: #fff;
    flex-direction: column;
}

.in-mobile img
{
  width: 150px;
    display: block;
    margin-bottom: 25px;
    margin:0 auto;
}

.in-mobile .mobile-view-blocked
{
  font-weight: 600;
    font-size: 30px;
    text-decoration: underline;
}

.break-timer-live
{
  color:#fea20c;
  font-weight: 600;
  font-size: 22px;
}

.power-icon
{
  cursor: pointer;
}

span.project-name-title {
  color: #bfbfbf;
  display: block;
}
span.task-name-title {
  font-size: 14px;
}