/*
* ApprovalTimeRow 
*/

.shift-current {
  margin-bottom: 14px;
}

.shift-current p{
  font-size: 16px;
  margin: 0;
}

.appr-data-data {
  height: 35px;
  line-height: 32px;
  border: 1px solid #f0efef;
  padding: 0 18px;
  border-radius: 4px;
}