/*
* ApprovalHead css
*/
body {
  background: #fff !important;
  font-family: 'Poppins', sans-serif !important;
}

#approval-head {
  width: 1110px;
  margin-bottom: 15px;
  z-index: 9;
  background: #fff !important;
  padding: 15px 0;
  position: sticky;
  top: 80px;
}

/* #approval-head.header_is_sticky {
  position: fixed;
  top: 0;
  box-shadow: 0 10px 10px 0 rgba(0,0,0,0.05);  
  -webkit-transition: all 0.2s linear;
} */

#approval-head button {
  margin: 0 12px;
  background: transparent;
  border: 1px solid #f5f5f5;
  height: 35px;
  text-align: center;
  text-transform: uppercase;
  font-size: 13px;
  font-family: "ProximaNova-Bold";
  color: #636363;
  padding: 0 10px;
  border-radius: 5px;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
  font-family: 'Poppins', sans-serif !important;
  min-width: auto;
}

#approval-head button:hover, #approval-head button.active {
  background: #069482;
  color: #fff;
  border-color: #069482;

}

#approval-head button:first-child {
  margin-left: 0;
}

#approval-head button:last-child {
  margin-right: 0;
}

#approval-head button.appr-tab-has-msg {
  border: 1px solid #069482;
  position: relative;
}

.appr-tab-has-msg:after {
  content: attr(data-right);
  position: absolute;
  top: -9px;
  right: -9px;
  display: block;
  width: 25px;
  height: 25px;
  border-radius: 50px;
  font-size: 10px;
  color: #fff;
  background: #069482;
  display: flex;
  align-items: center;
  justify-content: center;
}


/* .appr-tab-has-msg:before {
  content: attr(data-left);
  position: absolute;
  top: -9px;
  left: -9px;
  display: block;
  width: 19px;
  height: 19px;
  border-radius: 50px;
  font-size: 12px;
  color: #fff;
  background: #069482
} */
#approval-head::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

#approval-head::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 1px rgba(255, 255, 255, 0.3);
}

#approval-head::-webkit-scrollbar-thumb {
background-color: #f5f5f5;

}
@media (max-width: 1024px) {
  .switch_user_card{
    overflow: hidden !important;
  }
  #approval-head{
    overflow-x: scroll !important;
    overflow-y: hidden !important;
    display: flex;
    scroll-snap-type:x mandatory;
    gap: 10;
    width: 1100px;
    top: 10px;
  }
  #approval-head button{
    width: 150px;
    min-width: 150px;
  }
  
}
