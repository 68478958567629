/* 
  Page Tilte
*/

.page-title {
  font-size: 24px;
  font-weight: 600;
  color: #636363;
}

.page-sub-title
{
  font-size: 17px;
  font-weight: 600;
  color: #636363;
}