/**
 * Comment form css 
 */

 #comment-form .textarea {
  width: 70%;
  margin-right: 25px;
 }

 #comment-form textarea {
  width: 100%;
  border: none;
  background: #f9fafa;
  border-radius: 5px;
  border-left: 5px solid #f2f2f2;
  height: 80px;
}

#comment-form textarea:active, #comment-form textarea:focus {
  box-shadow: none;
  border: 1px solid #069482;
  border-left:  5px solid #069482;
}

#comment-form #submit {
  width: 30%;
  padding: 0;
	line-height: 16px;
	height: 40px;
}