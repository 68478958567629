/*
* Single Taks
*/
.icon_actions{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  grid-auto-rows: minmax(auto, auto);
}
.single-task {
  cursor: pointer;
  background: #fff;
}

.task-labels-priorities span
{
 border-radius: 8px;
 font-weight: normal;
}

.task-labels-priorities .task-priority
{
background:red;
color:#fff;
}


.single-task-container {
  padding: 19px 0;
  border-bottom: 1px solid #f6f6f6; 
  align-items: center;
}

.single-task p {
  margin: 0;
}

.task-client-detail {
  font-size: 16px;
  color: #bdbdbd;
}

.task-project-name {
  font-size: 16px !important;
  color: #212529 !important;
  font-weight: 600;
}

.single-task-container .sl-no {
  width: 5%;
  text-align: center;
  font-size: 16px;
  margin-bottom: auto;
}
.task-active-running .single-task.active .task-btn.box .btn:not(:disabled):not(.disabled){
  cursor:pointer !important;
 }
 .task-active-running .single-task .task-btn.box .btn:hover{
   background: #E6FAF8 !important;
   border-color: #E6FAF8 !important;
   color: #069482;
 }
.task-active-running .single-task .task-btn.box .btn:not(:disabled):not(.disabled){
 cursor:not-allowed;
}
.task-active-running .single-task .task-btn.box .btn:hover{
  background: #E6FAF8 !important;
  border-color: #E6FAF8 !important;
  color: #069482;
}
/* .single-task-container .task-details {
 width: 60%; 
} */

/* .single-task-container .task-btn {
 width: 20%;
 align-items: center
} */

.single-task-container .task-btn, .single-task-container .task-info-icon {
  cursor: pointer;
}

/* .single-task-container .task-total-time {
 width: 10%;
 margin-right: 10px;
 margin-bottom: 7px; 
} */

/* .single-task-container .task-shot-info {
 width: 5%; 
} */

.task-info-icon {
  margin-bottom: 8px;
  position: relative;
  transition: all 0.2s ease-in-out;
}

.task-btn-title {
  margin-left: 10px;
  font-size: 16px;
}

.task-btn > div {
  align-items: center;
 
  align-items: center;
  /* width: 50%; */
}

.task-btn > div .task-btn-title{
  line-height: 2.2;
}

/* .inactiveicon:hover {
  color: #069482;
}

.inactiveicon:hover path {
  fill: #069482 !important;
} */

#all-tasks.task-in-active .task-start:hover path,
#all-tasks.task-in-active .task-start:hover {
  fill: #069482 !important;
  color: #069482 !important;
}

.task-info-icon:before {
  display: none;
  content: attr(data-info);
	position: absolute;
	top: 5px;
	left: -396px;
	width: 400px;
	height: auto;
	background: rgba(66, 66, 66, 0.75);
	color: #fff;
	font-size: 13px;
	border-radius: 8px;
	border-top-right-radius: 0;
	padding: 10px 15px;
	letter-spacing: 0.3px;
	line-height: 1.30;
	/* border: 1px solid rgba(66, 66, 66, 0.75); */
  box-shadow: 1px 6px 10px 0 rgba(0,0,0,0.2);
  transition: all 0.2s ease-in-out;
}

/* .task-info-icon:hover:before {
  top: 17px;
  display: block;
} */

.task-info-icon:hover:before {
  top: 17px;
  display: block;
}

/*---------------------------------------------------
-------------------Active State----------------------
-----------------------------------------------------*/
.single-task.active {
  /* border-left: 5px solid #069482; */
  border-radius: 5px;
  box-shadow: 0px 10px 15px 0px rgba(193, 193, 193, 0.2);
  margin-bottom: 20px;
}

.single-task.active .single-task-container {
  border: none;
}

.task-timing {
  border-top: 1px solid #f4f4f4;
  padding: 20px 0;
  margin: 0 4% 0 5%;
  margin-bottom: 15px;
}

.task-timing span {
  width: 5%;
}

.task-timing .time {
  width: 33.33%;
  color: #bdbdbd;
}

.task-end .activeicon svg path{fill: rgb(17, 148, 129)!important;}
.task-start .activeicon svg path{fill: rgb(17, 148, 129)!important;}

.task-in-active .task-start,
.task-in-active .task-end,
.task-in-active .task-info-icon svg,
.task-in-active  .task-total-time {
  opacity: 0.5;
}

#all-tasks .task-start,
#all-tasks .task-end,
#all-tasks .task-info-icon svg,
#all-tasks  .task-total-time {
  opacity: 0.5;
}

.single-task.active .task-btn, single-task.active  .task-info-icon svg, .single-task.active .task-total-time{
  opacity: 1;
}

#all-tasks.task-in-active .task-start:hover, #all-tasks .task-end.activeicon {
  opacity: 1 !important;
}

.single-task.active .single-task-container.d-flex
{
  background: #069482;
}


.single-task.active .task-end .activeicon svg path {
  fill: #fcfcfc!important;
}

.single-task.active .task-end .task-btn-title
{
  color:#fff;
}
/* .single-task.active .task-timing p {
  color: #fff!important;
} */

.single-task.active .task-details p{
color:#fff;
}

.single-task.active .single-task-container .task-total-time
{
color:#fff;
opacity: 1!important;
}

.single-task.active .single-task-container .sl-no
{
  color:#fff;
}

.single-task.active .task-shot-info svg path,
.single-task.active .task-client-detail,
.single-task.active .task-start svg path,
.single-task.active .task-start .task-btn-title {
  fill: #96f4eb !important;
  color: #96f4eb !important;
}

#all-tasks.task-in-active .single-task.active .task-start:hover svg path{
  fill: #96f4eb !important;
  color: #96f4eb !important;
}
.taskcard_footer{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media ( max-width:1300px ){
  .task-start button, .task-end button{
    min-width: 120px;
    height: 40px !important;
    min-height: 40px !important;
  }
  .single-task-container .task-total-time{
    width: 95px !important;
    height: 40px !important;
    justify-content: center;
  }
}
/* Media Quries */
@media only screen and ( max-width: 576px ) {
  
}

.comment-atach a.linkified
{
  display: block;margin-top: 7px;
}
.board-header h4{font-size:14px;}
.trello-activity-refresh{display: inline-block;width:30px; margin-left: 10px;cursor: pointer;}
.trello-card h6{align-items: center;}

.task-owner{font-size:15px;}

.task-project-name span {
  display: inline-block;
  padding: 12px;
  line-height: 0px;
  background: #ececec;
  margin-right: 10px;
  margin-top: 10px;
  color:#111;
  font-size: 12px;
  font-weight: 600;
}
.task-project-name span.v, .task-project-name span.V
{margin-right: 10px;
  background: #0000FF;
  color:#fff;
}
.task-project-name span.r, .task-project-name span.R{
  margin-right: 10px;
  background: #008000;
  color: #fff;
}
.task-project-name span.u, .task-project-name span.U
{
  margin-right: 10px;
  background: #FF0000;
  color:#fff;
}

.task-project-name span.mr, .task-project-name span.MR
{
  margin-right: 10px;
  background: #ff8100;
  color:#fff;
}
.single-task-link
{
  max-width: 100% !important ;
  width: 100%;
} 

.single-task-link .box.task-details
{
  width: 100%;
}
.task-wrapper{
  position: relative !important;
}

.task-details .task-owner{
  display: flex !important;
  width: 38%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
 }
 .postby_name{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 180px;
 }
 @media (max-width:992px) {
    .mobile-center{
      align-items: center;
      justify-content: center;
    }
    .single-task-container .task-details{
      margin: 0 auto;
    }
 }
