/* 
  Name: AddNewFeedForm
  Path: components/AddNewFeedForm/AddNewFeedForm
*/

#addNewFeedFormWrapper {
  position: relative;
  margin-top: 0;
  padding: 25px;
  background: #fff;
  z-index: 9999;
  border-radius: 5px;
  top: 0;
  display: block;
  transition: all 0.1 cubic-bezier(0.785, 0.135, 0.15, 0.86);
  border: 1px solid #EBF3FB;
  border-radius: 8px;
  /* box-shadow: 0px 4px 32px 0px rgba(108,161,206,0.12); */
}
#addNewFeedFormWrapper label{
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #495057;
}
#addNewFeedTitle{
  border-radius: 8px;
  min-height: 50px;
  border-color:#ADB5BD;
}
.feedImg {
  width: 50%;
}


.feedImgPosition {
  width: 50%
}

.feedImg p {
  margin-bottom: 6px;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #495057;
}

.feedImg label {
  border: 1px solid #ADB5BD;
  width: 95%;
  padding: 0;
  padding-left: 15px;
  border-radius: 8px;
  min-height: 50px;
  margin-bottom: 10px;
  line-height: 50px;
}

.feedImgPreview {
  position: relative;
}

.feedImgPreview svg {
  position: absolute;
	top: 0;
	left: 0;
	background: rgb(255, 227, 227);
  color: red;
	font-size: 19px;
	width: 20px;
	height: 20px;
  border-radius: 8px;
  cursor: pointer;
}
.feedImgPreview svg path{
  padding: 5px;
}
.feedImgPreview span {
  width: 80px;
  height: 80px;
  display: block;
  background-size: cover !important;
 border-radius: 8px;
}

.newFeedPostBtnWrapper button {
  min-width: 100px;
}

#addNewFeedFormWrapper .alert{
  padding: 5px 10px;
	border-radius: 5px;
}

#addNewFeedFormWrapper input.error,
#addNewFeedFormWrapper textarea.error {
  border-color: red;
  box-shadow: 0 0 0 0.2rem rgba(255, 0, 0, 0.25)
}

/* Editor Styles */
.rdw-editor-wrapper .rdw-editor-toolbar {
  margin-bottom: 0;
  border: 1px solid #ADB5BD;
  /* border-bottom: none; */
  border-top-left-radius: 8px; 
  border-top-right-radius: 8px;
  padding: 15px;
  padding-bottom: 0;
}

.rdw-editor-main {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid #ADB5BD;
  border-top: none;
  margin-top: 0;
  min-height: 250px;
  padding: 15px;
}

.rdw-option-active.rdw-option-wrapper {
  box-shadow: 0px 0px 3px rgba(6, 148, 130, 0.5);
	border: 1px solid #069482;
	background: #f8fafa;
}

.public-DraftStyleDefault-block {
  margin-top: 0 !important;
}
.feedImgPosition select.form-control{
  border-radius: 8px !important;
  min-height: 50px;
  border-color: #ADB5BD;  
}
.cancel_btn_feed{
  background: none;
  border-radius: 8px;
  border: 1px solid #F93154;
  color: #DB3E3E;
  max-width: 160px;
  min-height: 48px;
  font-weight: 500;
  margin-right: 10px;
  width: 100%;
}
.cancel_btn_feed:hover{
  background:#DB3E3E;
  color: #fff;
  border: 1px solid #F93154;
}
.add_btn{
  background: #069482;
  color: #fff;
  border-radius: 8px;
  max-width: 160px;
  min-height: 48px;
  font-weight: 500;
  width: 100%;
}
.add_btn:hover{
  background: #187C72;
  color: #fff;
  border: 1px solid #187C72;
}