#approval-head{
  width: 1110px;
  margin-bottom: 15px;
  z-index: 9;
  background: #fff !important;
  padding: 15px 0;
  position: sticky;
  top: 80px;
}
#approval-head button:focus{
  background: #069482 !important;
  color: #fff !important;
}
/* #approval-head {
  width: 1110px;
  margin-bottom: 15px;
  z-index: 9;
  background: none!important;
  padding: 15px 0;
  position: sticky;
  top: 80px;
  right: 0;
  background: #fff !important;
} 

#approval-head.header_is_sticky {
  position: fixed;
  top: 60px !important;
  box-shadow: 0 10px 10px 0 rgba(0,0,0,0.05);
  -webkit-transition: all 0.2s linear;
} 
/* #approval-head button {
  min-width: 150px;
  margin: 0 34px;
  background: transparent;
  border: 1px solid #f5f5f5;
  height: 35px;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  font-family: "ProximaNova-Bold";
  color: #636363;
  padding: 0 17px;
  border-radius: 5px;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

#approval-head button:hover, #approval-head button.active {
  background: #069482;
  color: #fff;
  border-color: #069482
}
#approval-head button:focus{
  background: #069482 !important;
  color: #fff !important;
  border-color: #069482 !important;
}

#approval-head button:first-child {
  margin-left: 0;
}

#approval-head button:last-child {
  margin-right: 0;
}

#approval-head button.appr-tab-has-msg {
  border: 1px solid #069482;
  position: relative;
}

.appr-tab-has-msg:after {
  content: attr(data-right);
  position: absolute;
  top: -9px;
  right: -9px;
  display: block;
  width: 19px;
  height: 19px;
  border-radius: 50px;
  font-size: 12px;
  color: #fff;
  background: #069482
} */


/* .appr-tab-has-msg:before {
  content: attr(data-left);
  position: absolute;
  top: -9px;
  left: -9px;
  display: block;
  width: 19px;
  height: 19px;
  border-radius: 50px;
  font-size: 12px;
  color: #fff;
  background: #069482
}

*/
