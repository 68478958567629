/* cc person single */

.cc-all-peoples {
  position: absolute;
  padding: 15px;
  top: 37px;
  left: 0;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  z-index: 1;
  min-width: 180px;
  box-shadow: 0 8.604px 10px 0px rgba(179, 179, 179, 0.2);
  display: none;
}

.cc-all-peoples ul li, .cc-all-peoples ul {
  border: none;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
}

.cc-all-peoples input[type="checkbox"] {
  height: 16px;
}

.cc-all-peoples label {
  margin-bottom: 0;
  position: relative;
  top: 5px;
  left: 4px;
}

.cc-person-single {
  margin-bottom: 6px;
}

.cc-person-single:last-child {
  margin-bottom: 0;
}
