/*
* ApprovalContainer styles
*/

.caros-title {
  font-size: 16px;
  padding: 29px 0 24px 0;
}
@media (max-width: 1024px) {
.adminnotipage{
  width: 100% !important;
}
}
