/*
* Approveal Leave Card styles
*/
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600&family=Poppins:wght@200;300;500;600;700;800;900&display=swap');

.leave-appr-buttons button {
  width: 50%;
}

.leave-appr-buttons button:first-child {
  margin-right: 13px;
}

.leave-appr-buttons button:last-child {
  margin-left: 13px;
}
.caro-box{
  overflow: hidden !important;
  max-width: 100% !important;
  margin-bottom: 25px !important;
  border: 1px solid #E6EFFA !important; 
  background: #fff;
  border-radius: 8px !important; 
  font-family: 'Poppins', sans-serif !important;
}
.aprov_main-wrapper .shift-current  p.appr-date-title{
  font-size: 15px;
color: #212529;
font-weight: 500;
padding: 0 0 10px 0;
}
.aprov_main-wrapper .shift-current p.appr-data-data{
height: auto !important;
line-height: 10px !important;
border: 0;
padding: 0 !important;
border-radius: 4px;
color: #6C757D;
font-size: 14px !important;
margin: 0;
padding: 0;
}
.aprov_main-wrapper .shift-current{
  display: block !important;
}

.leave-appr-buttons
{
  max-width: 250px;
  width: 100%;
  float: right;
}
.appr-btn-approve {
  max-width: 250px;
  width: 100%;
  float: right;
}
.reson_box{
  padding: 10px 0;
}

.reson_box h2{
font-size: 16px;
color: #212529;
font-weight: 500;
padding: 0 0 0 0;
}
.reson_box p.appr-msg{
color: #6C757D;
font-size: 14px !important; 
margin-bottom: 10px !important;
}
.appr-approved-msg .title{
  font-weight: 500;
  color: #212529;
}
.appr-approved-msg .appr-approver{
  color: #6C757D;
}

.leave-slider-block1
{
  width:75%;
}

.leave-slider-block2
{
  width:24%;
}
.appr-head h5
{
  font-size: 16px;
}

section#shift-unblock-module .caro-box, #break-module .caro-box {
  width: 323px;
  display: inline-block;
}
#approval-head.header_is_sticky {
  background: #fff !important;
  right: 0;
  width: 100%;
  position: sticky !important;
}
/* #approval-head.header_is_sticky {
  position: fixed;
  top: 107px!important;
  box-shadow: 0 10px 10px 0 rgba(0,0,0,0.05);
  -webkit-transition: all 0.2s linear;
  background: #fff!important;
  width: 85%;
  margin-left: -41px;
} */
.leave_boxes{
  margin-left: 0 !important;
  margin-right: 0 !important;
}
@media only screen and (max-width: 1280px) {
  #approval-head.header_is_sticky {
    width: 90.4%;
  }
}
@media (max-width: 992px) {
.reson_box{
  flex-direction: column;
}
.leave-slider-block2{
  width: 100%;
}
}
@media (max-width: 600px) {
.appr-btn-approve{
  float: inherit !important;
}
}
.reson_box .attach_img_close{
  width: 100% !important;
  max-width: 100% !important;
}
.reson_box  .attch-items{
  display: flex;
}
.reson_box .attch-items .bg-medium-attachment{
  display: flex;
  width: 100%;
  gap: 10px;
  background: #fff;
  align-items: center;
}
.reson_box .attch-items .bg-medium-attachment p{
  margin: 0;
  padding: 0;
  color: #212529;
}
.reson_box .attachment-items > div {
  width: 100%;
  display: flex;
}
.reson_box  #attachment label{
  padding: 15px !important;
}
.reson_box  .attch-items .attach_img_close{
  max-width: 100% !important;
  width: 100% !important;
}
.reson_box  .attch-items .remove_img_btn{
  top: -7px;
  left: -8px;
}
.reson_box  .attch-items .bg-medium-attachment img{
  width: 60px;
  height: 60px;
  min-width: 60px;
  object-fit: cover;
}
