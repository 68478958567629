/**
* Live Activity 
*/
.liveactive_desiner #LoadingWrapper{
  display: contents !important;
}
#live-view {
  margin-top: 0;
}
#live-view .no-task-running{
  margin-top: 0 ;
}
#la-list-header p{
  list-style: none;
}

.la-name-body {
  text-align: center;
}

.la-em-id-body p {
  text-align: center;
}

.la-in-list {
  align-items: center;
  justify-content: center;
  margin: 0 4px;
  background: #fff;
}

.la-in-list span, .la-in-list > div {
  width: 33.333%;
  text-align: center;
}

.la-rm-time-body {
  text-align: center;
}

.la-task-body .la-in-list .activity-indicator{
  width: 100%
}

.la-in-list > p, .la-in-list > div{
  text-align: center;
  width: 33.333%;
  margin: 0
}

.la-in-list > div{
  height: 63px;
}

.activity-indicator.active svg path {
  fill: #07a08d !important
}

.la-data-body {
  font-size: 16px;
  color: #212529;
}

.la-data-body.la-name-body {
  width: 16.3%;
	text-align: left;
	padding-left: 49px;
  padding-top: 19px;
  line-height: 1;
}

.la-data-body.la-time-body {
  width:40.6%;
}

.la-data-body.la-task-body {
  width: 24.3%;
}

.la-data-body.la-task-body > div {
  width: 50%;
}

.la-data-body.la-rm-time-body {
  width: 100%;
  display: contents;
}


.la-name-body p, .la-time-body, .la-in-list, .la-rm-time-body p {
  border-bottom: 1px solid #f7f7f7;
}

#la-list-header h3,
#la-list-header h4 {
  font-weight: 500;
}

#la-list-header h3 {
  font-size: 18px;
  color: #fff;
  text-align: center;
}

#la-list-header .la-hd {
  background: #069482;
  margin: 0 4px;
  min-height: 100px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-weight: 600;
}

#la-list-header .la-name h3, .la-name-body p {
  text-align: left;
  padding-left: 50px;
}

#la-list-header .la-time,
#la-list-header .la-task {
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
}

#la-list-header .la-name h3,
#la-list-header .la-em-id h3 {
  line-height: 100px;
  margin-bottom: 0;
}


#la-list-header .la-time h3,
#la-list-header .la-task h3,
#la-list-header .la-time h4,
#la-list-header .la-task h4 {
  height: 50px;
  padding: 0;
  margin: 0;
  line-height: 50px;  
}

.la-time h3, .la-task h3 {
  width: 100%;
}

.la-time h4, .la-task h4 {
  width: 33.333%;
  flex: 1;
  font-size: 16px;
  color: #fff;
  background: #07a08d;
}

.la-rm-time-hd h3 p {
  line-height: 1.2;
  margin-bottom: 0;
}

.la-rm-time-hd h3 p:first-child {
  margin-top: 5px;
}

.la-over-time {
  color: red !important
}

#live-view .doto-users 
{
  display:none;
}


#live-view .doto-users.item-show
{
  display: block;
}

.extra-mile{color:red;}

.designer-livedata .la-data-body.la-name-body {
  width: 32.7%;
  border-bottom: 1px solid #f7f7f7;
}

.designer-livedata .la-data-body.la-task-body {
  width: 32.7%;
  border-bottom: 1px solid #f7f7f7;
}
.designer-livedata .la-data-body.la-rm-time-body {
  width: 32.5%;
  border-bottom: 1px solid #f7f7f7;
}

.designer-livedata .la-rm-time-body .r-hours {
  text-align: right;
  
}

.designer-livedata .activity-indicator
{
  width: 28%!important;
  text-align: left;
}



.mytasklist-designers span{
  display: block;

}

.mytasklist-designers label {
  display: inline-block;
  margin-bottom: .5rem;
  font-weight: 700;
  font-size: 16px;
  font-weight: 600;
}

.mytasklist-designers .change-assignee .container
{
  margin-top: 30px;
}

.mytasklist-designers .change-assignee .container .items  .row
{
  margin-bottom: 10px;
  border-bottom: 1px solid #e0dede;
  padding:5px 0px;
}
.showdetail .col-sm-6{
  background: #fff;
  
}
.showdetail .row {
  margin-left: 4px;
  margin-right: 4px;
}
.bgcolor .col-sm-6 {
  background-color: #f7f7f7;
  display: flex;
  padding-top: 15px;
}

.projectdetail .col-sm-6{
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #f7f7f7;
  color: #9c9c9c;
  line-height: 25px;
}
.projectdetail .task-name{
  color: #323232;
  font-family: "ProximaNova-Semibold";
}
.bgcolor{
  margin-top: 15px;
}
.small_gap{
  height: 15px;
  display: block;
}
.task-count
{
  cursor: pointer;
}
.liveactive_desiner tbody p{
 
  margin: 0 !important;
}
.liveactive_desiner .la-data-body{
  height: auto !important;
  line-height: normal;
}
.theme_table tbody tr{
  position: relative;
}
.theme_table tbody tr::after{
  content: "";
  position: absolute;
  width: auto;
  height: 1px;
  background: #DEE2E6;
  bottom: 0;
  z-index: 99;
  left: 30px;
  right: 30px;
}
.live_activity_page h1.card-title{
  margin: 0 !important;
  font-weight: 600;
  font-family: 'Poppins', sans-serif !important;
  text-align: left !important;
  color: #212529 !important;
  font-size: 20px;
  letter-spacing: 0.00938em;
  padding: 30px;

}
.live_activity_page{
  border-radius: 16px;
}
.la_first_head{
  background: #F0F6FA;  
}
.la_se_head{
  background: #F5FBFF !important;
}
tr.inner_table_header th:first-child{
  padding-left: .75rem !important;
} 
tr.inner_table_header th:last-child{
  padding-right: .75rem  !important; 
}
tr.inner_table_header{
  border-bottom: 0 !important;
}
tr.inner_table_header th{
  border-bottom: 0;
  width: 136px;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}
tr.inner_table_header td:first-child{
  padding-left: .75rem  !important; 
} 
tr.inner_table_header td:last-child{
  padding-right: .75rem  !important; 
}
tr.inner_table_header td{
  border-bottom: 0;
  width: 136px;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}
#live-view{
  display: contents !important;
}
#live-view #LoadingWrapper{
  display: contents !important;
}