/**
* App Main styles and globals
*/

@import './styles/checkbox.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
body#body {
  /* background: #fcfcfc !important; */
  /* background: #fcfcfc !important; */
  background: #F2F5F7 !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important; 
  line-height: 1.5;
} 
/* #LoadingWrapper{
  position: relative;
  left: 0;
  right: 0;
  width: 100vw;
} */
.xhide div:first-child{
  overflow-x: hidden !important;
}
button.btn-secondary.focus, button.btn-secondary:focus{
  background: #004c3f !important;
  color: #fff !important;
}
input[type='checkbox'], input[type='radio']
{
  appearance: none;
}

img {
  width: 100%;
  height: auto;
  display: block;
}

html, body, p, h1, h2, h3, h4, h5, h5, form, label, fieldset, li, ul, input, button {
  margin: 0;
  padding: 0
}
/*-----------Proxima nova fontfamily-----------*/
/* @font-face {
	font-family: "ProximaNova-Regular";
	src: url(fonts/ProximaNova-Regular.otf);
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "ProximaNova-Semibold";
	src: url(fonts/ProximaNova-Semibold.otf);
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "ProximaNovaCond-Light";
	src: url(fonts/ProximaNovaCond-Light.otf);
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "ProximaNova-Bold";
	src: url(fonts/ProximaNova-Bold.otf);
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: "ProximaNova-Black";
	src: url(fonts/ProximaNova-Black.otf);
	font-weight: normal;
	font-style: normal;
} */
/*-----------End Proxima nova fontfamily-----------*/
/***********SCROLLBAR***************/
*::-webkit-scrollbar {
  width: 8px;
}
 
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
*::-webkit-scrollbar-thumb {
  background-color: #afc3d8;
  outline: 1px solid rgb(234, 245, 255);
}
#new-side-menu::-webkit-scrollbar, .xhide ::-webkit-scrollbar{
  width: 0;
}
/***********GLOBAL STYLS***************/
.cursor-pointer {
  cursor: pointer;
}

.no-padding-l-r {
  padding: 0 !important
}

/* Bootstrap card styles reset */
div.card .card-body {
  padding: 0;
}

.card {
  border: none !important;
}

button, select {
  box-shadow: none !important
}

select:focus, select:active {
  border-color: #119481 !important
}

select {
  border-radius: 5px !important;
  border: 1px solid #f2f2f2;
}

select option {
  border-radius: 50px !important
}

/*************Bootstrap Reset Styles*************/
#filter .people select, #filter .hours select,
.form-control {
  font-size: 14px;
  background-color: transparent;
  border: 1px solid #f2f2f2;
}


/* filter */

.filter-row {
  padding: 25px;
  border-radius: 25px;
  /* background: #fafbfb;
  border: 1px solid #eaf3f3 */
}

.filter-row .date-col p, .filter-row .hours p {
  margin-bottom: .1rem 
}


/* Empty Space */
.space-12 {
  width: 12px;
  display: inline-block;
}


/*************************************
************CheckBox******************
**************************************/
input[type=checkbox], input[type=radio],
input[type=checkbox]:focus, input[type=radio]:focus,
input[type=checkbox]:active, input[type=radio]:active {
  box-shadow: none;
  outline: none;
}

label {
  cursor: pointer;
}


/***********OVERLAY SETTINGS***************/.overlay-dark-gray, .overlay-medium-gray,.overlay-light-gray {  position: fixed;  top: 0;  left: 0;  right: 0;  bottom: 0;  width: 100%;  height: 100%;  display: block;  z-index: 9999;}

.overlay-dark-gray{
  background: rgba(0,0,0,0.65);
}

.overlay-medium-gray {
  background: rgba(0,0,0,0.45);
}

.overlay-light-gray {
  background: rgba(0,0,0,0.25);
}


/******************************************
**********MEDIA QURIES********************/
@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1170px;
  }
}
.la-filter .form-check-input::after{
  top: -2px !important;
}
.form-check-input::after{
  top: 0 !important;
}
.themeContentBody{
  margin-left: 250px;
  width: 100%;
  margin-top: 100px;
  padding-left: 30px;
  padding-right: 30px;
  font-family: 'Poppins', sans-serif !important;
  margin-bottom: 50px;
}
a:hover{
  text-decoration: none !important;
}
.themeCard{
  padding: 30px;
  border-radius: 16px !important;
  background:#fff;
  -webkit-box-shadow: 0px 12px 24px 0px rgba(108,161,206,0.08);
  -moz-box-shadow: 0px 12px 24px 0px rgba(108,161,206,0.08);
  box-shadow: 0px 12px 24px 0px rgba(108,161,206,0.08);
}
.themeCard_title{
  padding:0 0 30px 0;
  margin: 0 !important;
  font-weight: 600;
  font-family: 'Poppins', sans-serif !important;
  text-align: left !important;
  color: #212529 !important;
  font-size: 20px;
  letter-spacing: 0.00938em;
}
.themefullwidthContentBoday{
  margin-left: 0 !important;
}
.confirm_modal .modal-content{
  border: none !important;
  border-radius: 8px;
}
.confirm_modal .modal-header{
  border-bottom: 0;
  font-size: 20px;
  color: #212529;
  font-weight: 400;
  padding-left: 30px;
}
.confirm_modal .modal-body{
  padding: 0 30px 30px 30px;
}
.confirm_modal .modal-body h6{
  font-size: 16px;
  font-weight: 400;
  color: #212529;
  margin: 0;
  padding: 0 0 8px 0;
}
.confirm_modal .modal-body {
  font-size: 14px;
  color: #6C757D;
}
.confirm_modal .modal-body .mob_btn_action{
  width: 100%;
}
.confirm_modal .button_group{
  margin: 0 auto;
}
.confirm_modal .modal-body button.cancel-button{
  float: left;
  min-width: 160px;
  min-height: 48px;
  border-radius: 12px;
  background: #FEF5F4;
  border-color: #FEF5F4;
  color: #FE2712;
  margin-top: 15px;
  margin-right: 15px;
}
.confirm_modal .modal-body button.cancel-button:hover, .confirm_modal .modal-body button.cancel-button:focus{
  background: #FE2712 !important;
  border-color: #FE2712 !important;
  color: #FEF5F4;
}
.confirm_modal .modal-body button.submit-btn{
  float: left;
  min-width: 160px;
  min-height: 48px;
  border-radius: 12px;
  background: #219186;
  border-color: #219186;
  color: #fff;
  margin-top: 15px;
}
.confirm_modal .modal-body button.submit-btn:hover, .confirm_modal .modal-body button.submit-btn:focus{
  background: #004c3f !important;
  border-color: #004c3f !important;
  color: #fff;
}
input:focus{
  box-shadow: none !important;
  -webkit-appearance: none;
}
.react-calendar{
 border: 1px solid #D2DFEC !important;
  border-radius: 8px;
  box-shadow: 0px 4px 32px 0px rgba(108,161,206,0.12);
  font-family: 'Poppins', sans-serif !important;
  }
  .react-calendar__month-view__weekdays__weekday abbr{
    font-size: 13px !important;
    font-weight: 500;
    text-transform: capitalize;
  }
.react-calendar__navigation{
    display: flex;
    flex-direction: row;
    padding: 10px;
   }
.react-calendar .react-calendar__navigation__prev-button{
    width: 32px;
    height: 60px;
    font-size: 0 !important;
    background: url('./images/rcalen_prevbtn_icon.png') no-repeat right center !important;
    background-size: 80% !important; 
   }
.react-calendar .react-calendar__navigation__next-button{
    width: 32px;
    height: 60px;
    font-size: 0 !important;
    background: url('./images/rcalen_next_btn.png') left center no-repeat !important;
    background-size: 80% !important; 
   }
.react-calendar__navigation button:nth-child(2){order: 2; }
.react-calendar__navigation button:nth-child(3){
     order: 1;
     color: #212529 !important;
     font-size: 16px !important;
     font-weight: 500 !important;
     text-align: left !important;
    }
.react-calendar__navigation button:nth-child(4){order: 3;}
.react-calendar__navigation button:hover{
    background: transparent;
  }
  .react-calendar button.react-calendar__navigation__arrow{
    font-size: 0 !important;
  }

.react-calendar .react-calendar__navigation {
  margin-bottom: 20px;
  padding: 0 20px;
  border-bottom: 1px solid #d2dfec;
  min-height: 60px;
}
.react-calendar__tile:disabled {
  background-color: #fff;
  opacity: .5;
}
b, strong {
  font-weight: 600 !important;
}
.rdtPicker{
  border: 1px solid #D2DFEC !important;
  border-radius: 8px;
  box-shadow: 0px 4px 32px 0px rgba(108,161,206,0.12);
  font-family: 'Poppins', sans-serif !important;
  width: 100%;
  max-width: 350px;
}
.rdtDays table thead tr:first-child{
  height: 60px;

}

.rdtDays .rdtPrev{
  width: 32px;
  height: 60px;
  font-size: 0 !important;
  background: url('./images/rcalen_prevbtn_icon.png') no-repeat right center !important;
  background-size: 80% !important; 
}
.rdtDays .rdtNext{
  width: 32px;
  height: 60px;
  font-size: 0 !important;
  background: url('./images/rcalen_next_btn.png') left center no-repeat !important;
  background-size: 80% !important; 
}
.rdtDays .rdtSwitch{
  color: #212529 !important;
  font-size: 16px !important;
  font-weight: 500 !important;  
}
.rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default;
  font-size: 13px !important;
  font-weight: 500;
  text-transform: capitalize;
  height: 50px;
}
.rdtPicker td, .rdtPicker th {
  text-align: center;
  height: 28px;
  padding: 10px !important;

}
.table{
  margin-bottom: 0;
}
.form-control::placeholder{
  font-weight: 500;
}
.ReactToast.info .ReactToast--title{
  color: #7D64AF;
}
.ReactToast.success .ReactToast--title{
  color: #00B74A;
}
.ReactToast.warning .ReactToast--title{
  color: #FFA900;
}
.ReactToast.error .ReactToast--title{
  color: #DB3E3E;
}
.ReactToast.info{
  border:none ;
}
.ReactToast.isOpen {
  transition: right 1s ease-out;
  right: 10px;
  top: 70px !important;
  border-radius: 8px !important;
  padding: 20px;
  font-family: 'Poppins', sans-serif !important;
  box-shadow: 0px 4px 32px 0px rgba(108,161,206,0.12) !important;
  border: 1px solid #D2DFEC !important;
  min-width: 450px;
}
.ReactToast--title{
  font-size: 16px !important;
  font-weight: 700;
  margin: 0  !important;
  color: #212529;
  padding: 0 0 8px 0;
}
.ReactToast--description{
  font-size: 12px;
  color: #6C757D;
  line-height: 20px;
}
.ReactToast--close{
  width: 25px;
  height: 25px;
  background: #F4F6F9 !important;
  border-radius: 4px;
  right: 10px !important;
  top: 10px !important;
  text-align: center;
  line-height: 0;
  padding: 0 !important;
}
.xhide > div:nth-child(3){
  width: 0 !important;
} 

.dtablefull{
  display: contents;
}
.sub-level .p0{
  padding: 0;
}
.sub-level table thead{
  background: #EDF4FA;
}
.fullwidth_mobilemenu .mobile-none {
  display: none;

}
.fullwidth_mobilemenu .mobile-active {
  display: block !important;
}
.fullwidth_mobilemenu .mobile-active .overlay_menu{
  background: rgba(0, 0, 0, 0.178);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 9;
} 
.fullwidth_mobilemenu  .mobile_toggle_menu{
  display: none;
}
.fullwidth_mobilemenu  .mobile_toggle_menu{
  display: block;
  background: transparent;
  color: #004c3f;
  border: none;
  position: relative;
}


.fullwidth_mobilemenu  .SideNavbar{
  width: 0;
}
.fullwidth_mobilemenu  #new-side-menu{
  position: fixed;
  width: 250px;
  background: #fff;
  height: 100vh;
  left: 0;
  z-index: 110;
  opacity: 1 !important;
  top: 0;
}
.fullwidth_mobilemenu .menuicon_box button{
  border: 0;
  background: transparent;
}
.fullwidth_mobilemenu .menuicon_box span.menuicon{
  width: 40px;
  height: 40px;
  display: block;
  border: 0;
  background: url(./images/menuicon.png) no-repeat;
}
.fullwidth_mobilemenu .menuicon_box span.menuclose{
  width: 40px;
  height: 40px;
  display: block;
  border: 0;
  background: url(./images/menuicon.png) no-repeat;
  background-position: 0px -41px;
}
.menuicon_box{
  display: none;
}
.fullwidth_mobilemenu .menuicon_box{
  display: block;
}
.menuicon_box_active{
  position: relative;
}
.faheem{
  display: none;
}
.menuicon_box_active .faheem{
  position: relative;
  display: block;
  z-index: -1;
}
.menuicon_box_active .faheem::before{
  background: rgba(0, 0, 0, 0.178);
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: -10000;
  content: "";
}
.slick-dots{
  display: none !important;
}
/* .menuicon_box_active::before{
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background: #22292138;
} */
@media (max-width:580px) {
  .ld_btn, .sli_btn {
    font-size: 13px !important;
    line-height: 15px !important;
  }
}
