/* 
  Name: Feed.General
  Path: components/Feeds/Feed.General
*/

#feed_general {
  width: 100%;
  padding: 25px;
  margin-bottom: 20px;
  background: #fff;
  background-size: cover !important;
  background-position: top center !important;
}

#feed_general .card{
  padding: 25px;
  padding-top: 0;
  /* margin-top: 30px; */
  /* align-items: center; */
  background-size: cover !important;
  background-position: top center !important;
}

#feed_general .card.img-background {
  height: 300px;
  justify-content: center;
}

.img-left {
 flex-direction: row !important;
}

.img-right {
  flex-direction: row-reverse !important
}

.img-top {
  flex-direction: column !important
}

.img-bottom {
  flex-direction: column-reverse !important
}

.feedGen_bg_img_card, .feedGen_bg_img_card *{
  background-color: transparent;
}

#feed_general .feed_image {
  margin-top: 25px;
  width: 100%;
}

#feed_general .feed_image img {
  border-radius: 5px;
  overflow: hidden;
}

#feed_general h3 {
  font-size: 20px;
  font-weight: 400;
  color: #636363;
  white-space: pre-line;
}

#feed_general h2 {
  color: #069482;
  margin: 10px 0 !important;
}

#feed_general div.img-right > div,
#feed_general div.img-left > div {
  width: 50%;
}

#feed_general .feed-item {
  box-shadow: none;
}

/* Editor Typography */
.rdw-link-modal {
  height: auto !important;
  box-shadow: 3px 3px 5px rgba(191, 189, 189, 0.3);
  border-radius: 5px;
}

.rdw-link-modal-input {
  height: 33px !important;
  line-height: 33px !important;
}

.rdw-link-modal-label {
  margin-bottom: 0;
}

.rdw-link-modal-target-option > span {
  margin-top: -5px;
}

#feed_general .feed-item {
  width: 100%;
  align-items: flex-start;
}

#feed_general .feed-item.img-left,
#feed_general .feed-item.img-right {
  margin-top: 25px;
}

.feed_general_has_bg {
  margin-top: auto;
  display: block;
  padding: 9px 0;
  margin: 0;
  background: #fff;
}

#feed_general .feed-item.img-left .feed_image,
#feed_general .feed-item.img-right .feed_image {
  margin-top: 0;
  padding: 0 10px;
}

#feed_general .feed-item.img-left #editor_wrapper,
#feed_general .feed-item.img-right #editor_wrapper {
   
}

#feed_general .feed-item.img-top .feed_image,
#feed_general .feed-item.img-bottom .feed_image {
  margin: 15px 0;
}

#editor_wrapper {
  width: 100%;
  white-space: pre-line;
}

#feed_general > .card {
  padding: 0;
}

#feed_general ol {
  margin: 0;
  padding: 0;
}

.rdw-link-modal-target-option {
  display: flex;
  line-height: 1.2;  
}

.rdw-link-modal-buttonsection {
  margin: 0 !important;
  padding: 0;
}

.rdw-link-modal-buttonsection button:first-child {
  background: #069482;
  border: 1px solid #069482
}

.rdw-link-modal-buttonsection button:last-child {
  background: #6c757d;
  border: 1px solid #6c757d
}

.rdw-link-modal-buttonsection button {
  color: #fff;
  margin: 0;
  margin-right: 10px;
}

.rdw-link-modal-target-option input {
  width: 0;
  height: 0;
  margin-right: 19px !important;
}

.rdw-link-modal-target-option input[type="checkbox"]:after {
  left: 1px;
}

#openLinkInNewWindow{

}