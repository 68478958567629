/*
* ApprovalLeaveComment style
*/

.appr-leave-comment {
  margin-bottom: 12px;
}

.appr-comment-icon {
  width: 19px;
	height: 16px;
  background: url(../../../../images/leave_comment_icon.png) no-repeat top left;
  background-size: 38px auto;
}
.appr-comment-icon.with-comment {
  width: 19px;
	height: 16px;
  background: url(../../../../images/leave_comment_icon.png) no-repeat top left;
  background-size: 38px auto;
  background-position-x: 100%!important;
  cursor: pointer;
}

.appr-comment-icon.appr-leave-approved {
  background: url(../../../../images/leave_comment_icon.png) no-repeat top left;
  background-size: 38px auto;
}

.approval-status-icon {
  margin-right: 10px;
  width: 16px;
  height: 15px;
  background: url(../../../../images/approve-reject.png) no-repeat top left;
  background-size: 30px auto;
}

.approval-status-icon.appr-leave-approved {
  background-position-x: 100% !important;
}



.appr-leave-comment p {
  margin-bottom: 0;
}

.caro-box .appr-leave-comment:first-child {
  margin-top: 15px;
}