/* 
    NewSidemenuItem
    path : components/NewSideMenu/NewSideMenuItem
*/

.nsmi_menu-icon {
    width: 35px;
    height: 35px;
    display: block;
}

