/* 
  Name: LoadingBox
  Path: Components/LoadingBox
*/

/* #LoadingBox {
  width: 300px;
  height: 200px;
  display: block;
  background: linear-gradient(91deg, #ffffff, #969696, #ffffff, #969696, #ffffff, #969696);
  background-size: 1200% 1200%;

  -webkit-animation: AnimationName 11s ease-in infinite;
  -moz-animation: AnimationName 11s ease-in infinite;
  -o-animation: AnimationName 11s ease-in infinite;
  animation: AnimationName 11s ease-in infinite;
}

@-webkit-keyframes AnimationName {
    0%{background-position:0% 49%}
    50%{background-position:100% 52%}
    100%{background-position:0% 49%}
}
@-moz-keyframes AnimationName {
    0%{background-position:0% 49%}
    50%{background-position:100% 52%}
    100%{background-position:0% 49%}
}
@-o-keyframes AnimationName {
    0%{background-position:0% 49%}
    50%{background-position:100% 52%}
    100%{background-position:0% 49%}
}
@keyframes AnimationName {
    0%{background-position:0% 49%}
    50%{background-position:100% 52%}
    100%{background-position:0% 49%}
} */

/* .LoadingBox {
  /* width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 4px solid;
   border-color: #055949 rgba(0,0,0,0.1) rgba(0,0,0,0.1);
  animation: spin .6s ease infinite; 
  margin: 0 auto;  




  width: 100%;
  height: 100%;
  animation: Loading 0.6s cubic-bezier(0.03, 0.38, 0.47, 0.74) infinite;
  animation-fill-mode: forwards;
  background: #f6f7f8;
  background: linear-gradient(to right, #fafafa 8%, #dfdfdf 38%, #fafafa 54%); 
  background: linear-gradient(to right, #fafafa, #efefef, #fafafa);
  /* background-size: 1000px 640px; 
  background-size: 125% 640px;
  background-size: cover;  
  position: relative;
  /* position: absolute;
	left: 0;
	right: 0;
	top: 0;
  bottom: 0; 
  min-height: 35px; 
}

.LoadingBoxSmall {
  animation-duration: 1.2s
}

/* @keyframes spin {
  0% { transform: rotate(0deg) }
  
  100% { transform: rotate(359deg) }
} 

@keyframes Loading{
  0%{
     background-position: -468px 0 ;
    background-position: -100px 0;
  }
  100%{
      background-position: 1000px 0;
  }
}  */

.main_banner .LoadingBox{
  margin: 25px 0 15px 0 !important;
  max-width: 200px;
  width: 100%;
  height: 100%;
  animation: Loading 0.6s cubic-bezier(0.03, 0.38, 0.47, 0.74) infinite;
  animation-fill-mode: forwards;
  background: #f6f7f8;
  /* background: linear-gradient(to right, #fafafa 8%, #dfdfdf 38%, #fafafa 54%); */
  background: linear-gradient(to right, #219185, #068675, #219185);
    animation: shine-lines 2s infinite ease-out;
  /* background-size: 1000px 640px; */
  background-size: 125% 640px;
  background-size: cover;  
  position: relative;
  /* position: absolute;
	left: 0;
	right: 0;
	top: 0;
  bottom: 0; */
  min-height: 35px;
}

.LoadingBox {
  width: 100%;
  height: 100%;
  animation: Loading 0.6s cubic-bezier(0.03, 0.38, 0.47, 0.74) infinite;
  animation-fill-mode: forwards;
  background: #ddd;
  /* background: linear-gradient(to right, #fafafa 8%, #dfdfdf 38%, #fafafa 54%); */
  background-image: linear-gradient(90deg, #fafafa 0px, rgba(247, 247, 247, 0.973) 40px, #fafafa 80px);
  background-size: 600px;
  animation: shine-lines 2s infinite ease-out;
  /* background-size: 1000px 640px; */
  background-size: 125% 640px;
  background-size: cover;  
  position: relative;
  /* position: absolute;
	left: 0;
	right: 0;
	top: 0;
  bottom: 0; */
  min-height: 35px;
}

.LoadingBoxSmall {
  animation-duration: 1.2s
}

/* @keyframes Loading{
  0%{
      /* background-position: -468px 0 
      background-position: -100px 0
  }
  100%{
      background-position: 1000px 0
  }
} */

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40%, 100% {
    background-position: 140px;
  }
}