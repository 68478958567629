/* .addNewFeed_label {
  margin: 0;
  height: 35px;
  width: 20%;
  background-color: #069482;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  text-align: center;
  line-height: 33px;
  font-size: 16px;
  color: #fff;
 position: absolute;
} */

#addNewFeed_input.form-control {
  width: 100%;
  height: 100%;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: none !important;
  height: 35px;
  border-color: #ADB5BD;
  font-size: 13px;
 border-radius: 8px;
 position: relative;
}