.lb_button_grp{
    margin-top: 18px;
}
.ld_btn, .sli_btn{
    border-radius: 8px;
    font-weight: 500;
    min-height: 49px;
    padding-left: 20px;
    padding-right: 20px;
}
.ld_btn:hover, .sli_btn:hover{
    background: #004c3f !important;
    color: #fff !important;
}
.filter-label {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 12px;
    color: #495057;
    font-weight: 500;
    letter-spacing: 0.00938em;
  }
.eld_header_wrap{
    padding: 0 0 30px 0;
}
.leave_issue_wrap{
    padding: 30px;
}
.leave_issue_wrap h6{
    margin: 0 0 15px 0 !important;
    font-weight: 600;
    font-family: 'Poppins', sans-serif !important;
    text-align: left !important;
    color: #212529 !important;
    font-size: 20px;
    letter-spacing: 0.00938em;
}
.leave_issue_wrap .leave-status-card{
    margin: 0 !important;
    color: #212529 !important;
}
button.show_btn{
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
}
button.show_btn:hover{
    background: #004c3f !important;
    color: #fff;
}

.back-leavebook
{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.back-leavebook p
{
    font-size: 20px;
}