/**
* Live Activity Styles
*/
.la-filter {
flex-wrap: wrap;
display: grid;
grid-template-columns: repeat(5, 1fr);
grid-gap: 10px;
gap: 10px;
grid-auto-rows: minmax(100px, auto);
margin-left: 30px;
margin-bottom: 30px;
}

.la-filter .input-group {
 
  width: auto;
  border-radius:8px;
 position: relative;
  padding: 10px 18px;
  background: #F0F6FA;
}

.la-filter .input-group:last-child {
  margin-right: 0;
}

/* .la-filter .input-group:hover, .la-filter .input-group.active {
  box-shadow: 0 0 5px 3px rgba(0,0,0,0.06);
} */

.la-filter .input-group label {
  margin-right: 6px;
  margin-bottom: 0;
  font-size: 16px;
  color: #212529;
  font-weight: 500;
  padding: 0;
}
.la-filter .form-check-input:checked + label{
  color: #219186;
}
/* .la-filter .input-group label:after{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #000;
} */
.la-rm-time-body .r-hours{
	    width: 100%;
    display: inline-block;
    text-align: right;
}

.la-rm-time-body .r-pending{
	    width: 10%;
    display: inline-block;
    text-align: left;
}