.client_list_wrapper{
    display: flex;
    flex-wrap: wrap;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    grid-gap: 20px;
    gap: 20px;

}
.client_list_box{
    transition: all 0.5s ease-in-out;
    border-radius: 12px;
    border: 0px solid #bfc4ca !important;
    position: relative;
    display: flex;
    flex-direction: column;
    background: #F2F5F7;
    padding: 20px;
    flex: 281px;
    width: 100%;
    min-height: 335px;
    position: relative;
}
.client_list_box .profile_pic{
    width: 100px;
    height: 100px;
    border-radius: 100px;
    background: #00806C;
    margin-bottom: 20px;
    color: #fff;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
}
.client_list_box .client_name{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
}
.client_list_box .client_name h3{
    color: #1A1F21;
    font-size: 17px;
    margin: 0;
    padding: 0 0 8px 0;
    font-weight: 600;
}
.client_list_box .client_name h6{
    color: #95A0A7;
    font-size: 14px;
    display: flex;
    align-items: center;
    font-weight: 400;
}
.client_list_box .client_name h6 .material-icons.md-dark{
    font-size: 18px;
    color: #95A0A7;
}
.client_list_box .packages{
    display: flex;
    justify-content: center;
    gap:20px;
    padding: 15px 0 15px 0;
}
.client_list_box .packages div{
    display: flex;
    align-items:center ;
    background: #fff;
    padding: 5px 8px;
    border-radius: 8px;
}
.client_list_box .packages .material-icons{
    font-size: 22px;
    margin-right: 8px;
}
.viewpro_btn{
  background:#C9E2DE;
  color:#00806C;
  border: none;
  padding-top: 11px;
  padding-bottom: 11px;
  font-size: 14px;
  font-weight: 600;    
  border-radius: 8px;
  margin-top: 15px;
  display: block;
  transition: all .2s ease-in-out;
  width: 100%;
}
.viewpro_btn:hover{
    background: #00806C;
    color: #E5FFF7;
    border: none;
}
.viewpro_btn:focus{
    background: #00806C !important;
    color: #E5FFF7 !important;
    border: none;
}
.edit_btn_client{
    position: absolute;
    right: 20px;
    top:20px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border: none;
    transition: all .2s ease-in-out;
}
.edit_btn_client:hover{
    background: #00806C;
}
.edit_btn_client:hover .material-icons{
    color: #fff;
}
.edit_btn_client:focus{
    background: #00806C !important;
}
.edit_btn_client:focus .material-icons{
    color: #fff;
}
.edit_btn_client .material-icons{
    color: #00806C;
    font-size: 18px;

}
.client_filter{
    width: 100%;
    justify-content: space-between;
}
.client_filter .search_client{
    max-width: 400px !important;
    width: 100%;
    position: relative;
}
.search_btn{
    background: transparent !important;
    border: 0 !important;
    position: absolute;
    right: 0;
    height: 47px;
}
.search_btn .material-icons{
    color: #212529;
}
.search_btn:hover{
    background: transparent !important;
    border: 0 !important; 
}
button.btn-secondary.search_btn:focus{
    background: transparent !important;
    border: 0 !important; 
}
.search_btn:hover .material-icons{
    color: #102030;
}
.modal_headercli{
    padding: 20px;
    font-size: 18px;
    font-weight: 600;
    color: #1A1F21;
}
.from_editclient{
    padding: 0 20px 20px 20px;
}
.from_editclient .input-group{
    flex-direction: column-reverse;
    margin-bottom: 20px;
}
.from_editclient label {
    color: #495057;
    font-size: 12px;
    font-weight: 500;
    margin: 0;
    padding: 0 0 4px 0;
}
.from_editclient .input_box{
    display: block;
    border-radius: 8px !important;
    border: 1px solid #adb5bd !important;
    font-size: 15px;
    color: #212529;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #fff !important;
    min-height: 48px;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
}
.model_footercli{
    display: flex;
    gap: 5px;
}
.model_footercli .viewpro_btn{
    max-width: 150px;
    width: 100%;
    min-height: 50px;
}
.model_footercli .close_btn{
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: red;
    color: #fff;
    margin-top: 15px;
    border-radius: 8px;
}
.model_footercli .close_btn .material-icons{
    color: #fff;
}
.linked_boards_wraper {
    background: #fff;
    border-radius: 16px;
    padding: 30px;
}
.linked_boards_wraper h2{
    color: #212529;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 20px;
    text-align: left !important;
    margin: 0 !important;
}

.detailpage_view .client_list_box{
    background: #fff;
    min-height: auto;
}
.detailpage_view .packages div{
    background: #F2F5F7;
}
.detailpage_view  .client_list_wrapper{
    padding-left: 0;
    padding-right: 0;
}
.choose_board .rbt-input-main{
    border-radius: 8px !important;
    border: 1px solid #adb5bd !important;
    font-size: 15px;
    color: #212529;
    padding-left: 15px;
    padding-right: 15px;
    background-color: #fff !important;
    min-height: 48px;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
}
.linked_boards_wraper .choose_board{
    display: flex;
    justify-content: space-between;
}
 .health_btn{
    margin-left: 15px;
    background: #00806C;
    color: #fff;
    border-radius: 8px;
    border: none;
    padding-top: 11px;
    padding-bottom: 11px;
    font-size: 14px;
    font-weight: 600;    
   transition: all .2s ease-in-out;
}
.health_btn:hover{
    background: #E5FFF7;
    color: #00806C;
}
.linked_boards_wraper .choose_board .rbt{
    width: 81%;
}
.board_innerdetail{
    background: #fbfbfb;
    padding: 20px 0;
    margin-top: 20px;
    border-radius: 12px;
}
.board_innerdetail .board_detail_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0 20px;
}
.board_innerdetail .board_detail_header h3{
    font-size: 16px;
    color: #212529;
    padding: 0;
    margin: 0;
    font-weight: 600;
    letter-spacing: 0.15px;
}
.button_grp_cli {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    margin:15px 0;
    padding: 0 20px;
    gap: 10px;
}
.button_grp_cli .gap10{
    gap: 10px ;
}
.button_grp_cli .month_btton{
    background: #E5FFF7 !important;
    color: #00806C !important;
    border: 0 !important;
    border-radius: 8px;
}
.button_grp_cli .month_btton:hover{
    background: #00806C !important;
    color: #fff !important;
}
.button_grp_cli .month_btton.active{
    background: #00806C !important;
    color: #fff !important;
}
.clientwork_table tbody td:last-child{
    text-align: right;
}
.add_notinput{
    border-radius: 8px !important;
    border: 1px solid #adb5bd !important;
    font-size: 15px;
    color: #212529;
    padding:15px;
    background-color: #fff !important;
    min-height: 48px;
    font-size: 16px;
    font-weight: 400;
    width: 100%;
    resize: none;
    margin-bottom: 20px;
}
.clientnote_view{
    padding: 20px;
    background: #eff6fa;
    border-radius: 12px;
    margin-top: 20px;
}
.clientnote_view p{
    color: #6c757d;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 0;
    padding-right: 21px;
    min-height: 70px;
    flex-grow: 1;
    letter-spacing: 0.08px;
}
.client_list_box .board_status{
    width: 100%;
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:10px;
}
.client_helth_board .health_btn{
    margin-left: 0;
    width: 100%;
    height: 60px;
    font-size: 16px;
}
.deatil-leftbox{
    flex-direction: column;
}
.client_data_card{
    padding: 20px;
    background: #fff;
    border-radius: 12px;
    margin-bottom: 20px;
}
.client_data_card .detail h4{
    font-size: 17px;
    color: #1A1F21;
    font-weight: 500;
    margin: 0;
    padding: 0;
}
.client_data_card .detail  span{
    color: #95A0A7;
    font-size: 12px;
    display: flex;
    align-items: center;
    font-weight: 400;
}
.client_data_card  .staf_name{
    display: flex;
    justify-content: space-between;
}
.client_data_card .detail {
    display: flex;
}
.client_data_card .staf_name span.pro_pic{
    min-width: 38px;
    height: 38px;
    display: flex;
    margin-right: 10px;
}
.client_data_card .staf_name .stars {
    display: flex;
    align-items: center;
}
.client_data_card .staf_name .stars .material-icons{
    font-size: 18px;
    color: #feca57;
}
.client_data_card p{
    margin: 0;
    color: #6c757d;
    font-size: 14px;
    line-height: 22px;
    padding-top: 15px;
}
.board_list{
     flex-wrap: wrap;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 15px;
    gap: 15px;
    grid-auto-rows: minmax(100px, auto);
}
.board_list .boardcard{
    flex: 281px 1;
    width: 100%;
    background: #fbfbfb;
}
.board_list .board-actions{
    display: flex;
    gap: 10px;
    width: 100%;
}
.board_list .packages{
    display: flex;
    justify-content: center;
    gap:20px;
    background: #f2f5f7;
    padding: 10px 0 10px 0;
    margin-top: 15px;
    border-radius: 10px;
}
.board_list .packages div{
    display: flex;
    align-items:center ;
    background: #fff;
    padding: 5px 8px;
    border-radius: 8px;
}
.board_list .packages div span{
    color: #212529;
}
.board_list .packages .material-icons{
    font-size: 22px;
    margin-right: 8px;
}
.board_work_detail{
    margin: 0;
    padding: 0;
    margin: 0;
    padding: 0;
    position: fixed;
    height: 100%;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 800px;
}
.board_work_detail .modal-dialog{
    max-width: 500px;
    margin: 0;
    position: fixed;
    right: 0;
    width: 100% !important;
    height: 100%;
    top: 0;
}
.board_work_detail .modal-content{
    height: 100%;
    border: 0;
    border-radius: 0;
}
.board_list  .board-actions span:hover{
    font-weight: 400;
    border-color: #00806C;
    color: #00806C;
}
.hide_clienthelth{
    text-align: right;
    padding: 10px 0 10px 0;
    color: #00806C;
    font-weight: 600;
    width: 100%;
    display: block;
    cursor: pointer;
}
.total_time{
    flex-direction: column;
    font-size: 14px;
    font-weight: 600;
    color: #212529;
}
.total_time span{
    color: #95A0A7;
    font-size: 12px;
    display: flex;
    font-weight: 400;
}
.linked_boards_wraper .section_title{
    display: flex;
     justify-content: space-between;
     margin: 0;
     padding-top: 0 !important;
}
.linked_boards_wraper .section_title h2{
    margin: 0;
}
.linked_boards_wraper .section_title .add_btn{
    font-size: 15px;
    max-width: 140px;
    height: 45px;
    border: 0;
}
