/* Icons Styles */

.icon {
  padding: 10px;
}

.menu-icon a{
  text-decoration: none;
  color:#fff;
}

.icon.bell-icon {
  position: relative;
}

.icon.bell-icon:after {
  /*content: attr(data-notification); */
	width: 16px;
	height: 16px;
	display: block;
	background: #069482;
	text-align: center;
	line-height: 13px;
	border-radius: 25px;
	color: #fff;
	font-size: 10px;
	font-family: "ProximaNova-Bold";
	position: absolute;
	top: 12px;
	right: 5px;
	border: 1px solid #fff;
}