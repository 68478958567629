/*
* LoanMakePay css
*/

.repay-apply-reason {
  margin-top: 25px;
}
.repay-amount{
  margin-bottom: 24px;
}
.repay-repay-date{
  margin-bottom: 24px;
}
.repay-repay-date .react-datetime-picker, #repay-apply-amount, .choose_user select.form-control {
  display: block;
  width: 100%;
  margin: 0 !important;
  min-height: 48px !important;
  border-radius: 8px !important;
  border: 1px solid #ADB5BD;
  line-height: 35px;
  padding-left: 14px;
  padding-right: 14px;
}
.choose_user select.form-control {
  background-position:96% center !important;
}
