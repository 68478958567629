.preMesssageWrapper{
  margin: 30px;
  padding: 25px;
  background: #fff;
  border: 1px solid #e6effa;
  border-radius: 12px;
  box-shadow: 0px 12px 24px 0px rgba(108, 161, 206, 0.08);
}
.preMesssageWrapper .ql-toolbar.ql-snow{
  border: 0 !important;
}
.preMesssageWrapper .quill .ql-editor{
  background: #fff;
  min-height: 230px;
  border: 1px solid #adb5bd;
  border-radius: 8px;
  font-size: 14px;
}
.preMesssageWrapper .ql-container.ql-snow{
  border: 0;
}
.preMesssageWrapper .ql-editor.ql-blank::before{
  padding: 0;
  font-style: normal;
}
.addBox{
  margin: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
.addPredeM{
  display: flex;
  gap: 10px;
  width: 100%;
}
.addPredeM input[type="text"]{
  width: 100%;
  background: #e8e8e8;
  padding: 10px;
}
.addPredeM button{
  background: #000;
  min-width: 100px;
  border: 0;
  color: #fff;
  font-weight: 500;
  border-radius: 4px;
}
.dataView{
  display: flex;
  width: 100%;
}
.dataView > div{
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.removebtn{
  width: 100px;
  height: 50px;
  min-width: 100px;
  border: 0;
  background: red;
  color: #fff;
  border-radius: 8px;
}