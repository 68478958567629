/*
* Load Details Head styles
*/



#loan-details-head {
 
  background: #fff;
}
#loan-details-head .row{
  width: 100%;
}



#loan-details-head p {
  font-size: 20px;
  color: #636363;
  margin: 0;
}

#loan-details-head p.title {
  font-weight: 400;
  color: #bdbdbd
}

#loan-details-head button {
  width: 140px;
  height: 35px;
  padding: 0;
}

#loan-details-head button:first-of-type {
  margin-right: 37px;
}
#loan-details-head #LoadingWrapper{width:55%;}