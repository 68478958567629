.dark-mode .w3-button.w3-white {
    background-color: #2196f3 !important;
    border-color: transparent !important;
    border-radius: 2px;
  }
  
  .app {
    max-width: 500px;
    margin: 16px auto;
    position: relative;
  }
  
  .dark-mode .app {
    background-color: #000;
  }
  
  .dark-mode-toggle {
    /* position: absolute; */
    display: flex;
    align-items: center;
    width: 90px;
    margin-left: 10px;
  }
  .dark-mode-toggle > button {
    font-size: 1.2em;
    background: none;
    border: none;
    color: #ffe600;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  .dark-mode .dark-mode-toggle > button {
    color: #999;
  }
  .dark-mode-toggle > button:last-child {
    color: #666;
  }
  .dark-mode .dark-mode-toggle > button:last-child {
    color: #ffe600;
  }
  /* bab3fd */
  .dark-mode-toggle > button:focus {
    outline: none;
  }
  
  .toggle-control {
    position: relative;
    padding: 0 4px;
    width: 50px;
  }
  .dark-mode-toggle input[type='checkbox'].dmcheck {
    width: 40px;
    height: 10px;
    background: #555;
    position: relative;
    border-radius: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    vertical-align: 2px;
    outline: none;
    margin:0;
  }
  .dark-mode-toggle input[type='checkbox'].dmcheck:focus-visible {
    outline: solid 2px white;
  }
  .dark-mode-toggle input[type='checkbox'].dmcheck + label {
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    transition: all 0.3s ease;
    cursor: pointer;
    position: absolute;
    top: -4px;
    left: 3px;
    background: #fff;
    background: #069482;
    opacity: 1;
    margin: 0 !important;
  }
  
  .dark-mode-toggle input[type='checkbox'].dmcheck:checked + label {
    left: 27px;
  }
  .dark-mode-toggle input[type='checkbox'] {
    -webkit-appearance: none;
    appearance: none !important;
  }
  .dark-mode-toggle input[type="checkbox"]:checked:after{
      display: none;
  }
  .dark-mode-toggle input[type="checkbox"]:after{
    display: none;
  }

  #body.dark-mode{
      background: #000 !important;
  }
.dark-mode .card{
    background: #1a1a1a !important;
}
.dark-mode .btn.disabled, .dark-mode .btn:disabled {
    background: #353232 !important;
    opacity: 1 !important;
    border-color: #353232 !important;
    color: #bfdad7 !important;
}
.dark-mode #new-side-menu {
    box-shadow: 12.287px 8.604px 25px 0px rgb(0 0 0 / 10%);
    background: #1a1a1a;
}
.dark-mode .side-menu-wrapper .menu-item{
    border-bottom: 1px solid #313131; 
}
.dark-mode .doto_header{
    background: #1a1a1a;
    border-bottom: 1px solid #313131;
}
.dark-mode  .nsmi_menu-icon div{
    background: #e2e2e2;
}
.dark-mode #new-side-menu .menu_items .active .nsmi_menu_name {
     color: #29b39e;
}
.dark-mode .nsmi_menu_name{
    color: #e2e2e2;
}
.dark-mode .topHeaderbar{
    background: #242424 ;  
}
.dark-mode .user-cont .icon{
    background: #353232;
}
.dark-mode header .welcome-time-box span {
    font-size: 16px;
    color: #e2e2e2;
    font-family: "ProximaNova-Bold";
}
.dark-mode  .login-out-logs .title{
    color: #e2e2e2;
}
.dark-mode .time-exhausted {
    margin-left: 10px;
    color: #bfdad7;
}
.dark-mode .allow-break-input{
    color: #bfdad7;
}
.dark-mode .log-container {
   background: #060606;
    color: #bfdad7;
    border-left: 4px solid #1c3c38;
}
.dark-mode .progress{
    background-color: #535556;  
}
.dark-mode .break-lunch-in-out .break-box{
    border-bottom: 1px solid #34373e;
}
.dark-mode .log-total p{
    background: #060606;
    color: #bfdad7;
    padding: 5px;
}
.dark-mode #new-side-menu .menu_items a {
    color: #b2b2b2!important;
    text-decoration: none;
}
.dark-mode .shift-btns button {
    font-size: 16px;
    color: #e2e2e2;
    background: #1c3c38;
    border: 1px solid #0e5f54;
}
.dark-mode .topHeaderbar .task-wrap svg path{
    fill-rule: evenodd;
    fill: rgb(141 141 141) !important;   
}
.dark-mode .task-wrap span {
    color: #b2b2b2;
}
.dark-mode .task-wrap {
    border-right: 1px solid #3e3e3e;
}
.dark-mode .page-title{
    color: #e2e2e2;  
}
.dark-mode .task-wrapper .head h4 {
    color: #b2b2b2;  
}
.dark-mode .add-new-task {
    background: #060606;
    box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 20%);
   
}
.dark-mode .add-new-task input{
    background: #060606 !important;
    color: #bfdad7;
}
.dark-mode .btn-green{
    background-color: #1c3c38;
    border: 1px solid #0e5f54;  
}
.dark-mode .single-task{
    background: #1a1a1a;
}
.dark-mode .single-task-container{
    border-bottom: 1px solid #353535;
}
.dark-mode .single-task-container .sl-no{
    color: #bfdad7;
}
.dark-mode  .task-btn-title {
    color: #bfdad7;
}
.dark-mode .reassign-my-task {
    height: 28px;
    width: 28px;
    margin-right: 18px;
    background: #505050;
    border-radius: 7px;
    padding: 2px;
}
.dark-mode .task-timing{
    border-top: 1px solid #1a1a1a;
}
.dark-mode .single-task.active {
    box-shadow: 0px 10px 15px 0px rgb(0 0 0 / 47%)
}
.dark-mode .single-task.active .single-task-container.d-flex {
    background: #1c3c38;
}
.dark-mode .logamend{
    background: #252424;
}
.dark-mode .member-not-found.text-center, .dark-mode .page-sub-title, .dark-mode span.assigni-name {
    color: #b2b2b2;
}
.dark-mode .search-filter-assignees input{
    border: 1px solid #010101;
    background: #000; 
    color: #bfdad7;
}
.dark-mode .close-icon svg path{
    fill-rule: evenodd;
    fill: rgb(255 255 255) !important;
}
.dark-mode .modal-content{
    background-color: #1a1a1a;
}
.dark-mode .modal-header{
    border-bottom: 1px solid #313131;  
}
.dark-mode .modal-title, .dark-mode .modal-body p, .dark-mode .section_title{
    color: #b2b2b2;

}
.dark-mode .section_title{
    color: #e2e2e2;

}
.dark-mode .modal-body h6{
    color: #b1b1b1;
}
.modal-header .close{
    color: #fff;
}
.dark-mode .card.without-tabs.no-questions{
    color: #b1b1b1;
}
.dark-mode #addNewFeed_input.form-control{
    border-color: #1a1a1a;
    font-size: 13px;
    background: #1a1a1a; 
}
.dark-mode .addNewFeed_label{
    background: #1c3c38;
    border: 1px solid #0e5f54;
    color: #b2b2b2;
    font-size: 14px;
}
.dark-mode .feed_comment_input{
    background-color: #2f2f2f;
    background-clip: padding-box;
    border: 1px solid #1a1a1a;
}
.dark-mode  .feed_comment_input:focus{
    background-color: #2f2f2f;
    background-clip: padding-box;
    border: 1px solid #1a1a1a; 
}
.dark-mode  .feed_comment_action p{
    color: #bfdad7;
}
.dark-mode  .feed_comment_action_icon {
    background-color: #bfdad7;
    /* padding: 2px; */
    background-size: 12px !important;
    background-repeat: no-repeat !important;
    background-position: center center;
    border-radius: 4px;
    padding: 0;
    background-size: 10px !important;
    background-position: 3px 3px;
}
.dark-mode .feed-item{
    box-shadow:none !important;
}
.dark-mode .bds-person .bds-person-date {
    color: #bfdad7;
}
.dark-mode #alert-message{
    background: #1a1a1a;
    color: #b2b2b2;
}
.dark-mode .filter-row .date-col p, .dark-mode .filter-row .hours p{
    color: #e2e2e2;
}
.dark-mode .form-control{
    background-color: #1a1a1a;
    background-clip: padding-box;
    border: 1px solid #191717;
}
.dark-mode .dropdown-menu{
    background-color: #1a1a1a;
    color: #b2b2b2 !important;
}
.dark-mode .dropdown-item{
    color: #b2b2b2 !important;
}
.dark-mode .daily-log-adv-filter .hours {
    border: 1px solid #2d2d2d;
    margin-left: 10px!important;
}
.dark-mode .react-datetime-picker {
    border: 1px solid #2b2b2b;
}
.dark-mode  .react-calendar{
    background: #1a1a1a !important;
    border: 1px solid #2b2b2b !important;
    box-shadow: none !important;
}
.dark-mode .react-calendar__navigation__label{
    color: #b2b2b2;
}
.dark-mode .react-calendar .react-calendar__month-view__weekdays__weekday{
    color: #b2b2b2;
}
.dark-mode .react-calendar__month-view__days__day--weekend{
    color: #d10000 !important;
}
.dark-mode .react-calendar button{
    color: #b2b2b2 ;
}
.dark-mode #filter .search-btn button{
    background: #1c3c38;
    border: 1px solid #0e5f54;
}
.dark-mode .react-calendar__tile:enabled:hover abbr, 
.dark-mode .react-calendar__tile:enabled:focus abbr, 
.dark-mode .react-calendar .react-calendar__tile--active abbr{
    background: #1c3c38;
    border: 1px solid #0e5f54; 
}
.dark-mode .list-head-cont .rowone{
    color: #b2b2b2;
}
.dark-mode #date-list-contianer{
    background: #000;
}
.dark-mode .log-timer #date-list-contianer .card{
    color: #bfdad7;
}
.dark-mode #date-list-contianer .card:hover{
    box-shadow: none !important;
}
.dark-mode .ticket_title h2, .dark-mode .head_text{
    color: #b2b2b2 ;
}
.dark-mode .filter_drop_wrap .form-control {
    background-color: #313131;
    background-clip: padding-box;
    border: 1px solid #313131;
    color: #bfdad7;
}
.dark-mode .filter_drop_wrap .form-control::placeholder{
    color: rgb(185, 185, 185);
}
.dark-mode #ticket_details select{
    color: #bfdad7;
}
.dark-mode .back_btns button{
 background-color: #1c3c38 !important;
 border: 1px solid #0e5f54 !important;  
}
.dark-mode .filter_drop_wrap .search_but_filt{
    background-color: #1c3c38; 
}
.dark-mode .ticket_lits_head{
    border: 1px solid #272727;
    background: #000;
}
.dark-mode  .list_row{
    border-bottom: 1px solid #252525;
    background: #0e0e0e;
}
.dark-mode .tlist_text {
     color: #bfdad7;
}
.dark-mode .ticket-sidebar-wrapper .form-control, .dark-mode .react-tags{
    background-color: #313131;
    border: 1px solid #313131;
    color: #bfdad7;
}
.dark-mode .react-tags__search-input{
    background: #313131;
}
.dark-mode .post_date .ticket-date-title{
    color: #e2e2e2;
}
.dark-mode .text-data-view{
    color: #5d8881;
}
.dark-mode .ulchekbox {
    border: 1px solid #2f2f2f;
    background: #000;
}
.dark-mode .ulchekbox .ch-item {
    border-bottom: 1px solid #2b2727;
    padding: 3px 0;
}
.dark-mode .rbstyle label:after{
    background-color: #313131;
    border: 1px solid #506175;
}
.dark-mode .card h6{
    color: #b2b2b2;
}
.dark-mode .reminder-options {
    color: #b2b2b2;
}
.dark-mode .react-datetime-picker {
    border: 1px solid #2b2b2b;
    background-color: #313131;
}
.dark-mode label {
    display: inline-block;
    color: #bfdad7;
    margin-right: 5px;
}
.dark-mode .pending-reminder-info {
    border-top: 1px solid #2b2727;
    color: #b2b2b2;
}
.dark-mode .issue_chat_detail h2{
    color: #e2e2e2;
}
.dark-mode .leave-days .date-type{
    color: #fff;
}
.dark-mode #leave-balence h4.title.text-center{
    color: #e2e2e2 !important;
}
.dark-mode .cmd_viewbox{
    border: 1px solid #2b2b2b;
    border-radius: 2px;
    background: #000; 
}
.dark-mode .comment-text p, .dark-mode .ticket-summary-description p {
    color: #b2b2b2;
}
.dark-mode .new-ticket-details .quill {
    border: 1px solid #2b2b2b;
    background: #000;
}
.dark-mode .new-ticket-details .ql-editor{
    background: #313131;
    color: #bfdad7 !important;
}

.dark-mode .btn_uplod {
    color: #A0A0A0;
    border: 1px solid #1a1a1a;
    background-color: #1a1a1a;
}
.dark-mode #feed-sidebar .card{
    box-shadow: none;
}
.dark-mode .read-field, .read-field:focus, .read-field:active{
    background: #313131;
    cursor: not-allowed;
    border: 1px solid #000;
    color: #b2b2b2;
}
.dark-mode header{
    border-bottom: none  !important;
}
.dark-mode .search_btn{
    background-color: #1c3c38 !important;
    border: 1px solid #0e5f54 !important;  
}
.dark-mode .filter-icon{
    background-color: #1c3c38 !important;
    border: 1px solid #0e5f54 !important;  
}
.dark-mode .team-view-list-header, 
.dark-mode .team-view-list .card div,
 .dark-mode .head-table,
.dark-mode .total-emp-leave,
 .dark-mode .body-table{
    color: #b2b2b2;
}
.dark-mode .no-task-running{
    background: #1a1a1a;
    color: #b2b2b2;
}
.dark-mode .ApprovalModules{
    background: #000;
    color: #b2b2b2;
}
.dark-mode  .caro-box{
  background: #1a1a1a;
  border-color: #282f2e;
}
.dark-mode  .la-filter .input-group{
    background: #1a1a1a;
    color: #b2b2b2;
}
.dark-mode .nsmi_submenu li:hover{
    color: #b2b2b2;
}
.dark-mode .teamholidays .ms-container, .dark-mode .ms-container .ms-selectable, .dark-mode .ms-container .ms-selection,
.dark-mode .ms-selectionpanel, .dark-mode .ms-selectionpanel2{
    background-color: #1a1a1a;
}
.dark-mode input.search-input{
    background-color: #383838;
    border: 1px solid #383838;
    color: #bfdad7;
}
.dark-mode .ms-container .ms-selectable li.ms-elem-selectable, .dark-mode .ms-container .ms-selection li.ms-elem-selection{
    color: #b2b2b2;
}
.dark-mode .ms-container .ms-list{
    border: 1px solid #3a3a3a; 
}
.dark-mode .ms-container .ms-selectable li.ms-elem-selectable, .dark-mode .ms-container .ms-selection li.ms-elem-selection{
    border-bottom: 1px #3a3a3a solid; 
}
.dark-mode .ms-selectionpanel button{
    background-color: #1c3c38 !important;
    border: 1px solid #0e5f54 !important;  
    width: 40px;
    height: 40px;
}
.dark-mode #filter .people .filter-label, .dark-mode .list-head-cont{
    color: #e2e2e2;
}
.dark-mode .list-body-cont{
    color: #b2b2b2;
    border: 1px solid #403b3b;
    background: #1a1a1a;
}
.dark-mode  #loan-details-head{
    border: 1px solid rgb(51 51 51) !important;
}
.dark-mode #loan-details-head > div{
    border-right: 1px solid #292929;
}
.dark-mode #loan-summery-head{
    background: hsl(0deg 0% 18%);
}
.dark-mode .loan-module .form-control {
    background-color: #2f2f2f;
    background-clip: padding-box;
    border: 1px solid #403e3e;
    color: #fff;
}
.dark-mode .loan-module, .dark-mode #loan-summery-wrapper{
    border: 1px solid rgb(51 51 51) !important; 
}
.dark-mode #leave_balence{
    background: #1a1a1a !important;
}
.dark-mode #leave_header .leave-menu-anchor{
    color: #e2e2e2;
}
.dark-mode #leave_header p{
    border-bottom: 1px solid #3a3a3a;
    background: #1a1a1a;
}
.dark-mode .menuActive, .dark-mode #leave_header p:hover, .dark-mode #leave_header p:hover a{
    background-color: #1c3c38 !important;
    color: #e2e2e2 !important;
}
.dark-mode .leave-balence-card{
    border-color: #333 !important;
    box-shadow: none;
}
.dark-mode #leave_balence .leave-note{
    color: #b2b2b2;
}
.dark-mode .upcoming_company_holiday {
    border: 1px solid #333 !important;
    color: #b2b2b2;
    box-shadow: none;
}
.dark-mode #downArrow{
    background-color: #1c3c38 !important;
}
.dark-mode .leave_body{
    background-color: #252525;
}
.dark-mode #leave_apply .react-datetime-picker{
    background-color: #1a1a1a;
    background-clip: padding-box;
    border: 1px solid #191717;   
}
.dark-mode .leave_body input[type="text"], .dark-mode .leave_body input[type="date"], .dark-mode .leave_body input[type="number"], 
.dark-mode .leave_body select, .dark-mode #attachment .file-wrapper, .dark-mode #cc-people .people, .dark-mode .leave-details-wrapper{
    background-color: #1a1a1a;
    background-clip: padding-box;
    border: 1px solid #191717; color: #fff;
}
.dark-mode #leave-balence{
    background: #1a1a1a 
}
.dark-mode .leave-status-card{
    border-color: #333 !important;
    box-shadow: none ;
}
.dark-mode .leave-days{
    background: #505050;
}
.dark-mode #leave_calender {
     background: #1a1a1a;
    border: 1px solid #333;
    box-shadow: none;
}
.dark-mode .leave_calender_details .category {
    border-bottom: 1px solid #333;
}
.dark-mode  #leave-react-calender .react-calendar .react-calendar__navigation__label{
    font-size: 20px !important;
}
.dark-mode .leave_calender_details p {
    color: #bfdad7;
}
.dark-mode .la-data-body{
    background: #1a1a1a;
    color: #b2b2b2;
}
.dark-mode .la-name-body p, .dark-mode .la-time-body, .dark-mode .la-in-list, .dark-mode .la-rm-time-body p {
    border-bottom: 1px solid #333;
}
.dark-mode #la-list-header .la-hd{
    background: #1c3c38; 
}
.dark-mode .la-time h4, .dark-mode .la-task h4{
    background: #1f4c46;
}
.dark-mode .designer-livedata .la-data-body.la-name-body, .dark-mode .designer-livedata .la-data-body.la-task-body, .dark-mode .designer-livedata .la-data-body.la-rm-time-body {
    border-bottom: 1px solid #333;
}
.dark-mode .project-log-filter .datepicker-col p, .dark-mode .project-log-hour p{
    color: #e2e2e2;
}
.dark-mode #hourfilter, .dark-mode .DateRangePickerInput {
     background-color: #1a1a1a;
}
.dark-mode .DateInput{
    background: #1a1a1a;
}
.dark-mode .DateRangePickerInput__withBorder {
    border-radius: 2px;
    border: 1px solid #1a1a1a;
}
.dark-mode .DateInput_input{
    background: #1a1a1a;
}
.dark-mode .list-filter .filter-wrap .min-2, .dark-mode .device-mobile{
    color: #b2b2b2;
}
.dark-mode .CalendarMonth {
    background: #1a1a1a;
}
.dark-mode .CalendarDay__default {
    border: 1px solid #333;
    color: #b2b2b2;
    background: #1a1a1a;
}
.dark-mode .CalendarMonthGrid{
    background: #1a1a1a;
}
.dark-mode .DayPicker__horizontal {
    background: #1a1a1a;
}
.dark-mode .DateInput_fangStroke {
    stroke: #1a1a1a;
    fill: #1a1a1a;
}
.dark-mode .DayPickerNavigation_button__default{
    background-color: #1c3c38 !important;
    border: 1px solid #0e5f54 !important;
}
.dark-mode .availability-records .log-head-items {
    padding: 10px 10px;
    color: #b2b2b2;
}
.dark-mode .availability-records .log-body-items {
   color: #bfdad7;
}
.dark-mode .health_rpot_header{
    background: #1a1a1a;
}
.dark-mode .report-tab-head{
    color: #b2b2b2;
}
.dark-mode .client-health-report, .dark-mode .trello-boards .list-card.d-block.card{
    color: #e2e2e2;
}
.dark-mode #ki_countdown{
    background: #1a1a1a;
}
.dark-mode #ki_countdown p{
    color: #b2b2b2;
}
.dark-mode #ki_countdown h3{
    color: #fff;
}
.dark-mode .closed-items-heading{
    background: #1a1a1a;
    color: #e2e2e2;
}
.dark-mode .closed-items {
    background: #1a1a1a;
    color: #bfdad7;
}
.dark-mode .closed-items a{
    color: #e2e2e2;
}
.dark-mode #addNewFeedFormWrapper{
    background: #1a1a1a;
}
.dark-mode .feedImg p{
    color: #bfdad7;
}
.dark-mode #addNewFeedFormWrapper .form-control{
    background: #2d2d2d;
    color: #bfdad7;
}
.dark-mode .rdw-editor-toolbar{
    background: #2b2b2b;
    border: 1px solid #333 !important;
}
.dark-mode .rdw-editor-main {
    border: 1px solid #333;
    background: #000;
    color: #bfdad7;
  
}
.dark-mode .rdw-option-wrapper{
    background: #bfdad7;
    border: 1px solid #333;
    width: 25px;
    opacity: 1;
}
.dark-mode .rdw-dropdown-wrapper{
    background: #5f5f5f;
    border: 1px solid #333;
}
.dark-mode .rdw-dropdown-selectedtext{
    color: #bfdad7;
}

.dark-mode .feed-sidebar-up-levs-cont p{
    color: #bfdad7;
}
.dark-mode .feed-item-wish .content {
     background-color: #000 !important;
    opacity: .9;
}
.dark-mode .feed-item-wish .content h3{
    color: #2b2b2b;
}
.dark-mode .feed-item-wish .content p{
    color: #2b2b2b;
}

.dark-mode .new-people-content{
    background: #2b2b2b;
}
.dark-mode .profile-fields .form-control {
    background-color: #2b2b2b !important;
    background-clip: padding-box;
    border: 1px solid #333 !important;
    color: #fff;
}
.dark-mode #user-management-section .react-tel-input .flag-dropdown{
    background-color: #2b2b2b;
    border: 1px solid #333;
}
.dark-mode  #user-management-section .react-tel-input .form-control{
    border: 1px solid #333 !important;
}
.dark-mode .fileuplod label{
    background: #2b2b2b;
    border: 1px solid #333;
}
.dark-mode .feed_comment_wrapper .feed_comment_input {
    background-color: #2f2f2f;
    border: 1px solid #333;
    color: #bfdad7;
}
.dark-mode .timer-box p, .dark-mode .timer-box div {
    color: #b2b2b2;
}

.dark-mode .loan-module .title{
    color: #b2b2b2;
}
.dark-mode .filter_drop_wrap_sidebar{
    background:#1a1a1a;
}
.dark-mode  .filter_drop_wrap_sidebar .seperator-text{
    color: #b2b2b2;
}
.dark-mode .filter_drop_wrap_sidebar .form-control{
    background-color: #2b2b2b;
    border: 1px solid #333;
}
.dark-mode .filter_drop_wrap_sidebar .assigned-only{
    background-color: #2b2b2b;
    border: 1px solid #333;
}
.dark-mode .filter_drop_wrap_sidebar .react-tags, .dark-mode .filter_drop_wrap_sidebar .rbt, 
.dark-mode .filter_drop_wrap_sidebar select, .dark-mode .filter_drop_wrap_sidebar .picker-field{
    color: #b2b2b2;
}
.dark-mode .filter_drop_wrap_sidebar input[type="text"]{
    color: #b2b2b2;
}
.dark-mode #user-management-section .equalHM{
    color: #b2b2b2;
}
.dark-mode .rbstyle input[type=radio]:checked + label {
    color: #5fbdb4;
}
.dark-mode #feed_general{
    background: #1a1a1a;
}
.dark-mode  #feed_general .feed-item{
    background-color: #1a1a1a;
}
.dark-mode .project-task-items{
    background: #000 !important;
}
.dark-mode .qa-tab-wrapper{
    background-color: #1a1a1a;
}
.dark-mode .qa-tab-wrapper .react-tabs__tab--selected{
    color: #f1f1f1 !important;
    background: #3b7770;

}
.dark-mode .qa-card{
border-bottom: 1px solid #333;

}
.dark-mode .qa-card .das-qa{
    color: #bfdad7;
}
.dark-mode .checkqa{
    border: 1px solid #333;
    background: #1d1c1c;
    color: #b2b2b2;
}
.dark-mode .qr-report .react-tags{
    background: #2b2b2b !important;
}
.dark-mode .checkqa:hover{
    background: #1c3c38;
    border: 1px solid #0e5f54;
}
.dark-mode .textareabox{
    background: #2b2b2b;
    border: 1px solid #2b2b2b;
    color: #b2b2b2;
}
.dark-mode .health-filter{
    background: #000;  
}
.dark-mode .filter-row {
    padding: 15px 0 !important;
}
.dark-mode .filter-row p{
    color: #e2e2e2;
}
#reportMonthly .years, #reportMonthly .months{
min-width: 150px;
}
.dark-mode .react-tel-input .flag-dropdown.open .selected-flag {
    background: #2b2b2b;
    border-radius: 3px 0 0 0;
}
.dark-mode .react-tel-input .country-list{
    background: #2b2b2b;
}
.dark-mode .closed-board-wrapper .closed-items a{
    color: #b2b2b2;
}
.dark-mode #all-tasks .task-total-time{
    color: #bfdad7;
    opacity: 1;
}
.dark-mode .checkqa.green {
    background-color: #129482 !important;
    color: #fff;
    border: 0;
}
.dark-mode .health_rpot_header {
    background: #2b2b2b !important;
}
.dark-mode a.report-tab-list {
    color: #bfdad7;
}
.dark-mode .inner-row-wraper-for-list:after{
    background: #333;
}
.dark-mode .CalendarDay__selected, .dark-mode .CalendarDay__selected:active, 
.dark-mode .CalendarDay__selected:hover{
    background-color: #1c3c38 !important;
    border: 1px solid #0e5f54 !important;
    color: #fff;
}
.dark-mode .health-datas .card{
    color: #e2e2e2;
    background: #2a2a2a !important;
    border-top: 0 !important;
}
.dark-mode .health-data-content{
 background: #1a1a1a;
}
.dark-mode .task-timing .time{
    color: #bfdad7;
}
.dark-mode .single-task.active .task-details p{
    color: #e2e2e2;
}
.dark-mode .task-client-detail{
    color: #e2e2e2;
}
.dark-mode .btn-green{
    color: #e2e2e2;
}
.dark-mode .task-project-name{
    color: #bfdad7;
}
.dark-mode .switchUser .date-col .form-control::placeholder{
    color: #9fb9b6;
}
.dark-mode .textareabox::placeholder{
    color: #9fb9b6;
}
.dark-mode .react-tags__search-input::placeholder,
.dark-mode .feed_comment_input::placeholder,
.dark-mode .date-range-col .DateInput_input::placeholder,
.dark-mode .DateInput_input::placeholder{
    color: #9fb9b6;
}
.dark-mode .react-tags__search-input{
    color: #b2b2b2;
}
.dark-mode .feed-banner h2, .dark-mode .feed-banner h3, .dark-mode .feed-item-wish h3{
    color: #e2e2e2;
}
.dark-mode .feed-post-date{
    color: #bfdad7;
}
.dark-mode .feed-sidebar-quote{
    color: #bfdad7; 
}
.dark-mode .feed-sidebar-title{
    color: #e2e2e2  !important;
}
.dark-mode .bds-person-cont .d-flex p:first-of-type{
    color: #e2e2e2; 
}
.dark-mode .react-datetime-picker__inputGroup__divider {
    color: #bfdad7;
}
.dark-mode  .react-datetime-picker__inputGroup__input{
    color: #bfdad7;
}
.dark-mode .react-datetime-picker__inputGroup__leadingZero{
    color: #bfdad7 !important;
}
.dark-mode .rbt-input-main::placeholder{
    color: #bfdad7 !important;
}
.dark-mode .rbt-input-main{
    color: #bfdad7;
}
.dark-mode #date-list-contianer .project-card.shift-card .card .name,
.dark-mode  #date-list-contianer .project-card .card .time-entered,
.dark-mode #date-list-contianer .project-card.work-card .card .name
{
    color: #bfdad7;  
}
.dark-mode #date-list-contianer .project-card.shift-card .card .card-body .project-name{
    color: #bfdad7; 
}
.dark-mode span.project-name-title{
    color: #e2e2e2;
    
}
.dark-mode span.task-name-title {
    font-size: 14px;
    color: #afc7c4;
}
.dark-mode .single-project{
    padding: 10px 0;
    border-bottom: 1px solid #333; 
}
.dark-mode .DateRangePickerInput_arrow_svg{
    fill: #bfdad7;
}
.dark-mode .date-range-col .DateInput_input{
    color: #bfdad7;
}
.dark-mode  #hourfilter{
    color: #bfdad7;
}
.dark-mode .list-head-cont .name, .dark-mode .list-head-cont .time-entered{
    color: #e2e2e2 !important;
}
.dark-mode .project-log #date-list-contianer .card .name{
    color: #bfdad7;
}
.dark-mode .project-log #date-list-contianer .card .time-entered{
    color: #bfdad7;
}
.dark-mode #list-head p{
    color: #e2e2e2  !important;

}
.dark-mode .CalendarMonth_caption{
    color: #bfdad7;
}
.dark-mode .DayPicker_weekHeader_ul{
color: #b2b2b2;
}
.dark-mode #date-list-contianer .card p.project-name ,.dark-mode #date-list-contianer .card p.client-time{
    color: #bfdad7 !important;
}
.dark-mode .DateInput_input{
    color: #bfdad7;
}
.dark-mode .inner-row-wraper-for-list{
    color: #e2e2e2;
}
.dark-mode .data-sync{
    color: #bfdad7;
}
.dark-mode .trello-card{
    background: #1a1a1a;
}
.dark-mode .board-header  h3{
    color: #e2e2e2;
}
.dark-mode  .trello-card h6.card-desc{
    color: #afc7c4;
}
.dark-mode .trello-card .card-detail-description{
color: #bfdad7;
}
.dark-mode  .trello-card h6 b{
color: #e2e2e2;
}
.dark-mode  .trello-card a{
    color: #5d8881;
}
.dark-mode  .comment-date{
    color: #b2b2b2;
}
.dark-mode .card-detail-description{
    background: #000 !important;
    padding: 15px;
}
.dark-mode .author b{
    color: #e2e2e2;
}
.dark-mode #yearfilter{
    color: #bfdad7;
}
.dark-mode .filter_drop_wrap .form-control::placeholder{
    color: #bfdad7;
}
.health-data-content{
    position: absolute;
    top: 15%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.dark-mode .ticket-sidebar-wrapper .ticket-id-title{
    color: #e2e2e2;
}
.dark-mode .ticket-id-value{
    color: #bfdad7;
}
.dark-mode .ticket-summary-description p span{
    color: #bfdad7 !important;
}
.dark-mode .ticket-subjet-txtbox{
    color: #bfdad7 !important;
}
.dark-mode .ticket-subjet-txtbox::placeholder{
    color: #bfdad7 !important;
}
.dark-mode .form-control::placeholder{
    color: #bfdad7;
}
.dark-mode .filter-team-management .form-control{
    color: #bfdad7;
}
.dark-mode  .team-view-list .card div{
    color: #bfdad7;
}
.dark-mode .appr-head h5{
    color: #e2e2e2;

}
.dark-mode .appr-msg{
    color: #bfdad7;
}
.dark-mode .caros-title{
    color: #e2e2e2;
}
.dark-mode #approval-head button{
    border: 1px solid #333;
    background: #2b2b2b;
    color: #a6c1bd;
}
.dark-mode .leave-balence-card p{
    color: #bfdad7;
}
.dark-mode .leave-note strong {
    color: #e2e2e2;
}
.dark-mode .leave-note span {
    color: #bfdad7;
}
.dark-mode #leave_balence .title{
    color: #e2e2e2;
}
.dark-mode  .upcoming_company_holiday p{
    color: #bfdad7;
}
.dark-mode  .upcoming_company_holiday h4{
    color: #e2e2e2;
}
.dark-mode  .leave-status-card .leave-status-col h4{
    color: #bfdad7;
}
.dark-mode  .leave-status-card .status.pending h4 {
    color: #ff7800;
}
.dark-mode .leave_calender_details h4{
    color: #e2e2e2;
}
.dark-mode #loan-details-head p{
    color: #bfdad7;
}
.dark-mode #loan-details-head  p.title{
    color: #e2e2e2;
}
.dark-mode .date-col .form-control{
    background-color: #2b2b2b;
    background-clip: padding-box;
    border: 1px solid #333;
    color: #bfdad7;
}
.dark-mode .admin-summary-list h3.title{
    color: #e2e2e2 !important;
}
.dark-mode .loan-summery-list-item .user{
    color: #e2e2e2 !important;
}
.dark-mode .loan-summery-list-item .date{
    color: #bfdad7 !important;
}
.dark-mode .loan-summery-list-item .description,
.dark-mode .loan-summery-list-item .amount,
.dark-mode .loan-summery-list-item .type,
.dark-mode .loan-summery-list-item .status,
.dark-mode .loan-summery-list-item .action{
    color: #bfdad7 !important;
}
.dark-mode .list-body-cont{
    color: #bfdad7 !important; 
}
.dark-mode #user-management-section h5{
    color: #e2e2e2;
}
.dark-mode .health-data-content h5{
    color: #e2e2e2;
}
ç .close-health{
    color: #e2e2e2;
}
.dark-mode #login-card{
    background: #fff !important;
}
.dark-mode #login-card label{
    color: #323232;
}
.dark-mode .login-wrapper .form-control{
    background: #fff;
}
.dark-mode  .cc-all-peoples{
    background: #1a1a1a;
    border-color:#333 ;
    box-shadow: none;
}
.dark-mode  textarea.leave-reason-textarea{
    color: #bfdad7;
}
.dark-mode  #filter .filter-row{
    background: #000;
    border-radius: 0;
}
.dark-mode .ticket-wrapper .single-line-data span{
    color: #bfdad7;
}
.dark-mode .dropdown-item:focus, .dark-mode .dropdown-item:hover{
    background: #333 !important;
}
.dark-mode .LoadingBox {
    width: 100%;
    height: 100%;
    animation: Loading 0.9s cubic-bezier(0.03, 0.38, 0.47, 0.74) infinite;
    animation-fill-mode: forwards;
    background: #f6f7f8;
    /* background: linear-gradient(to right, #fafafa 8%, #dfdfdf 38%, #fafafa 54%); */
    background: linear-gradient(to right, #000000, #131111, #0c0606);
    /* background-size: 1000px 640px; */
    background-size: 125% 640px;
    background-size: cover;
    position: relative;
    min-height: 35px;
}
.dark-mode .health-comments{
    background: #000;
}
.dark-mode .ql-container.ql-snow, .dark-mode .ql-toolbar.ql-snow, 
.dark-mode .health-comments .health-comment-field input {
    border: 1px solid #333;
}
.dark-mode  .ql-snow .ql-picker{
    color: #bfdad7;
}
.dark-mode .ql-snow .ql-stroke{
    fill: none;
    stroke: #bfdad7;
}
.dark-mode .health-comments .ql-editor {
    background: #333;
    color: #bfdad7 !important;
}
.dark-mode .health-comments .ql-editor{
    color: #bfdad7;
}
.dark-mode .ql-editor.ql-blank::before{
    color: #bfdad7;
}
.dark-mode .health-comments .health-comment-field input{
    color: #bfdad7;
}
.dark-mode .trello-card .attachment-block .attachmet-label{
    color: #bfdad7;
}
/* .dark-mode #date-list-contianer .user-active {
    padding: 10px;
    background: #194e46;
} */
.dark-mode #filter .search-btn button:hover{
    color: #5fbdb4 !important;
}
.dark-mode .fixtab {
    background: #1a1a1a; 
}
.dark-mode .fixtab::after{
    background: #797979;  
}
.dark-mode #user-management-section label{
    color: #bfdad7;
}
.dark-mode .board-count{
    color: #bfdad7;
}
.dark-mode .rbt-input-hint{
 color:#b2b2b2  !important;
}
.dark-mode .popup-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    background: rgb(80 80 80 / 96%);
    z-index: 99999;
    margin-left: -15px;
}
.dark-mode .tea-break-popup {
    width: 57%;
    position: absolute;
    left: 50%;
    top: 30%;
    background: #000;
    min-height: 200px;
    padding: 1.5rem;
    transform: translate(-50%, -50%);
    box-shadow: 10px 10px 20px 0px rgb(0 0 0 / 40%);
}
.dark-mode .qa-tab-wrapper .react-tabs__tab{
    color: #adadad;
}
.dark-mode .topHeaderbar .inactiveicon:hover svg path{
    fill-rule: evenodd;
    fill: #069482 !important;
}
.dark-mode .inactivetaskbox{
    opacity: .8;
}
.dark-mode .internal-comment-overlay{
    background: rgb(0 0 0 / 83%);  
}
.dark-mode  .internal-comment-box{
    background: #383838;
}
.dark-mode .internal-comment-box textarea{
    color: #bfdad7;
}
.dark-mode .shift-day-bold{
    background: #b30000 !important;
}
.dark-mode  #report-monthly-head .container{
    color: #bfdad7;
}
.dark-mode .page_header_wrap{
    border-bottom: 1px solid #333;
}
.dark-mode .breadcrumb-item.active{
    color: #bfdad7 !important;
}
.dark-mode .home_button_box{
    background-color: #1c3c38 !important;
    border: 1px solid #0e5f54 !important;  
}
.dark-mode button.addbtn_text span{
    background-color: #1c3c38 !important;
    border: 1px solid #0e5f54 !important;
  
}
.dark-mode button.addbtn_text{
    color: #bfdad7;   
}
.dark-mode .attchfiles_wrap{
    background: #3e3e3e;
}
.dark-mode h4.boxtitile{
    color: #b2b2b2;
}
.dark-mode  .filetype_note, .dark-mode .filesize{
    color: #bfdad7;
}
.dark-mode .filepond--drop-label {
    background: #323232;
    border: 1px dashed #666;
}
.dark-mode .filepond--panel-root{
    background: #323232;
}
.dark-mode .save_btn{
    background-color: #1c3c38 !important;
    border: 1px solid #0e5f54 !important;  
}
.dark-mode .btn-search{
    background-color: #000;
}
.dark-mode h2.board_name{
    color: #e2e2e2;
}
.dark-mode p.board_discription{
    color: #bfdad7;
}
.dark-mode .memb_avatarlist .member_count{
    border: 1px solid #333;
    color: #bfdad7;
}
.dark-mode .add_member_btn{
    background-color: #464646;
    border: 1px solid #202020;
}
.dark-mode .boardcard:hover{
    box-shadow: none !important;
}
.dark-mode p.board_discrption{
    color: #bfdad7;
}
.dark-mode .add_member_link {
    color: #bfdad7;
    border: 1px solid #333;
    background: #262626;
 }
 .dark-mode .board_detail_wrapper p.discption{
    color: #bfdad7;
 }
 .dark-mode .search__text{
    height: 40px;
    border-radius: 0;
    background: #020202;
    color: #bfdad7;
 }
 .dark-mode .search:hover{
     background: #020202;
 }
 .dark-mode .draft_btn::after{
    background-position: -20px 0px;
 }
 .dark-mode .draft_btn{
    box-shadow: 0 0 0 0.5px rgb(255 255 255 / 68%) !important;
    color: #fff;
    background-color: #000;
 }
 .dark-mode .arch_btn{
    box-shadow: 0 0 0 0.5px rgb(255 255 255 / 68%) !important;
    color: #fff;
    background-color: #000;
}
.dark-mode .arch_btn::after{
    background-position: -20px -15px;
}
.dark-mode .task_box {
    background: #101010;
   box-shadow: 0 0 0 0.5px rgb(122 122 122 / 68%) !important;
}
.dark-mode .task_titile{
    color: #fff;
}
.dark-mode .duedate_time span{
    color: #bfdad7; 
}
.dark-mode .duedate_time{
   color: #bfdad7;
}
.dark-mode .assign_person, .dark-mode .assign_person span{
    color: #bfdad7;
}
.dark-mode .new_tas_formbody{
    background: #303030;
}
.dark-mode #newtask .form-control {
    margin-bottom: 10px;
    background: #000;
}
.dark-mode .newtask_form_body .react-datetime-picker{
    background: #000;
}
.dark-mode .cancel_btn{
    background: #505050;
    color: #bfdad7;
}
.dark-mode .btn_sec button:hover{
    background-color: #1c3c38 !important;

}
.dark-mode .sd_btn{
    background: #505050;
    color: #bfdad7; 
}
.dark-mode .react-tags{
    background: #000 !important;
}
.dark-mode #newtask .react-tags__search-input{
    background: #000;
}
.dark-mode .choose_temp{
    background: #000;
    color: #fff;
}
.dark-mode .task_omwer_name span, .dark-mode .task_due_date span{
    color: #bfdad7;
}
.dark-mode .comment_view_wraper{
    background: #1a1a1a;
}
.dark-mode .user_bc_name{
    color: #b2b2b2;
}
.dark-mode .comments_bc h3{
    color: #fff;
}
.dark-mode .comments_bc p{
    color: #bfdad7;
}
.dark-mode .attach_images h3{
    color: #fff;
}
.dark-mode .editer_cmd_box{
    background: #1a1a1a;
}
.dark-mode .note_add_wrapper .quill{
    background: #1a1a1a;
    box-shadow: none !important;
}
.dark-mode .note_add_wrapper .quill .ql-editor{
    background: #303030;
    color: #bfdad7 !important;
}
.dark-mode .task_leftbox.newtask_form_body label{
    color: #bfdad7 !important;
}
.dark-mode .task_leftbox .form-control{
    background: #000;
    color: #bfdad7;
}
.dark-mode .task_leftbox  .react-tags__search-input{
    background: #000 !important;
}
.dark-mode .brand_popup .input_box{
    background: #000;
    color: #bfdad7;
}
.dark-mode .modal-footer{
    border-top: 1px solid #333;
}
.dark-mode .addbrand_btn{
    background-color: #1c3c38 !important;
    border: 1px solid #0e5f54 !important; 
}
.dark-mode .modale_head h5{
    color: #b2b2b2;
}
.dark-mode .brands_box{
    color: #bfdad7;
}
.dark-mode .brands_box:hover{
    box-shadow: none !important;
}
.dark-mode .dropdownboard .dropdown-menu{
    border-color: #333;
}
.dark-mode .folder_box:hover{
    box-shadow: none !important;
}
.dark-mode .folder_name{
    color: #bfdad7;
}
.dark-mode h2.barnd_not_titile{
    color: #e2e2e2;
}
.dark-mode .comment_preview{
   background: #1a1a1a;
}
.dark-mode .comment_text{
    color: #bfdad7;
}
.dark-mode .qucik_button button{
    color: #e1e1e1;
}
.dark-mode .add_member{
    background: #1a1a1a;
}
.dark-mode .added_member_prev{
    border-bottom: 1px solid #333;
}
.dark-mode .boardadd_form{
    background: #1a1a1a;
    border-top:1px solid #333 ;
}
.dark-mode .boardadd_form .text_box{
    color: #bfdad7;
    background: #000;
    border: 1px solid #333 !important;
    box-shadow: none !important;
}
.dark-mode .boardadd_form .textarea_box{
    background: #000;
    color: #bfdad7;
    border: 1px solid #333 !important;
}
.dark-mode .boardadd_form::after{
    border-top: 10px solid #4d4d4d;
}
.dark-mode .note_add_wrapper .quill{
    box-shadow: 0 0 0 0.5px rgb(122 122 122 / 68%) !important;
}
.dark-mode  .request-approval-shift p.sub-title{
    color: #b2b2b2;
}
.dark-mode  .request-approval-shift{
    background: #383838;
}
.dark-mode  .new-people-content .form-control{
    color: #bfdad7;
}
.btn-secondary.focus, .btn-secondary:focus {
    color: inherit;
    background: #fff !important;
}

#body.dark-mode select option{
    background:#000;
}