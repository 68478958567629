/* 
  Name: FeedCommentLike
  Path: components/Feeds/FeedComments/FeedCommentAction/FeedCommentLike
*/

.feed_comment_action_icon {
  width: 20px !important;
  height: 20px !important;
  display: block;
  background-size: 100% auto !important;
  margin-right: 7px;
}

.feed_comment_action_icon_liked {
  background: url('../../../../../images/comment_like_icon_outline.svg');
 
  /* background: url('../../../assets/comment_like_icon_fill.svg'); */
}

.feed_comment_action_icon_not_liked {
  background: url('../../../../../images/comment_like_icon_fill.svg');
}

/* .feed_comment_action_icon_comment {
  background: url('../../../assets/comment_icon_line.svg');
} */

.feed_comment_action {
  margin-right: 17px;
  align-items: center;
}

.feed_comment_action_wrapper {
  margin-bottom: 17px;
}