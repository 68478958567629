
button.bell-icon{
    width: 44px;
    height: 44px;
    padding: 5px 7px ;
    background: #EFF5F9 !important;
    border:none;
    border-radius: 8px;
    position: relative;
}
.noti_alert{
    width: 20px;
    height: 20px;
    background: #069482 !important;
    border-radius: 10px;
    color: #fff;
    position: absolute;
    top: -3px;
    right: -7px;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
}
button.bell-icon svg{
    color: #ADB5BD;
}  
button.bell-icon:hover{
    background: #f2f2f2 !important;
}
button.bell-icon:focus{
    background: #f2f2f2 !important;
}
button.bell-icon img{
    width: 100%;
}
.custom_popover .popover{
    z-index: 99999;
    max-width: 350px;
    width: 100%;
    border: 1px solid #EBF3FB;
    border-radius: 8px;
    box-shadow: 0px 4px 32px 0px rgba(108,161,206,0.12);
}
.custom_popover .popover .arrow{
    display: none;
}
.custom_popover .popover h3.popover-header{
    padding: 10px;
    background: #fff;
    overflow: hidden;
    -webkit-border-top-left-radius: 8px;
    -webkit-border-top-right-radius: 8px;
    -moz-border-radius-topleft: 8px;
    -moz-border-radius-topright: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding:15px;
    font-size: 18px;
}
.custom_popover .popover .popover-body a{
    display: block;
padding: 15px;
text-align: center;
font-size: 13px;
color: #069482;
text-decoration: underline;
font-weight: 500;
}
.custom_popover .popover .popover-body a:hover{
    color: #069482;
    text-decoration: underline;  
}
.custom_popover .popover .popover-body{
    padding: 0;
}
.quick_noti_list_item{
    border-bottom: 1px solid #f1f1f1;
    padding: 15px;
}
.quick_noti_list_item h4{
    font-size: 15px;
    margin: 0;
    padding: 0 0 5px 15px;
    color: #212529;
    position: relative;
}
.quick_noti_list_item h4::before{
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    background: #069482;
    border-radius: 10px;
    top: 5px;
    left: 0;
}
.quick_noti_list_item p{
    color: rgb(124, 129, 134);
    font-size: 14px;
    padding: 0;
    margin: 0;
    white-space: pre-line;
}
.quick_noti_list_item:last-child{
    border-bottom: 0 !important;
}
.date_n_time{
    font-size: 12px;
    color: rgb(191, 191, 191);
}
.noti_detail{
    padding-left: 15px;
}

.noti_detail a, .noti_detail a:hover
{
    padding: 0px!important;
    text-align: left!important;
    text-decoration: none!important;
    font-weight: normal!important;
    cursor: pointer;
}
.custom_popover .popover .arrow{
    display: none;
}
.custom_popover .popover h3.popover-header{
    padding: 10px;
    background: #fff;
    overflow: hidden;
    -webkit-border-top-left-radius: 8px;
    -webkit-border-top-right-radius: 8px;
    -moz-border-radius-topleft: 8px;
    -moz-border-radius-topright: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding:15px;
    font-size: 18px;
    border: 0px!important;
}
.popover-header-wrap
{
    border-bottom: 1px solid #f1f1f1;
}

.popover-header-wrap a{
    display: block;
padding: 15px;
text-align: center;
font-size: 13px;
color: #069482;
text-decoration: underline;
font-weight: 500;
}

.custom_popover .popover .popover-body a{
    display: block;
padding: 15px;
text-align: center;
font-size: 13px;
color: #069482;
text-decoration: underline;
font-weight: 500;
}
.custom_popover .popover .popover-body a:hover{
    color: #069482;
    text-decoration: underline;  
}
.custom_popover .popover .popover-body{
    padding: 0;
}
.quick_noti_list_item{
    border-bottom: 1px solid #f1f1f1;
    padding: 15px;
}
.quick_noti_list_item h4{
    font-size: 15px;
    margin: 0;
    padding: 0 0 5px 15px;
    color: #212529;
    position: relative;
}
.quick_noti_list_item h4::before{
    position: absolute;
    content: "";
    width: 10px;
    height: 10px;
    background: #069482;
    border-radius: 10px;
    top: 5px;
    left: 0;
}
.quick_noti_list_item p{
    color: rgb(124, 129, 134);
    font-size: 14px;
    padding: 0;
    margin: 0;
}
.quick_noti_list_item:last-child{
    border-bottom: 0 !important;
}
.date_n_time{
    font-size: 12px;
    color: rgb(191, 191, 191);
}
.noti_detail{
    padding-left: 15px;
}
.allnotifiction .react-tabs__tab{
    border: none;
    padding: 0 0px 15px 0;
    font-size: 16px;
    color: #6C757D;
    font-weight: 400;
    margin-right: 30px;
}
.allnotifiction .react-tabs__tab--selected{
    color: #219186;
    border-bottom: 1px solid #069482;
}
.allnotifiction .react-tabs__tab-list{
    border-bottom: 0;
}
.allnotifiction .react-tabs__tab-panel{
    padding:0 0 20px 0;
}
.allnotifiction .notification_list{
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    border-bottom: 1px solid #eff5f9;
}
.allnotifiction .notification_list .notifi_data{
    width: 90%;
}
.allnotifiction .notification_list .notifi_data h3{
    color: #212529;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    padding: 0 0 10px 0;
}
.allnotifiction .notification_list .notifi_data p{
    font-size: 15px;
    color:#6C757D;
    line-height: 28px;
    margin: 0;
    padding: 0;
}
.allnotifiction .notification_list .notifi_data span.daten_time_notify{
    color: #6C757D;
    font-size: 13px;
    margin: 0;
    padding: 5px 0 0 0;
}
.allnotifiction .notification_list .notifi_checkbox{
    display: flex;
    align-items: center;
    justify-content: center;
}
.read_notifiction{
    opacity: .5;
}
.noti-click
{
    cursor: pointer;
}
.all-notifications-read
{
    cursor: pointer;
}
.notifi_data
{
    cursor: pointer;
}
