.colleagues_task h1.page-title{
    margin: 0 !important;
    font-weight: 600;
    font-family: 'Poppins', sans-serif !important;
    text-align: left !important;
    color: #212529 !important;
    font-size: 20px;
    letter-spacing: 0.00938em;
    padding: 0 0 30px 0;
}
.colleagues_task .switchUser{
    margin: 0 !important;
}
.no-task-running{
    padding: 30px 0;
    text-align: center;
}

.colleagues_task .single-task .task-end.activeicon
{
    display: block;
}


.colleague-timers .override-policy input[type="checkbox"]:after {
    border-color: #111;
    top: -3px!important;
}

.colleague-timers .override-policy label {
    font-size: 14px;
    color: #111;
}
.colleague-timers .single-task {
    cursor: pointer;
    background: #fff;
    min-height: 160px;
}
.colleage-shift-break
{
    margin-top: 10px;
}
.colleage-shift-break .start_b
{
    margin-top: 25px!important;
    font-size: 16px!important;
}

.colleague-timers .white_bg_btn {
    max-width: 180px;
    
}

.colleague-timers .white_bg_btn {
    box-shadow: none!important;
}
.colleague-timers  .override-policy label {
    padding: 0 20px 0 5px;
}

.override-policy .colleage-shift-break {
    margin-top: 18px;
}

.override-policy  input[type="checkbox"] {
    margin-right: 40px!important;
}
.colleague-timers span.active-msg {
    display: block;
    margin-top: 32px;
    font-weight: 500;
    color: red;
}

.colleague-timers .task-details .task-owner {
    width: auto;
}

.colleague-timers .postby_name {
    width: auto;
}