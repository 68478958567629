/*
 * Single Comment 
 */

.single-comment {
  align-items: center;
  background: #f9fafa;
  border-radius: 4px;
  border-left: 5px solid #f2f2f2;
  padding: 14px;
  margin-bottom: 20px;
}

 .single-comment .author-box {
  width: 35%;
  padding-left: 11px;
}

.single-comment .comment-box {
  width: 65%;
}

.single-comment p {
  margin-bottom: 0;
}

.single-comment .author {
  color: #0e0e0e;
	font-size: 16px;
}