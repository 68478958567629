/* UpcomingCompanyHoliday */
.upcomeing_leave_card{
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 15px;
  gap: 15px;
  grid-auto-rows: minmax(auto, auto);
}
.upcoming_company_holiday p {
  font-size: 12px;
  margin-bottom: 4px;
}

.upcoming_company_holiday h4 {
  font-size: 16px;
  margin-bottom: 0;
}
.upcoming_company_holiday h4:last-child{
  font-size: 14px;
  color: #6C757D;
  font-weight: 400;
  margin: 0;
  padding: 7px 0 0 0;
}
.upcoming_company_holiday {
  border-radius: 8px;
  padding: 20px;
  background: #F3FAFF;
}

.upcoming_company_holiday > div {
  margin-right: 100px;
}

.upcoming_company_holiday > div:last-child {
  margin-right: 0;
}

.upcoming_company_holiday .day {
  min-width: 100px;
}

.upcoming_company_holiday .occasion {
  min-width: 165px;
}

#downArrow.hidelist {
  transform: translateY(26px) rotate(180deg);
}

.upcoming_company_holiday #LoadingBox {
  animation-duration: 3s !important
}

.upcoming-holiday-card
{
  width:100%;
  margin:0 auto;
}

/* Media Quries */
@media only screen and ( max-width: 576px ) {
  #leave_header .leave-menu-anchor {
    text-transform: capitalize;
  }
  
  .upcoming_company_holiday {
    padding: 23px;
  }
  .upcoming-holiday-card
  {
    width:100%;
  }
}