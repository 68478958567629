/* Login Page */
.pad_LR_0{
  padding-left: 0;
  padding-right: 0;
}
.login_page{
  background: #fff
}
.monkey_img_sec {
  position: relative;
  background: #fff;
  background: url(../../images/login_monky_img.png);
    background-size: auto;
  height: 100vh;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.monkey_img_sec img{
  width: 75%;

/* -webkit-animation: 5s ease-out 0s 1 slideInFromLeft; /* Chrome, Safari, Opera 
animation: 5s ease-out 0s 1 slideInFromLeft; */
}
/*@keyframes slideInFromLeft {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(-0%);
  }
} */

/* @keyframes animate{
  from{
  transform:rotate(0deg);
  } 
  to{
  transform:rotate(-500deg);
  }
  from{
  right:5px;
  } 
  to{
  right:80%;
  }
} */
.login_logo {
  width: 100px;
  float: right;
  margin-right: 30px;
  margin-top: 30px;
}

.monkey_img_sec h1.card-title {
  color: #FFC83D;
  font-size: 50px;
  font-weight: 600;
  margin: 0;
  padding: 40px 0 10px 0;
}
.monkey_img_sec p.card-text {
  font-weight: 400;
  color: #FFF0CB;
  line-height: 32px;
  font-size: 18px;
}
.login_from {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 3rem 0 3rem;
  background: #fff;
  max-width: 600px;
}
.login_from h3.card-title {
  color: #055949;
  font-size: 35px;
  font-weight: 400;
}
.login_from h2.card-title {
  color: #055949;
  font-size: 40px;
  font-weight: 700;
  padding: 0 0 25px 0;
  margin: 0;
}
.login_from p.card-text {
  color: #495057;
  font-size: 16px;
  padding: 0 0 30px 0;
  margin: 0;
}
.flex_direction {
  display: flex;
  flex-direction: column-reverse;
}
.custom_input_style {
  height: 60px;
  padding-left: 15px;
  padding-right: 15px;
  background: #fff;
  border: 1px solid #CED4DA;
  box-sizing: border-box;
  border-radius: 12px;
}
.signin_from .form-label {
  font-size: 12px;
  color: #6C757D;
  font-weight: 500;
  margin: 0;
  padding: 0 0 5px 0;
}
.login_btn {
  border-radius: 12px;
  background: #055949;
  border: 1px solid #055949;
  box-sizing: border-box;
  color: #fff;
  width: 100%;
  display: block;
  margin-top: 40px;
  min-height: 51px;
  font-size: 18px;
  font-weight: 500;
  transition: all .8s ease-in-out;
  min-height: 60px;
}
.login_btn:hover {
  background: #1a7269;
}
#form-submit:focus{
  background: #1a7269 !important;
  color: #fff !important;
}
.custom_input_style:focus{
  box-shadow: none !important;
  border-color:#055949 !important ;
}
.custom_input_style:focus + .form-label{
  color: #055949;
}
input:focus{
  box-shadow: none !important;
}
input:focus-visible{
  box-shadow: none !important;
  outline: none;
}
.login_btn:focus{
  background: #004c3f;
  border: 1px solid #004c3f; 
}
.login_from #LoadingWrapper{
  display: flex;
  flex-direction: column-reverse; 
}
.white_bg{
  background: #fff !important;
}
.login-error{
  margin: 0;
  padding:0;
 color: red;
}
/* @media screen and (max-width: 1024px) {
  .monkey_img_sec{
    background-image: url(./assets/images/tab_loginbg.png);
  }
} */
@media only screen and ( max-width: 992px ) {
  .monkey_img_sec{
    display: none !important;
  }
  .monkey_img_sec h1.card-title{
    font-size: 30px;
    padding-top: 20px;
  }
  .monkey_img_sec p.card-text{
    font-size: 14px;
    line-height: 25px;
  }
  .login_from {
    padding: 1.8rem;
    margin: 0 auto;
    height: 100vh;
  }
  .login_from h3.card-title{font-size: 30px;}
  .login_from h2.card-title{
    font-size: 25px;
  }
  #LoadingWrapper{
    overflow-x: hidden !important;
  }
}






.login-wrapper {
  height: 100vh;
  position: relative;
}

#login-page {
  background: #069482;
  height: 100vh;
  width: 100vw;
  position: relative;
}

.kimp-image {
  max-width: 698px;
}

#login-page .doto-logo {
  width: 280px;
  margin: 0 auto;
}

#login-card {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 99;
  padding: 80px 100px;
  border-radius: 5px;
  box-shadow: 20.479px 14.339px 40px 0px rgba(5, 132, 116, 0.2);
  background: #fff;
}

.rem-me-group {
  align-items: center;
  justify-content: center;
  margin-left: -10px;
}

.rem-me-group input {
  width: auto;
  height: 13px;
}

.rem-me-group input[type="checkbox"]:after {
  left: 9px;
}

#login-card .doto-logo {
  margin-bottom: 30px;
}

#login-card .text-center {
  margin-bottom: 40px;
}

#login-card input[type="email"], 
#login-card input[type="password"] {
  height: 58px;
  border: 1px solid #636363;
  padding-left: 28px !important;
  font-size: 18px;
  outline: none;
}

#login-card input[type="email"]:focus, #login-card input[type="email"]:active,
#login-card input[type="password"]:focus, #login-card input[type="password"]:active {
  border: 1px solid #069482;
  box-shadow: 0 0 10px 0 rgba(5, 132, 116, 0.2);
}

#login-card input[type="submit"] {
  height: 58px;
  background: #fcc43b;
  color: #000;
  font-size: 20px;
  border: none;
  outline: none;
  margin-top: 42px
}

#login-card input[type="submit"]:hover {
  background: #069482;
  color: #fff;
}

#login-card input::placeholder{
  color: #919191
}

#login-card input[type="email"] {
  margin-bottom: 25px !important
}

#remember-me {
  padding: 8px 0;
}

#remember-me label {
  margin: 0;
}

#remember-me a {
  text-align: right;
  float: right;
  color: #636363;
  text-decoration: underline;
}

/* Media Quries */
@media only screen and ( max-width: 1024px ) {
  #login-card  #LoadingWrapper {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}
  #login-card{
    padding: 40px;
  }
  .monkey_img img{
    width: 80%;
  }
  #login-page .doto-logo{
    width: 200px;
  }
  #remember-me{
    display: none !important;
  }
}
@media only screen and ( max-width: 769px ) {
.rem-me-group {
   margin-left: 0;
}
#remember-me a {
  text-align: center;
  float: none;
  color: #636363;
  text-decoration: underline;
  width: 100%;
  display: block;
  margin-top: 10px;
}
#login-page .row{
  margin-right: 0px;
  margin-left: 0px;
}
#login-page .col-sm-6{
  flex: 0 0 100%;
  max-width: 100%;
}
#kimp-image{
  width: 80%;
  margin: 0 auto;
  top: 0 !important;
  left: 0px !important;
  right: 0 !important;
  margin-left: 10%;
}

#login-page .doto-logo {
  width: 240px;
  margin: 0 auto;
}
.monkey_img div{
  transform: translateX(-0) translateY(0) scale(1) translateZ(0px) !important;
  opacity: 1;
}
.monkey_img{
  transform: translate(0) !important;
  display: none;
}
#LoadingWrapper {
  overflow-x: hidden;
  overflow-y: hidden;
}
#remember-me{
  display: none;
}
#login-page{
  background: #fff;
}
#login-card{
  box-shadow: none;
}
}
@media only screen and ( max-width: 576px ) {
  #login-card #LoadingWrapper {
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}
  .login-wrapper {
    height: auto;
    position: relative;
  }
  #login-card{
    padding: 10px ;
  position: relative;
    transform: translateY(0);
  }

  #kimp-image {
    max-width: 100%;
    top: 20px;
    left: 7%;
   
    margin-left: 0;
    margin: 0 auto;
  }
  .monkey_img{
    transform: translate(0) !important;
    display: none;
  }
  #login-card .doto-logo, #login-card .text-center {
    margin-bottom: 20px;
  }

  #login-card input[type="submit"] {
    margin-top: 16px;
  }

  #remember-me {
    margin-top: 10px;
  }
  #remember-me{
    display: none;
  }
  #login-page{
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
  }
  #login-card{
    box-shadow: none;
  }
}
