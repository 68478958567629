/**
 * Checkbox Styling
 */

input[type="checkbox"] {
  position: relative;
  width: 10px;
  height: 0;  
  display: block;
  margin-right: 30px !important;
}

input[type="checkbox"]:after {
  position: absolute;
	content: '';
	width: 15px;
	height: 15px;
	border-radius: 3px;
	border: 1px solid gray;
	background: transparent;
	display: block;
	background-size: 20% auto;
	background-position: center;
	transition: all 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
	left: 20px;
	top: -3px;
}

input[type="checkbox"]:checked:after {
  content: '';
  background: #069482 url(../images/tick-white.png) no-repeat center;
  background-size: 80% auto;
  color: #fff;
  border: 1px solid #069482;
}
