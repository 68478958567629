/*
* AddmyTask 
*/

.add-task-box {
  z-index: 99999;
  position: fixed;
}

.add-new-task {
  height: auto;
  width: 100%;
  transition: all 0.26s ease-out;
}

.add-new-task h4{
  margin-bottom:20px;
}

/* .add-new-task input[type="text"] {
  border:1px solid #aba8a8;
} */

.add-task-box {
  position: absolute;
}
.add-new-task input,
.add-new-task button {
  height: 50px;
}

.add-new-task input {
  width: 100%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: none;
}

.add-new-task input::placeholder {
  color: #bdbdbd
}

.add-new-task input:focus,
.add-new-task input:active {
  box-shadow: none;
  border: 1px solid #069482;
  border-right: none;
}

.add-new-task button {
  width: 55px;
  margin: 0;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.add-new-task {
  box-shadow: 0px 10px 15px 0px rgba(193, 193, 193, 0.2);
  margin-top: 20px;
	z-index: 99999;
	position: relative;
	width: 0;
	overflow: hidden;
  margin-bottom: 30px;
}

.add-new-task.active {
  width: 100%;
  background-color: #fff;
}

.add-task.head{
  margin-bottom: 30px;
  margin-top: 30px;
}
/* Media Quries */
@media only screen and (max-width: 576px) {
  #all-tasks .col-12:first-of-type {
    padding: 0;
  }
  .add-task.head #task-today {
    width: 0 !important;
    height: 0px !important;
  }

  .single-task-container {
    flex-wrap: wrap;
  }

  .single-task-container .sl-no {
    width: 10%
  }
  
  .single-task-container .task-details {
    width: 90%;
  }

  .single-task-container .sl-no, .single-task-container .task-details {
    padding-bottom: 15px;
  }

  .single-task-container .task-btn {
    width: 55%;
    padding-left: 15px;
  }

  #all-tasks .task-total-time {
    width: 20%;
  }

  .single-task-container .task-shot-info {
    width: 10%;
  }

  .single-task-container .basecamp-icon {
    width: 15%;
    padding-right: 10px;
  }
}