/* 

.loan-module {
  padding: 40px 130px;
  border: 1px solid #f2f2f2 !important;
  box-shadow: 12.287px 8.604px 25px 0px rgba(179, 179, 179, 0.1);
  background: #fff;
}

.loan-module .title {
  font-size: 16px;
  margin-bottom: 25px;
}

.loan-module input,
.loan-module .react-datetime-picker,
.loan-module textarea {
  border: 1px solid #f0efef;
  border-radius: 5px;
}

#leave-apply-btn {
  width:140px;
  height: 38px;
  margin: 30px auto 0 auto;
  display: block;
  padding: 0;
}

.loan-apply-reason {
  margin-top: 25px;
}

.loan-module textarea {
  height: 116px;
}

.loan-module .react-datetime-picker {
  height: auto;
  width: 250px
}

.loan-module .react-datetime-picker input {
  border: none !important
}

.loan-module .input-group {
  display: block;
}

.loan-module .input-group .date-col
{
  margin-right: 0px;
}
.loan-module .react-datetime-picker {
  margin-right: 0px!important;
}

.loan-module .input-group:nth-child(2) {
  margin-right: 15px;
  margin-left: 0px;
}

.loan-module .input-group:nth-child(1) {
  margin-right: 15px;
}

.loan-module .input-group:nth-child(3) {
  margin-right: 15px;
  margin-left: 0px;
}

.loan-module .input-group:nth-child(4) {
  
}

.loan-amount  #loan-apply-amount{width:100%!important;border-radius: 6px;}
.repay-amount  #repay-apply-amount, .repay-apply-date select {width:100%!important;border-radius: 6px;}
.loan-module .react-datetime-picker {
  padding: 13px 7px;
} */