/* 
  Name: FeedCommentItem
  Path: components/Feeds/FeedComments/FeedCommentItem
*/

.feed_comment_item {
  padding:20px 0;
  border-bottom: 1px solid #DEE2E6;
}
.feed_comment_item:first-child{
  padding-top: 0 !important;
}
.feed_comment_item:last-child {
  border: none;
  padding-bottom: 0 !important;
}

.feed_comment_item p {
  margin-bottom: 4px;
  color: #6C757D;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  padding-bottom: 8px;
  margin-bottom: 0;
}
.user_detail_feed_cmd{
  display: flex;
  align-items: center;
  margin-bottom: 8px;
} 
.user_detail_feed_cmd img{
  width: 40px;
  height: 40px;
  border-radius: 8px;
  overflow: hidden;
}
.user_detail_feed_cmd h6{
  font-size: 16px;
  font-weight: 600;
  color: #212529;
  margin-left: 10px;
}
.feed_cmd_add_date{
  color: #6C757D;
  font-size: 11px;
}