/* Leave Balence */

.leave-balence-card {
  margin:0 0 15px 15px;
  align-items: center;
  width: 200px;
  padding: 14px 0;
  padding-bottom: 0px;
  border: 1px solid #f2f2f2 !important;
  border-radius: 5px;
  box-shadow: 12.287px 8.604px 25px 0px rgba(179, 179, 179, 0.1);
}

.leave-balence-card:nth-child(4),
.leave-balence-card:last-child {
  margin-right: 0;
}

/*.leave-balence-card:first-child,
.leave-balence-card:nth-child(5) {
  margin-left: 0;
}
*/
.leave-balence-card h1 {
  font-size: 30px;
  color: #069482;
  font-family: "ProximaNova-Bold";
  margin-bottom: 0;
}

.leave-balence-card p {
  margin-bottom: 0;
  font-size: 16px;
  color: #636363
}

.leave-balence-card #LoadingBox {
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.leave-balence-card #LoadingWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 130px;
  display: flex;
	flex-direction: column;
	justify-content: center;
}

.leave-note{
  height:100px;
  font-size:18px;
  text-align: center;
}

.leave-note span
{
  display:block; margin-bottom: 15px;
  float: left;
}


.flip-card {
  perspective: 1000px;
  cursor: pointer;
}
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
  top: 20px;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}
.flip-card-back {
  transform: rotateY(180deg);
}

.flip-card-back p.text-center {font-size: 14px!important;margin-bottom: 10px;}


@media screen and (max-width: 1200px) {
  .leave-balence-card{
    width: 175px;
  }
  .balance-wrapper{
    max-width: 900px;
  }

}
@media screen and (max-width: 1100px) {
  .leave_balance_wrapper{
    max-width: 700px;
  }
  #leave_header p{
    font-size: 16px !important;
  }
  .balance-wrapper #leave_balence {
    padding: 0 30px;
  }
  .leave-balence-card{
    width: 160px;
  }
}