/*
 * Filter Styles
*/
.fliterbox{
    width: 60%;
    display: flex;
    align-items: center;
  }
  /* .fliterbox .filter-label{
    margin-top: 0 !important;
  } */
  
  .calander-icon {
    width: 18px;
  }
  .mgTop20{
  margin-top:20px ;
  }
  .react-datetime-picker {
    border: 1px solid #f2f2f2;
      background: transparent;
      padding: 5px 16px;
      height: 100%;
    display: inline-block;
    width: 262px;
  }
  
  /* .date-col {
    margin-right: 18px;
    width: 260px;
  } */
  
  .filter-row {
    padding: 25px 0
  }
  
  #filter .people select, #filter .hours select {
    width: 100%;
    height: 35px;
  }
  
  #filter .hours {
    width: auto !important;
   
  }
  
  /* #filter .filter-label {
    margin-bottom: 20px
  }
   */
  #filter .filter-row > div {
    align-self: flex-end;
  }
/*   
  #filter .search-btn button {
    background: #069482;
    border: none;
    height: 35px;
    width: 50px;
    border-radius: 50px;
    padding: 0 0 4px 0;
  } */
  
  #filter .search-btn button:hover {
    background: #fcc43b;
    color: #000 !important
  }
  
  #filter .search-btn button:hover path {
    fill: #000 !important
  }
  
  #filter .hours {
    width: 175px;
    align-self: flex-end;
  }
  
  #filter .hours select, #filter .hours p {
    width: auto;
    margin-left: auto
  }
  
  #filter .hours p {
    margin-left: 0;
    margin-right: 10px;
  }
  
  #filter .filter-row .form-group {
    margin: 0;
  }
  
  select.form-control {
    appearance: none;
    background: url(../../images/select-arrow-green-down.png) no-repeat 95% center;
  }
  
  #hourfilter {
    background: url(../../images/select-arrow-gray-down.png) no-repeat 95% center;
    background-position-x: 85%;
  }
  
  select.form-control:active, select.form-control:focus,
  select.react-datetime-picker__inputGroup__amPm:active, select.react-datetime-picker__inputGroup__amPm:focus {
    background: url(../../images/select-arrow-green-up.png) no-repeat 95% center !important;  
  }
  
  #hourfilter:active, #hourfilter:focus {
    background: url(../../images/select-arrow-gray-up.png) no-repeat 95% center !important;
  }
  
  #hourfilter, #hourfilter:active, #hourfilter:focus, select.form-control:active, select.form-control:focus,
  select.react-datetime-picker__inputGroup__amPm:active, select.react-datetime-picker__inputGroup__amPm:focus,
  select.form-control {
    background-size: 14px 9px !important;
  }
  
  #hourfilter:active, #hourfilter:focus {
    background-position-x: 85%;
  }
  
  /* react date time picker style reset */
  .react-datetime-picker__wrapper {
    border: none;
  }
  
  select.react-datetime-picker__inputGroup__amPm {
    appearance: none;
    background: url(../../images/select-arrow-green-down.png) no-repeat right center;
    background-size: 14px 9px;
    padding-right: 20px;
  }
  
  .react-datetime-picker__button {
    padding-top: 0;
    padding-bottom: 0;
  }
  
  .react-datetime-picker__inputGroup__input {
    color: #636363
  }
  
  .react-datetime-picker__inputGroup__amPm {
    margin-left: 11px;
  }
  
  .react-datetime-picker__inputGroup__hour {
    margin-left: 5px;
  }
  
  .react-datetime-picker__clear-button {
    display: none;
  }
  
  #hourfilter {
    width: 111px!important;
  }
  
  .react-datetime-picker__inputGroup__leadingZero {
    color:#636363!important;
  }
  
  /* #filter .row{margin:0px;} */
  #filter .filter-row{padding-top:0px;}
  
  .date-range-col{width:320px;}
  
  
  .project-col
  {
  width: 270px;
  margin-right: 20px;
  }
  
  .project-log #date-list-contianer .card .name {
      width: 500px;
  }
  
  .project-log p.time-entered.emp-name, .project-log p.project-name.emp-name {
      width: 350px!important;
  }
  .project-log p.time-entered.emp-name
  {
    width:350px!important;
    padding-left: 0px!important;
  }
  
  .project-log p.emp-project-task
  {
    width:62%!important;
    padding-left: 0px!important;
  }
  .project-log .overall-time {
      margin-bottom: 10px;
      margin-left: 40px;
      font-weight: bold;
      text-align: right;
      margin-right: 20px;
  }
  
  
  .date-range-col .DateInput_input {
      font-weight: normal;
      font-size: 15px;
      line-height: 24px;
      color: #484848;
      background-color: transparent;
      width: 100%;
      padding: 4px 5px;
      border: 0;
      border-top: 0;
      border-right: 0;
      border-bottom: 2px solid transparent;
      border-left: 0;
      border-radius: 0;
      font-size: 14px;
  }
  
  .project-log-filter .date-range-col {
      width: 300px;
  }
  
  .project-log-filter .search-btn
  {
    margin-right:15px;
  }
  
  /**********Media Quries*****************/
  @media only screen and ( max-width: 576px ) {
    #filter .filter-row {
      flex-direction: column;
    }
    #filter .filter-row > div, #filter .react-datetime-picker,
    #filter .search-btn button, #filter .hours select  {
      width: 100% !important
    }
  
    #hourfilter {
      background-position-x: 95%;
    }
  
    /* #filter .filter-label {
      margin-bottom: 5px
    } */
  
    #filter .filter-row > div {
      margin: 0 0 10px 0;
    }
  
    #filter .hours {
      order: 3;
    }
  
    #filter .search-btn {
      order: 4;
    }
  
    #filter .search-btn button,
    #filter .react-datetime-picker {
      border-radius: 5px;
    }
  
    #filter .filter-row > div > p {
      display: none;
    }
  
    .react-datetime-picker__button:enabled {
      margin-right: -6px;
    }
  
    #list-head #date-list-contianer .col-sm-12 {
      padding: 0;
    }
    
  }
  
  @media only screen and ( max-width: 768px ) {
    .filter-row {
      flex-wrap: wrap;
    }
  
    #filter .filter-row .start-date,
    #filter .filter-row .end-date {
      margin-bottom: 15px;
    }
  
  }
  
  @media only screen and ( max-width: 992px ) {
  
  }
  
  .list-filter .filter-wrap
  {
    display: flex;
  }
  
  /* .list-filter .filter-wrap .min-2
  {
    width: auto;
      display: flex;
      padding-right: 20px;
  }
   */
  .list-filter .filter-wrap .device-mobile
  {
    display: flex;
    width: auto;
  }
  
  .health-filter .fliterbox {
    width: 85%;
  }
  
  .health-filter .rbt-input-main{width:200px;}
  .qr-report .rbt{margin-top:20px;margin-right: 30px;width: 300px;}
  .textareabox{font-size:15px;color:#111;}
  .people-error.fliterbox .rbt-input-main
  {
    border:1px solid red;
  }