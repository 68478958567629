.easy_tab_access_task{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    background: #fff;
    border-radius:12px;
    box-shadow: 0px 12px 24px 0px rgba(108,161,206,0.08) !important;

}
.task_full_list{
    flex-direction: column;
}
.task-active-running .single-task.active{
    border-radius: 16px !important;
}
.easy_tab_access_task h1.page-title {
padding: 0 !important;
box-shadow: 0 !important;
color: #212529;
margin: 0 !important;
}
.easy_tab_access_task ul{
    margin: 0;
    padding: 0;
    display: flex;
}
.easy_tab_access_task ul li{
    list-style: none;
    margin-left: 10px;
}
.easy_tab_access_task ul li a{
    padding: 5px;
    color: #212529;
    font-family: 15px;
    font-weight: 500;
    position: relative;
}
.easy_tab_access_task ul li a:hover, .easy_tab_access_task ul li a:focus{
    color: #069482;
}

/* .task-active-running #due-today{
    margin-top: 30px;
} */
#all-tasks .task-start, #all-tasks .task-end{
    opacity: 1 !important;
}
.projects-mytasks .single-task-container-projects.active .task-start{
    display: none;
}
.projects-mytasks .single-task-container .task-btn {
     align-items: flex-start !important;
     justify-content: flex-start !important;
     width:auto !important;
  }
/* .single-task-container .task-btn{
    display: none !important;
} */
.projects-mytasks .single-task-container .task-end{
    display: none;
}
.projects-mytasks .single-task-container-projects.active .task-end{
    display: block;
}
#due-today .head h4{
    padding-top: 30px;
}
.task-project-name .vip{background: #7c45f1; color:#fff; }
.task-project-name .revision{background: rgb(69, 69, 226); color: #fff;}
.task-project-name .minor.revision{background: orange; color: #111;}
.task-project-name .multi-step{background: #1dbc44; color: #fff;}
.task-project-name .gif{background-color: #ff7000; color: #fff;}
.task-project-name .ppt{background-color: #3f99b1; color: #fff;}
.task-project-name .trial{background-color: #9d3fa6; color: #fff;}
.task-project-name .resize{background-color: #cbc434; color: #fff;}
.task-project-name .upgraded{background-color: #3f64a6; color: #fff;}

.internal-seperator{
    width: 1px;
    border-left: 1px solid #ccc;
    display: inline-block;
    margin-left: 10px;
}
@media (max-width:992px) {
    .myassiments_wrapper .task-labels-priorities{
        margin-bottom: 15px;
        align-items: center;
        justify-content: center;
    }
}
@media (max-width:580px) {
.taskcard_footer{
    flex-direction: column;
}
.myassiments_wrapper .task-labels-priorities{
    margin-bottom: 5px;
}
#all-tasks .col-12:first-of-type{
    padding-left: 15px;
    padding-right: 15px;
}
.internal-seperator{
    display: none;
}
.easy_tab_access_task ul li{
    text-align: center;
}
}

.hidden-task-block .single-task-container-projects.single-task .taskcard_footer
{
    pointer-events: none;
    opacity: 0.3!important;
}
.hidden-task-block .single-task-container-projects.single-task.active .taskcard_footer {
    pointer-events:visible;
    opacity: 1!important;
}

.hidden-task-block .single-task-container-projects.single-task.priority-internal-card .taskcard_footer
{
    pointer-events:visible;
    opacity: 1!important;
}