/*
* Loan Summer List 
*/

.loan-summery-list-item {

}

.loan-summery-list-item {
  align-items: center;
}

.loan-summery-list-item p {
  color: #636363 !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 32px !important;
  margin-bottom: 0;
}

.loan-summery-list-item .note {
  background: url(../../../../images/leave_comment_icon.png) no-repeat;
  background-size: cover;
  background-position-x: 0;
  background-position-y: 0;
  width: 17px !important;
  height: 15px !important;
  margin-bottom: 0;
  margin-left: 39px;
  cursor: pointer;
}

.loan-summery-list-item .note:hover {
  background-position-x: 102%;
}

.loan-summery-list-item select option {
  color: #000
}

.loan-summery-list-item .date {
  width: 19.5% !important;
  padding-left: 17px;
}

.admin-summary-list .loan-summery-list-item .description {
  width: 41% !important
}

.loan-summery-list-item .amount {
  width: 10% !important
}


.loan-summery-list-item .type {
  width: 10% !important;
  padding-left: 15px;
}

.admin-summary-list #loan-summery-head .description {
    width: 38%;
}


.admin-summary-list #loan-summery-head .status {
    width: 10%;
}

.admin-summary-list #loan-summery-head .date {
    width: 13%;
}


.admin-summary-list .loan-summery-list-item .date {
    width: 15.5% !important;
}

.admin-summary-list .loan-summery-list-item .description {
    width: 38% !important;
    padding-right:10px;
}

.admin-summary-list .loan-summery-list-item .status {
    width: 10% !important;
}


.admin-summary-list .loan-summery-list-item .blank-note, .admin-summary-list .loan-summery-list-item .blank-type {
    width: 11% !important;
}

/* .loan-summery-list-item .note {
  width: 10% !important
} */

.approve, .reject{cursor: pointer;}

.loan-summery-list-item .description span{
  display:block;
}

.loan-summery-list-item .description span.repay-data{
  color:#000;
  font-weight: bold;
  font-size:14px;
}
#loan-summery-wrapper.admin-summary-list select, #loan-summery-wrapper p.type {
    width: 80px!important;
}
.actions_btn {
  display: flex;
}
.actions_btn div.approve{
  color: #212529;
  font-weight: 600 !important;
  background: #219186;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  min-width: 30px;
  text-align: center;
  line-height: 30px;
  color: #fff;
}
.actions_btn div.reject{
  color: #212529;
  font-weight: 600 !important;
  background: #F93154;
  border-radius: 4px;
  width: 30px;
  height: 30px;
  min-width: 30px;
  text-align: center;
  line-height: 30px;
  color: #fff;
}