/* coutdown */
#countdown_wrapper {
   /* width: 100vw;*/
    height: calc(100vh - 250px );
    display: flex;
    align-items: center;
    justify-content: center;
}

#ki_countdown {
   display: flex;
   align-items: flex-start;
   justify-content: flex-start;
   height: 89vh;
   flex-direction: column-reverse;
}

#countdown_wrapper .LoadingBox {
    width: 580px !important;
    height: 445px !important;
}

/* #ki_countdown > div {
    height: 50%;
    justify-content: center;
    text-align: left;
    padding-left: 70px;
    position: relative;
    flex-direction: column;
} */

/* .actual_subs:after {
    content: '';
    width: 60%;
    height: 1px;
    background: #dbdcdc;
    bottom: 0;
    left: 0;
    display: block;
    position: absolute;
} */

#ki_countdown p {
    font-size: 32px;
    margin-bottom: 15px;
    color: #219186;
    line-height: 31px;
    font-weight: 400;
}

#ki_countdown h3 {
    margin: 0;
    font-size: 8em;
    font-weight: 600;
    color: #1f846f;
    line-height: 1;
    padding: 0 0 15px 0;

}
.ki_countdown_img{
    width: 85%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}
.monky_img{
    display: flex;
    align-items: center;
    justify-content: center; 
    height: 89vh;
}
/* .ki_countdown_img {
    position: absolute;
    top: -46px;
    right: -53%;
    max-width: 740px;
} */

#ki_countdown .actual_subs.greater{
    height:100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.actual_subs.greater:after
{
    background:none;
}
@media only screen and ( max-width: 576px ) {
    #countdown_wrapper {
        height: 100%;
    }

    .ki_countdown_img {
        display: none;
    }

    #ki_countdown {
        width: 100%;        
    }

    #ki_countdown > div {
        padding: 0 5%;
        text-align: center;
    }

    #ki_countdown h3 {
        font-size: 50px;
    }

    #ki_countdown p {
        font-size: 23px;
        line-height: 25px;
    }

    .actual_subs:after {
        width: 100%;
    }

    #countdown_wrapper #LoadingWrapper {
        width: 80%;
    }
}