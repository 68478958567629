.members_user_card h1.card-title{
    color: #212529;
    font-weight: 600;
    text-align: left !important;
    font-size: 20px;
    padding: 30px;
    margin: 0;
}
.members_user_card{
    border-radius: 12px;
}
.edit_save_member_box{
    display: flex;
    align-items: center;
   padding-left: 15px;
}
.edit_save_member_box .save-team-member{
    margin-right: 15px;
    width: 40px;
    height: 40px;
    padding: 10px;
    border-radius: 8px;
    background: #E6FAF8;
    cursor: pointer;
}
.filter-team-management{
    margin-bottom: 25px !important;
}
.theme_selectbox{
    position: relative;
    border: 1px solid #ADB5BD !important;
    min-height: 49px;
    border-radius: 8px !important;
    padding-left: 15px !important;
    position: relative;
}
.edit-team-member{
    width: 40px;
    height: 40px;
    border-radius: 8px;
    background: #E6FAF8;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.edit-team-member .material-icons{
    color: #119381;
}
.team-view-list tbody tr td{
    vertical-align: middle;
}