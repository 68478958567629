.cl_helth_report{
   border-radius: 16px;
   margin-bottom: 50px;
}
.outer-wrapper .filter-part{
    margin:0 15px 15px 15px;
}
.her_title{
    padding:30px;
    margin: 0 !important;
    font-weight: 600;
    font-family: 'Poppins', sans-serif !important;
    text-align: left !important;
    color: #212529 !important;
    font-size: 20px;
    letter-spacing: 0.00938em;
}
.health_rpot_header {
    background: #EDEDED;
    min-height: 50px;
    align-items: center;
    display: flex;
}
.health_rpot_header .row{
    width: 100%;
}
.report-tab-head {
    font-size: 16px;
    color: #212529;
    font-weight: 700;
    display: block;
    font-family: 'Montserrat', sans-serif;
}
.inner-row-wraper {
    display: flex;
    align-items: center;
    max-width: 1530px;
    width: 100%;
    margin: 0 auto;
}
.inner-row-wraper-for-list{
    max-width: 1530px;
    width: 100%;
    margin: 0 auto;
    min-height: 70px;
    display: flex;
    align-items: center;
    position: relative;
}
a.report-tab-list {
    font-size: 14px;
    font-weight: 400;
    color: #212529;
    letter-spacing: 0.01071em;
}
.inner-row-wraper-for-list:after {
    content: "";
    position: absolute;
    max-width: 1060px;
    width: 97%;
    margin: 0 auto;
    height: 1px;
    background: #CDD3DB;
    left: 15px;
    right: 15px;
    bottom: 0;
}
.datepik{
    width: 100%;
    border: 1px solid #e8e8e8;
    min-height: 36px;
    line-height: 25px;
    outline: none;
    border-radius: 5px;
}
.comd_icon{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.status_icon{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    
}
.comd_icon .material-icons{
    background: #DDFFEB;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #00B74A;
}
.status_icon .material-icons{
    background: #FFF8EA;
    width: 40px;
    height: 40px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFA900;
}
.comd_icon img{
    width: 21px;
    height: 21px;
    cursor: pointer;
}
.status_icon img{
    width: 21px;
    height: 21px;
    cursor: pointer;
}
.health-overlay{
    position: fixed;
    background: rgb(33 37 41 / 16%);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
    height: 100%;
}
.health-comments{
    position: fixed;
    z-index: 99999;
    width: 100%;
    max-width: 600px;
    max-height: 560px;
    height: 100%;
    background: #fff;
    padding: 15px;
    margin: 0 auto 50px auto;
    left: 0;
    right: 0;
    top: 10%;
    bottom: 0%;
    transform: translate(0%, 0%);
    border-radius: 8px;
    padding: 30px;
}

.health-comments .star-rating-given{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 60px;
}

.health-comments .health-comment-field{
    margin-top: 10px;
}

.health-comments .health-comment-field input{
    border-radius: 8px;
    border:1px solid #ADB5BD;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 4px;
    min-height: 48px;
}
.health-comments .health-comment-field input::placeholder{
    color: #ADB5BD;
    font-weight: 400;
    font-size: 14px;
}

.health-comments .ql-toolbar.ql-snow{
    border-bottom: 0;
}
.health-comments .ql-container.ql-snow{
    border: 1px solid #ADB5BD !important;
    border-radius: 8px;
    margin-bottom: 13px;
}
.health-comments .ql-editor{
    background: #fff;
    margin: 0;
    height: 120px;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 8px;
    min-height: 120px !important;
    max-height: 120px;
}
.health-comments .ql-editor.ql-blank::before{
    left: 0;
    right: 0;
    font-style: normal;
    font-size: 14px;
    color:#ADB5BD;
}
.health-comments .ql-editor::placeholder{
    padding-left: 15px;
}
button.add_cmd_btn {
    border: none;
    float: right;
    background: #219186;
    border-radius: 8px;
    max-width: 200px;
    width: 100%;
    height: 48px;
    margin-top: 15px;
    font-size: 14px;
    color: #fff;
    font-weight: 600;
    transition: all .7s ease-in-out;
    outline: none;
    font-family: 'Poppins', sans-serif !important;
}
.ql-editor.ql-blank::before{
    padding:0 15px ;
}
.closebtn{
    position: absolute;
    right: 15px;
    background: #F4F6F9;
    border-radius: 8px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    top: 15px;
}
.closebtn img{
    width: 8px;
}
.health-comments-edit .comment_content {
    padding: 20px 0;
    border-top: 1px solid #CDD3DB;
    height: 100px;
    overflow: scroll;
    overflow-x: hidden;
}
.no-active-shift{margin: 20px 0px;font-weight: bold; font-size: 14px;}
.health-comments-edit .ql-toolbar.ql-snow{
    font-family: 'Poppins', sans-serif !important;
    border: 0;
}

@media screen and (max-width: 1200px) {
    .report-tab-head{
        font-size: 14px;
    }
    .star-ratings svg{
        width: 23px !important;
    }
    
    }
    @media screen and (max-width: 768px) {
        .page-title {
           margin: 20px 0 20px 0px !important;
        }
        .fliterbox {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        #filter .filter-row .start-date{
            margin-bottom: 0;
        }
        .list-filter{
            align-self: center;
            justify-content: center;
            align-items: center;
            display: flex;
            clear: both;
            float: left;
            width: 100%;
            margin-top: 20px;
        }
    }
    @media screen and (max-width: 600px) {
        .DateInput{
            width: 80px;
        }
        .availability-records #filter .search-btn button {
            height: 29px;
            width: 50px;
            padding: 0px;
            font-size: 14px;
            padding: 9px;
            line-height: 15px;
        }
        #LoadingWrapper{
            overflow-x: scroll;
            overflow-y: hidden;
        }
        .availability-records .log-head-items {
            padding: 10px 10px;
            width: 1000px;
            position: relative;
        }
        .availability-records .log-body-items {
             width: 1000px;
             position: relative;
        }
    .fixtab{
        position: sticky;
        left: -2px;
        top: 0;
        background: #fff;
        width: 144px;
        z-index: 99;
     
     }

     .fixtab::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 42px;
    top: -10px;
        right: 0;

    }

}
    @media screen and (max-width: 480px) {
    #filter .filter-row{
        padding: 0;
    }
    .DateInput {
        width: 90px;
    }
    .availability-records #filter .search-btn button{
        padding: 7px;
    }
    .availability-records #filter .search-btn button{
        padding: 5px;
    }
    #LoadingWrapper{
        overflow-x: scroll;
        overflow-y: hidden;
    }
    .availability-records .log-head-items {
        padding: 10px 10px;
        width: 1000px;
        position: relative;
    }
    .availability-records .log-body-items {
         width: 1000px;
         position: relative;
    }
.fixtab{
    position: sticky;
    left: -2px;
    top: 0;
    background: #fff;
    width: 130px;
    z-index: 99;
  
}
.fixtab::after {
    position: absolute;
    content: "";
    width: 2px;
    height: 42px;
    background: #f7f8f8;
    top: -10px;
    right: 0;

}
.availability-records #filter .search-btn button {
    height: 30px;
    width: 30px!important;
    padding: 0px;
}
}

#LoadingWrapper::-webkit-scrollbar {
    width: 1px;
    height: 5px;
  }
  
  
#LoadingWrapper::-webkit-scrollbar-thumb {
  background-color: #f4f4f4;
  outline: 1px solid #fff;
}
input.delayed-message
{
    cursor: pointer;
}
.health_rpot_header.pl_ratio_header
{
    margin-top:20px;
}
.client-ratio-outer-wrapper .inner-row-wraper-for-list {
    min-height: 50px;
}
.theme_table > thead{
    background: #F0F6FA;
    border-bottom: 1px solid #DEE2E6;
  

} 
.theme_table thead th:first-child{
    padding-left: 30px;
}
.theme_table thead th:last-child{
    padding-right: 30px;
    text-align: right;
}
.theme_table thead th{
    border-bottom: 0 !important;
    border-top: 0 !important;
    min-height: 60px;
    color: #6C757D;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif !important;
    letter-spacing: 0.01071em;
    border: 0 !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.theme_table > thead > tr{
    min-height: 66px;
    vertical-align: middle;
    line-height: 38px;
}

.theme_table tbody  th{
    border: 0 !important;
    letter-spacing: 0.01071em;
    display: table-cell;
    vertical-align: inherit;
}
.theme_table tbody  td:first-child{
    padding-left: 30px;
}
.theme_table tbody td:last-child{
    padding-right: 30px;
}
.theme_table tbody  td{
    color: #212529;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Poppins', sans-serif !important;
    border: 0 !important;
    padding-top: 25px;
    padding-bottom: 25px;
  
}
.theme_table > tbody > tr{
    position: relative;
}
.theme_table > tbody > tr::after{
    content: "";
    position: absolute;
    width: auto;
    height: 1px;
    background: #DEE2E6;
    bottom: 0;
    z-index: 90;
    left: 30px;
    right: 30px;
}
.theme_table tbody + tbody{
    border: 0 !important;
}

.clienthealthreport .health-comments-edit .comment_content {
    height: auto;
    overflow: hidden;
    overflow-x: hidden;
}
.health-trello
{
    display: grid;
    margin-bottom: 20px;
}
.health-trello span{
    font-weight: bold;
    margin-bottom:10px;
}