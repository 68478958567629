/* LeaveBalance */
.leave_bal_header{
background: #F0F6FA;
min-height: 65px;
display: flex;
justify-content: center;
position: relative;
z-index: 1;
font-size: 15px;
border-bottom: 1px solid #DEE2E6 !important;
color: #6C757D;
font-weight: 600;
border-radius: 0;
font-family: 'Poppins', sans-serif !important;
padding-left: 30px;
padding-right: 30px;
}

.le_bal_sheet{
  min-height: 45px;
  align-items: center;
  font-size: 14px;
  border-bottom: 1px solid #eef7f6;
}
.leav_titile{

  color: #069482;
}
#leave_balence .theme_table tbody tr{
  position: relative;
}
#leave_balence .theme_table tbody tr::after {
  content: "";
  position: absolute;
  width: auto;
  height: 1px;
  background: #DEE2E6;
  bottom: 0;
  z-index: 80;
  left: 30px;
  right: 30px;
}
#leave_balence .theme_table tbody tr td:first-child{
color: #212529;
font-size: 15px;
font-weight: 600;
}
#leave_balence .theme_table tbody tr td:last-child{
  text-align: right;
  }
.balance-wrapper #leave_balence {
  padding:0 50px;
  overflow-x: hidden;
  padding-top: 25px;
}
#leave_balence {
  background: #fff;
  overflow: visible;
  margin-bottom: 30px;
}

.all-leaves {
  padding: 40px 0 0px 0;
  flex-wrap: wrap;
}

@media only screen and ( max-width: 576px ) {
  #leave_balence {
    padding: 3px;
  }

  .all-leaves {
    padding: 0;
  }

  .leave-balence-card {
    margin: 8px !important;
    width: 44% !important;
    padding: 18px 0;
  }

  #leave_balence {
    background: #dfdfdf;
  }

  .upcoming_company_holiday {
    flex-direction: column !important;
	  margin: 8px !important;
  }

  .upcoming_company_holiday > div {
    margin: 0 !important;
    padding: 15px 0;
    border-bottom: 1px solid #dfdfdf !important;
  }

  #leave_balence .title {
    padding: 25px 0;
  }

  .upcoming_company_holiday > div:last-child {
    border-bottom: none !important
  }

  #leave_header p {
    font-size: 15px
  }
}

#leave_balence .leave-note
{
  margin: 0 !important;
  padding: 30px 30px 0 30px; 
  text-align: left;
  height: auto !important;
}
#leave_balence .leave-note h3{
  color: #212529;
  font-size: 18px;
  margin: 0;
  padding: 0 0 8px 0;
  font-weight: 600;
}
#leave_balence .leave-note p{
  color: #6C757D;
  line-height: 32px;
  font-size: 15px;
  margin-bottom: 0;
}
#leave_balence .theme_table tbody tr td:last-child {
  text-align: left;
  padding-left: 19px;
}
