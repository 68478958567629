/* 
    Name: SwitchUser
    Path: components/SwitchUser
*/

.switchUser .date-col {
    width: 100%
}

/* .switchUser .date-col .form-control, .switchUser .date-col > p{
    width: 400px !important;
    margin: 0 auto;
    display: block;
    text-align: center
} */

.switch_user_wrapper {
}

#filter .filter-label {
    margin-top: 30px;
    margin-bottom: 10px;
}

.switchUser.teamholidays .date-col .rbt
{
    width: 100%;
    margin: 0 auto;
}

.switchUser.teamholidays .date-col .rbt.holidayerror
{
    border:1px solid red;
}

.switchUser.teamholidays .date-col .form-control, .switchUser.teamholidays .date-col > p
{
    width:100%!important;
}
.switchUser.teamholidays{
    margin-bottom: 30px !important;
}
.teamholidays .ms-container {
    width: 80%;
    margin: 0;
    background: #fff;
    padding: 0;
    border-radius: 8px;
}
.ms-selectionpanel2
{
    display: none;
}

.ms-selectionpanel
{
    padding-left:5%;
}
.action-box{
    margin-bottom: 50px;
    width:80%;
     height: 150px;
}
.action-box .btn-green{
    background: #219186;
    color: #fff;
    max-width: 200px;
    width: 100%;
    font-weight: 600;
    min-height: 50px;
    border-radius: 8px;
    border: 1px solid #219186;
    font-size: 16px;
}
.action-box .btn-green:hover, .action-box .btn-green:focus {
    background:#004c3f !important;
    border: 1px solid #004c3f !important;
    color: #fff;
}
.confirm-nortice
{
    margin-bottom: 15px;
    text-align: center;
    font-size: 15px;
    color: #1aac8f;
    font-weight: 700;
}

.team-holiday-approve .btn-green {
    background: #069482;
    border: 1px solid #069482;
    color: #fff;
    padding: 7px 50px;
}

.team-holiday-approve .btn-gray {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
    padding: 7px 50px;
}
.mr-20
{
    margin-right: 20px;
}
.total-emp-leave .head-table{padding:15px;  font-weight: bold;font-size:15px;}
.total-emp-leave .body-table{padding:15px; margin-top:5px}
.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding: 50px;
    font-size: 20px;
    z-index:9999;
  }
  .total-emp-leave .text-bold, .total-emp-leave .text-inner-bold{font-weight: 700;}
  .total-emp-leave .filter-box{margin-bottom:30px;}
  .total-emp-leave .rbt-menu{z-index: 9999999;}
@media only screen and (min-width: 992px)
{
    .hidden-md
    {
        display: none;
    }
}

@media only screen and (max-width: 576px){
    .body-table .text-bold{width: 30%;}
    .total-emp-leave .filter-box .people{width: 100%;}
    .hidden-xs
    {
        display: none;
    }
    .switch_user_wrapper {
    width: 100%;
	}
    #filter .switch_user_wrapper .people select,  #filter .switch_user_wrapper .people,  #filter .switch_user_wrapper .people p{width:100%!important;}

    .switchUser.teamholidays .date-col .form-control, .switchUser.teamholidays .date-col > p
    {
        width:99%!important;
    }

.teamholidays .ms-container {
    width: 99%;
    }
    .ms-selectionpanel
    {
        padding-left:0%;
    }
    .mr-20
    {
        margin-right: 0px;
    }
}	

.cfm-message{
    margin-top: 60px;
    font-size: 18px;
}
.ms-selectable .search-input, .ms-selection .search-input{
    border: 1px solid #ADB5BD;
    min-height: 57px;
    border-radius: 8px !important;
    padding-left: 25px;
    padding-right: 25px;
    font-size: 15px;
    font-weight: 400;
    color: #495057;
    position: relative;
    background: #fff;
  }
  .ms-selectable{
      position: relative;
  }
.ms-selectable::before{
    position: absolute;
    content: "";
    width: 93%;
    height: 1px;
    top: 0;
    background: #adb5bd;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;
    top: 50px;
  }
.ms-selection::before{
    position: absolute;
    content: "";
    width: 93%;
    height: 1px;
    top: 0;
    background: #adb5bd;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 1;
    top: 50px;
  }
  .ms-container .ms-selection{
      position: relative;
  }
  .ms-container .ms-list{
    border-color: #ADB5BD !important;
    -webkit-box-shadow: 0px 12px 24px 0px rgba(230,239,250,0.08);
    -moz-box-shadow: 0px 12px 24px 0px rgba(230,239,250,0.08);
    box-shadow: 0px 12px 24px 0px rgba(230,239,250,0.08);
    margin-top: -13px;
    background: #fff !important;
    border-top: 0;
    border-radius: 0 0 8px 8px;
    position: relative;
    padding-top: 10px;
   
  }
  .ms-container .ms-selectable li.ms-elem-selectable, .ms-container .ms-selection li.ms-elem-selection{
      font-size: 16px;
      color: #212529;
      padding-left: 25px;
      padding-right: 25px;
      background: #fff;
  }
  .ms-container .ms-selectable li:hover, .ms-container .ms-selection li:hover {
    cursor: pointer;
    color: #212529;
    text-decoration: none;
    background-color: #F3FAFF;
  }
  .ms-container .ms-selectable li.selected, .ms-container .ms-selection li.selected {
    color: #212529;
    background-color: #F3FAFF !important;
    border-bottom: 1px #F3FAFF solid !important;
  }
  .ms-selectionpanel button{
    width: 44px;
    height: 44px;
    padding: 5px 7px;
    border-radius: 8px;
    background:#EFF5F9;
    border: 0;
    box-shadow: none;
  }
  .ms-selectionpanel button:hover,   .ms-selectionpanel button:focus{
      background: #055949 !important;
      color: #fff !important;
  }