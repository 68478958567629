.msg-top
{
    text-align: center;
    background: #E6FAF8;
    padding: 15px ;
    margin: 0 0 10px 0 !important;
    border-radius: 8px !important;
    width: 100% !important;
    
}
.msg-top p
{
padding:0px;margin: 0px; color:#219186;
}

.msg-top.shift-error {
    background: #ff1800;
    text-align: center;
}