/* Feeds */

/* feeds column */
#all_feeds {
  /* background: #f5f7f7; */
  /* padding: 19px; */
  background: #fff;
  padding: 30px;
  border-radius: 16px;
}

#all_feeds .LoadingBox.LoadingBoxSmall {
  height: 50px;
}

.feed-wrapper > .row > div, .feed-wrapper > .row {
  position: relative;
}

.feed-item {
  background-color: #ffffff;
  margin-top: 30px;
  border-bottom: 1px solid #DEE2E6 !important;
  padding-bottom: 10px;
  border-radius: 0 !important;

}

/* Feeds Banner */
.feed-banner > .card {
  /* padding: 0 40px 40px 40px; */
  padding: 0 40px 19px 40px;
  background: #fff url(../../images/feeds-banner-bg.png) no-repeat center;
  background-size: 90% auto;
}

.feed-post-date {
  padding: 16px 0;
  margin: 0 !important;
}

.feed-content-banner .feed-post-date, .feed-post-date {
 font-weight:600;
font-size: 16px;
 color: #ADB5BD
}

.feed-banner .content {
  width: 70%;
	padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.feed-banner-leaf {
  width: 15% !important;
  /* width: 13%; */
}

.feed-content-banner .content {
	padding: 0 74px 10px 74px;
}

.feed-content-banner .feed-post-date {
  font-weight: 600;
  font-size: 16px;
  color: #ADB5BD;
  margin: 0;
  padding: 16px 0;
}

.feed-content-banner h4, .feed-item-wish h2 {
  margin-bottom: 20px;
  font-size: 32px;

  color: #069482;
}

.feed-content-banner .content .msg {
  color: #636363;
  font-size: 24px;
}

.feed-content-banner p em {
  font-weight: 600;
}

.feed-banner h2, .feed-banner h3, .feed-item-wish h3 {
  font-size: 20px;
  color: #636363;
  font-weight: 600;
}

.feed-banner h3 {
  letter-spacing: 7px;
  font-weight: 500;
}

.feed-banner h2 strong {
  font-weight: 600;
}

.feed-banner h1 {
  line-height: 56px;
	font-size: 73px;
  color: #069482;
  font-weight: 700;
}

/* Feeds Wishes */
.feed-item-wish .content {
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.feed-item-wish .content > p {
  margin: 0;
  padding: 16px 0;
}

.feed-item-wish h3 {
 text-transform: capitalize;
}

.feed-item-wish h2 {
  font-weight: 500;
  margin-bottom: 0;
}

/* Feeds Anniversary */
.feed-item-anniversary h2 {
  margin-bottom: 17px
}

/* feeds sidebar */
/* #feed-sidebar {
  margin: 0 25px;
} */

/* .feed-sidebar-wrapper {
  background-color: #dfdfdf;
} */

#feed-sidebar {
  transition: all 0.2s cubic-bezier(.25,.28,.75,1.19)
}

#feed-sidebar.sticky {
  width: 395px;
  position: fixed;
  top: 25px;
  /* transition: all 1s cubic-bezier(.25,.28,.75,1.19) */
}

#feed-sidebar .card {
  padding: 26px;
  background: #fff;
  box-shadow: 12.287px 8.604px 25px 0px rgba(179, 179, 179, 0.1);
  margin-bottom: 20px;
}

#feed-sidebar p {
  margin-bottom: 0;
}

.feed-sidebar-title, .feed-sidebar-up-levs-cont h5.feed-sidebar-title {
  font-size: 18px;
  color: #636363;
  font-weight: 600;
}

.feed-sidebar-title {
  margin-bottom: 6px
}

.feed-sidebar-quote .message {
  /* max-width: 70%; */
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 10px !important;
	font-size: 14px !important;
  font-weight: 400 !important;
}

.feed-sidebar-quote .author,
.feed-sidebar-up-levs-cont .feed-sidebar-title  {
  font-size: 14px;
  color: #bdbdbd
}

.feed-sidebar-up-levs-cont .scroll-container {
  max-height: 220px;
  overflow: hidden;
  overflow-y: scroll;
}

.feeds-siddbar-subs-num {
  font-size: 28px;
  color: rgb(6, 148, 131);
  font-weight: 600;
  line-height: 0.857;  
}

.feed-sidebar-subs-diff-cont > .card {
  width: 50%;
}

.feed-sidebar-subs-diff-cont > .card p {
  margin-bottom: 0;
}

.feed-sidebar-subs-diff-cont > .card:first-child {
  margin-right: 10px;
}

.feed-sidebar-subs-diff-cont > .card:last-child {
  margin-left: 10px;
}

.feed-sidebar-up-levs-cont .cont {
  flex-wrap: wrap;
  /* justify-content: center; */
  justify-content: left;
  width: 100%;
}

.feed-sidebar-up-levs-cont .cont > div {
  width: 50%;
}

.bds-person {
  margin-bottom: 25px;
}
.bds-person .user_pic{
  overflow-x: initial !important;
}
.bds-person .user_pic img{
  min-height: 40px;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  overflow: hidden;
  display: block;
}


.bds-person .bds-person-date {
  width: 100%;
  height: 20px;
  line-height: 20px;
  margin-top: 0;
  font-size: 12px;
  text-align: left !important;
  padding-left: 0;
  color: #6C757D;
}

.feed-sidebar-up-bds-cont {
  padding-bottom: 5px;
  max-height: 155px;
  max-height: 250px;
}

.feed-sidebar-up-levs {
  margin-bottom: 12px;
}
.feed-sidebar-up-levs .box_titile{
  display: flex;
  align-items: center;
  color: #495057 !important;
  font-size: 15px !important;
  font-weight: 600 !important;
}
.feed-sidebar-up-levs  p{
  color: #212529 ;
  font-size: 14px ;
  margin-top: 5px ;
}
.feed-sidebar-up-levs .box_titile img{
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

.feed-sidebar-up-levs:nth-child(3),
.feed-sidebar-up-levs:nth-child(4) {
  margin-bottom: 0;
}

.feed-sidebar-up-levs-cont .cont > div:nth-child(odd), .bds-person:nth-child(odd) {
  /* border-right: 1px solid #f8f7f7; */
  width: 50%
}

.feed-sidebar-up-levs-cont .cont > div:nth-child(even), .bds-person:nth-child(even) {
  /* padding-left: 25px; */
  width: 50%;
}

.feed-sidebar-up-levs-cont .feed-sidebar-title {
  padding-right: 32px;
}

.feed-sidebar-up-levs-cont .cont > .v-line,
.bds-person-cont .v-line {
  width: 20%;
}

.bds-person-cont .d-flex p:first-of-type {
  color: #212529;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 110px;
  font-weight: 500;
}

.bds-person-cont {
  flex-wrap: wrap;
}
.feed-sidebar-up-levs-cont{
  border-radius: 16px;
  padding: 30px !important;
  -webkit-box-shadow: 0px 12px 24px 0px rgba(108,161,206,0.08) !important;
  -moz-box-shadow: 0px 12px 24px 0px rgba(108,161,206,0.08) !important;
  box-shadow: 0px 12px 24px 0px rgba(108,161,206,0.08) !important;
}
.feed-sidebar-up-levs-cont h5.feed-sidebar-title , .bday_card h6.feed-sidebar-up-bds-title{
  color: #212529;
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 20px;
  text-align: left !important;
  margin: 0 !important;
}
.feed-sidebar-up-levs-cont p {
  margin-bottom: 5px;
}

.feed-sidebar-up-levs-cont .feed-sidebar-title {
  margin-bottom: 10px;
  font-weight: 400
}

.feed-sidebar-up-levs-cont .v-line span,
.bds-person-cont .v-line span{
  width: 1px;
  min-height: 100%;
  background: #f8f7f7;
  margin: 0 40px;
  display: block;
  margin: 0 auto;
}


.feed-sidebar-up-bds-cont {
  padding-left: 65px;
  padding-right: 65px;
}

.feed-sidebar-up-bds-cont img {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.bds-person > div {
  align-items: center;
}

.bds-person > div p {
  margin-bottom: 0;
  font-size: 16px;
}

.loadmore_post {
  margin: 30px auto 0 auto;
  text-align: center;
  display: block;
  max-width: 200px;
  width: 100%;
  min-height: 50px;
  border-radius: 8px;
  letter-spacing: 0;
  color: #FFFFFF;
  font-size: 16px;
  font-weight: 500;
}
.loadmore_post:hover{
  background: #177168;
  color: #fff;
}
#st1{
  width: 35px;
  height: 35px;
  display: none;
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 15px;
  background: #069482 url(https://image.flaticon.com/icons/svg/271/271228.svg) no-repeat center;
  transform: rotate(270deg);
  background-size: 45% auto;
  cursor: pointer;
  border: 1px solid #069482;
}

#st1:hover {
  background-color: white;
  border: 1px solid #069482;
  box-shadow: -3px 0 10px 5px rgba(0,0,0,0.05)
}

.showtop{display: block!important;}


/* Feed Waiting for review */
.feed_appr_wrapper {
  width: 100%;
  background: #fff;
  margin-bottom: 0;
  height: 100%;
  line-height: 30px;
  padding-left: 15px;
  font-size: 14px;
  letter-spacing: 0.02em;
  padding: 17px 12px;
  border-top: 1px solid #ebebeb;
  align-items: center;
}

.feed_appr_wrapper > div,
.feed_appr_wrapper > h6,
.feed_appr_btns > button {
  width: 50%;
}

.feed_appr_btns > button {
  padding-left: 36px;
  background-size: 26px 26px !important;
  background-repeat: no-repeat !important;
  background-position: left center !important;
  padding-top: 0;
	padding-bottom: 0;
}

.feed_appr_btns > .feed_approve {
  background: url(./assets/approve_icon.png);
  position: relative;
}

.feed_appr_btns > .feed_approve:after {
  content: '';
	width: 1px;
	height: 20px;
	display: block;
	background: #ebebeb;
	position: absolute;
	right: 9px;
	top: 3px;
}

.feed_appr_btns > .feed_reject {
  background: url(./assets/reject_icon.png);
  padding-left: 62px;
  background-position-x: 28px !important;
}

.feed_appr_wrapper .feed-waiting-for-review {
  margin-bottom: 0;
  padding-left: 35px;
  padding-left: 36px;
  background: url(./assets/loading-icon.png) no-repeat left center;
  background-size: 26px 26px !important;
  margin-left: 14px;
}

.waiting_for_review_wrapper,
.waiting_for_approve_wrapper {
  margin-bottom: 20px;
}

.waiting-for-approve-wrapper #feed_general,
.waiting_for_review_wrapper #feed_general{
  margin-bottom: 0;
}

.waiting_for_approve_wrapper #feed_general {
  margin-bottom: 0;
}

.feed-sidebar-subs-diff-cont .card
{
  padding: 20px!important;
}

/* .waiting_for_approve_wrapper  .btn-green {
  margin-bottom: 20px;
} */

.bday_card{
  border-radius: 16px;
  padding: 30px !important;
  box-shadow: 0px 12px 24px 0px rgba(108,161,206,0.08) !important;
}
.small_card_box{
  border-radius: 16px;
  padding: 30px !important;
  box-shadow: 0px 12px 24px 0px rgba(108,161,206,0.08) !important;
  background: #fff;
  margin-bottom: 20px;
}
.small_card_box h6{
  font-size: 25px;
  color: #069482;
  padding-bottom: 5px;
  font-weight: 600;
  margin: 0;
}
.small_card_box h4{
  font-size: 18px;
  color:#495057;
  font-weight: 400;
  margin: 0;
}
@media (max-width:1300px){
  .feed-banner h1{
    font-size: 50px;
  }
  .feed-banner h2, .feed-banner h3, .feed-item-wish h3{
    font-size: 16px;
  }
  .small_card_box h4{
    font-size: 16px;
  }
  .feed-item-wish .content{
    background-size: 113% !important;
    background-repeat: no-repeat !important;
  }
}