/* 
  Name: Typography 
  Root: components/styles/Typography
*/

.text-caps {
  text-transform: uppercase;
}

.d-flex-middle {
  justify-content: center !important;
}

.d-flex-col {
  flex-direction: column !important;
}