/**
 * Login Portal Section
*/
.main_banner{
  position: relative;
  background:#22958c url(../../images/main_banner.jpg) no-repeat;
  background-size: cover;
  background-position: 60% 0px;
  min-height: 360px;
  border-radius: 12px;
  overflow: hidden;
  padding: 50px;
}

.main_banner img{
  width: 100%;
}
.quote-box-inner{
  max-width: 600px;
  width: 100%;
}
.quote-box-inner h3 {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  margin: 0;
  padding: 0;
  letter-spacing: .8px;
}
.quote-box-inner p {
  color: #CDFFFA;
  font-size: 18px;
  line-height: 28px;
  margin: 0;
  padding: 0;
  padding-right: 40%;
  font-weight: 300;
  letter-spacing: .8px;
  padding: 12px 0 12px 0;
}
.quote_onwer {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}

.white_bg_btn{
  max-width: 200px;
  width: 100%;
  border: none;
  background: #fff !important;
  color: #219186 !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  margin:25px 0 15px 0!important;
  transition: all .5s ease-in-out !important;
  min-height: 48px;
  letter-spacing: .6px;
  -webkit-box-shadow: 0px 3px 16px 0px rgba(14,131,120,1) !important;
  -moz-box-shadow: 0px 3px 16px 0px rgba(14,131,120,1) !important;
  box-shadow: 0px 3px 16px 0px rgba(14,131,120,1) !important;
  border-radius: 8px !important;
}
button.white_bg_btn:hover, button.white_bg_btn:focus{
  background: #fff !important;
  color: #219186 !important;
}
button.restart_btn{
  max-width: 200px;
  width: 100% !important;
  border: none;
  background: #000 !important;
  border-color: #000 !important;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  text-transform: capitalize !important;
  margin:25px 0 15px 0!important;
  transition: all .5s ease-in-out !important;
  min-height: 48px;
  letter-spacing: .6px;
  -webkit-box-shadow: 0px 3px 16px 0px rgba(14,131,120,1) !important;
  -moz-box-shadow: 0px 3px 16px 0px rgba(14,131,120,1) !important;
  box-shadow: 0px 3px 16px 0px rgba(14,131,120,1) !important;
  border-radius: 8px !important;
}
.total_log{
  display: flex;
  padding-top: 30px;
}
.total_log p{
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  margin-right: 30px;
}
.total_log p span{
  font-weight: 500;
}

.break_commen_box{
  padding: 30px;
  border-radius:16px !important;
  background: #fff;
  min-height: 235px;
  margin-top: 30px;
}
.card_header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.reg_br_icon{
  width: 48px;
  height: 48px;
  background-color: #E6FAF8;
  border-radius: 12px;
  background-image: url(../../images/reg_break_icon.svg);
  background-position:12px 12px;
  background-repeat: no-repeat;
  transition: all .5s ease-in-out;
}
/* .break_commen_box:hover .reg_br_icon{
  background-image: url(../../images/reg_break_icon.svg);
  background-color: #219186;
  background-position:-35px 12px;
} */
.card_content {
  margin-top: 15px;
}
.card_content h4{
  color: #212529;
  font-size: 1rem;
  margin: 0;
  font-weight: 500;
  padding-bottom: 8px;
}
.small_discr{
  color: #6C757D;
  font-size: 14px !important;
  line-height: 24px;
  padding-bottom: 10px;
  font-weight: 400 !important;
  min-height: 70px;
}
.start_b{
  background-color: #E6FAF8 !important;
  color:#219186 !important;
  font-size: 0.875rem !important;
  font-weight: 500 !important;
  margin: 10px 0 0 0 !important;
  padding: 10px 20px !important;
  border-radius: 8px !important;
  text-transform: capitalize !important;
  max-width: 160px;
  width: 100%;
  transition: all .5s ease-in-out;
  border: none;
}
.start_b:hover{
  background: #219186 !important;
  color:#E6FAF8 !important;
}

.emer_br_icon{
  width: 48px;
  height: 48px;
  background-color: #E6FAF8;
  border-radius: 12px;
  background-image: url(../../images/em_break_icon.svg);
  background-position:12px 12px;
  background-repeat: no-repeat;
  transition: all .5s ease-in-out;
}
/* .break_commen_box:hover .emer_br_icon{
  background-image: url(../../images/em_break_icon.svg);
  background-color: #219186;
  background-position:-35px 12px;
} */
.card_content ul li {
  margin: 0;
  padding: 15px 0 15px 0;
  border-bottom: 1px solid #DEE2E6;
  font-size: 0.875rem;
  color: #6C757D;
  list-style: none;
}


#login-section {
  padding: 50px;
  padding-top:0px;
  /* background: slateblue */
}

.login-page-btn {
  width: 140px;
  height: 39px;
  font-size: 16px;
  text-transform: capitalize;
  line-height: 35px;
  text-align: center;  
  display: block;
  padding: 0;
}

.login-card.login-out {
  margin-bottom: 30px;
}

.login-btns span {
  width: 30px;
  height: 1px;
}

.btn-green {
  background: #069482;
  border: 1px solid #069482;
  color: #fff;
}

.btn-gold {
  background: #fcc43b;
  border: 1px solid #fcc43b;
  color: #000;
}

.btn-gray-outline {
  background: #fff;
  border: 1px solid #dbdbdb;
  color: #000;
}

#login-section.break-lunch-in-out {
  padding: 45px;
}

#login-section .card {
  max-width: 400px;
  padding: 40px;
  border: none;
  border-radius: 5px;
  box-shadow: 12.287px 8.604px 25px 0px rgba(145, 145, 145, 0.1);
  background: #fff;
}

.login-card p {
  text-align: center;
  margin-bottom: 16px;
}

.log-total p {
  background: #fff;
  margin: 0 15px;
  text-align: left;
  width: 33.3333%;
  font-size: 15px;
  font-family: "ProximaNova-Bold";
  height: 50px;
  text-align: center;
  line-height: 50px;
  color: #646464
}

.log-total p:first-child {
  margin-left: 0;
}

.log-total p:last-child {
  margin-right: 0;
}

.log-total p:nth-child(2) {
  text-align: center;
}

.break-lunch-in-out {
  padding-bottom: 50px;
	border-bottom: 1px solid #dbdbdb;
	margin-bottom: 30px;
}

#progress-bar {
  margin-top: 30px;
}

.break-lunch-in-out .break-box {
  padding-bottom: 50px;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 40px;
}

#login-section .login-out-logs {
  width: 100%;
  max-width: 100%;
}

.login-out-logs .title {
  font-size: 24px;
  font-family: "ProximaNova-Bold";
  margin-bottom: 32px;
}

.disabled
{
  cursor: unset!important;
  box-shadow: none!important;
  opacity: 0.3!important;
}

.timer-box p, .timer-box div {
  width: 50%;
}

.break-timer-live {
  width: 100% !important;
}

img.end-break-img, img.end-lunch-img {
  margin-left: auto;
}

.login-btns img.start-break-img, .login-btns img.start-lunch-img {width:111px; height:51px;cursor: pointer;}
.login-btns img.end-break-img, .login-btns img.end-lunch-img {width:102px; height: 51px;cursor: pointer;}
.time-exhausted{
  margin-left:10px;
}
.timer .break-timer-live {
  margin-left:50px;
}
.request-end-lunch-img {
  width: 120px;
  height: 50px;cursor: pointer;
}
.request-approval
{
  position: absolute;
    min-height: 406px;
    top: 0;
    background: #fff;
    min-width: 312px;
    padding: 30px 0px;    padding-right: 25px;
}

.request-approval .sub-title, .request-approval-shift .sub-title {
  font-weight: 600;
  font-size:18px;
  margin: 0;
  padding: 0 0 15px 0;
  text-align: left !important;
}
.request-approval textarea, .request-approval textarea:active, .request-approval textarea:hover,
.request-approval-shift textarea, .request-approval-shift textarea:active, .request-approval-shift textarea:hover {
  min-height: 230px;
  outline: none;
  box-shadow: none;
}
.request-form-comment .form-control{
  border-radius: 8px;
  border: 1px solid #ADB5BD;
  min-height: 180px;
  resize: none;
  margin-bottom: 25px;
}
.request-form-comment .form-control:hover{
  min-height: 180px !important;
}
.request-form-comment .form-control::placeholder{
  color: #ADB5BD;
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
}
.submit-request{
  display: flex;
}
.submit-request button{margin-right:15px;width:100px;}
.request-end-break-img{
  width: 121px;
  height: 51px;
}

.request-approval-shift {
  position: absolute;
  min-height: 346px;
  left: 50%;
  top:50%;
  background: #fff;
  min-width: 314px;
  z-index: 100;
  transform: translate(-50%, -50%);
  padding: 30px;
  border-radius: 16px;
}

.shift-btns{justify-content: center;}
.unlock-shift-text{
  color:#f87d09;
  max-width: 200px;
  width: 100% !important;
  min-height: 49px;
  border-radius: 8px;
  font-weight: 600;
  padding: 15px 0;
}
.request-approval-shift textarea.is_required_field, .request-approval textarea.is_required_field{border:1px solid red;}
#login-section .has-ext-break .all-logs {
  height: 558px!important;
}
#login-section{padding-bottom:30px;}

.allow-break-box{padding:30px!important;}
.allow-break-input{width: 60%;margin:0 auto; margin-bottom: 15px;}
/* Media Quries */
@media only screen and ( max-width: 576px ) {
  #login-section{
    margin-top: 100px;
  }
  body {
    background-color: gray !important;
  }
  #login-section .card {
    padding: 25px;
  }

  .login-card.login-out, .break-lunch-in-out {
    margin-bottom: 14px;
  }

  .log-total p {
    line-height: 1;
  }
  #login-section {
    padding: 0px;
}
}

.msg-top {
  text-align: left;
  background: #119481;
  padding: 15px;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 97%;
}

.tea-break-popup
{
  width:45%;
  position: absolute;
  background:#ddd;
  left: 50%;
  top: 50%;
  border-radius: 16px;
  transform: translate(-50%, -50%);
}

.tea-break-popup img.end-break-img, .tea-break-popup img.end-lunch-img {
  margin-left: 0px;
}
.popup-overlay
{
  position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: #fff;
    
}

.popup-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgb(242 242 242 / 96%);
  z-index:99999;
  margin-left:-15px;
}

.tea-break-popup {
  width: 57%;
  position: absolute;
  left: 50%;
  top: 30%;
  background: #fff;
  min-height: 200px;
  padding: 1.5rem;
  transform: translate(-50%, -50%);
  -webkit-box-shadow: 10px 10px 20px 0px rgba(217,217,217,0.4);
  -moz-box-shadow: 10px 10px 20px 0px rgba(217,217,217,0.4);
  box-shadow: 10px 10px 20px 0px rgba(217,217,217,0.4);
}

.tea-break-popup .time-exhausted {
  margin-left: 10px;
  font-size: 18px;
  color: #212529;
  font-weight: 600;
  margin: 0;
  padding: 0;
}
.tea-break-popup .time_txt{
font-size: 15px;
font-weight: 400;
}
.tea-break-popup .request-approval textarea{
  min-height: 120px !important;
}

.tea-break-popup .request-approval {
  position: absolute;
  min-height: auto;
  top: 150px;
  left: 0;
  background: #fff;
  min-width: 100%;
  padding: 30px 35px;
}

.tea-break-popup .break-timer-live
{
  width:60px!important;
}
.tea-break-popup .time-exhaustedt.time_txt
{
  font-size: 14px;
  font-weight: 400;
}

.tea-break-popup{
  
 animation: fadein 2s;
  -moz-animation: fadein 2s; /* Firefox */
  -webkit-animation: fadein 2s; /* Safari and Chrome */
  -o-animation: fadein 2s; /* Opera */
}
@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-moz-keyframes fadein { /* Firefox */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-o-keyframes fadein { /* Opera */
  from {
      opacity:0;
  }
  to {
      opacity: 1;
  }
}

.shift-btns button{
  font-size: 16px;
  color: #fff;
  background: #069482;
  border:1px solid #069482;
}
/* Media Quries */
@media only screen and ( max-width: 1280px ) {
#login-section .card{
  padding:25px
}
.log-total p{
  font-size: 13px;
}
.login-btns img.end-break-img, .login-btns img.end-lunch-img {
  width: 40%;
  height: 100%;
  cursor: pointer;max-width: 110px;
}
.login-btns img.start-break-img, .login-btns img.start-lunch-img {
  width: 45%;
  height: 100%;
  cursor: pointer;
  max-width: 110px;
}
.login-page-btn, .log-container{
  font-size: 13px;
}
#LoadingWrapper .allow-break-input {
  width: 70%;
  margin: 0 auto;
  margin-bottom: 15px;
  display: -webkit-box !important;
}
.shift-btns button{
  font-size: 14px;
  color: #fff;
  background: #069482;
  border:1px solid #069482;
}
}

.msg-top.warning-color {
  background:#e74c3c;
  color:#fff;
}

.msg-top.warning-color p {
  font-family: "ProximaNova-Bold";
  color:#fff;
}

.msg-top.warning-color p a {
  color:#fff;
  text-decoration: underline;
}

#login-section .msg-top{width: 100%;}

/* .extendedbreakcomment .internal-comment-box{left:10%;top:30% !important;} */
.extended-break-popup-wrap{width:400px;min-height: 180px;top:38%}
.ext-action-wrap{
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.extended-break-popup-wrap .timer .break-timer-live {
  margin-left: 0px;
  width: auto!important;
}

.extendedbreakcomment .internal-comment-box {
  max-width: 490px;
  width: 100%;
  min-height: 200px;
  z-index: 99999;
  position: absolute;
  left: 50% !important;
  top:50% !important;
  transform: translate(-50%, -50%) !important;
  background: #fff;
  padding: 30px;
  overflow: hidden !important;
}
.extendedbreakcomment .internal-comment-label{
  font-size: 16px;
  font-weight: 400;
  line-height: 25px !important;
  color: #212529;
  font-family: 'Poppins', sans-serif !important;
}
.extendedbreakcomment .internal-comment-box textarea {
  min-height: 120px;
  border-radius: 8px;
  border: 1px solid #ADB5BD;
  resize: none;
}
.extendedbreakcomment .internal-comment-box textarea::placeholder{
  color: #ADB5BD;
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
}
.timer-box p{
  width: 100%;
}
.time-exhausted {
  margin-left: 0px;
}
.ext-action-wrap .timer 
{
  margin: 15px 0px;
}
.inactive_shift_buttons, .inactive_break_buttons
{
  opacity: 0.4!important;
  cursor: not-allowed!important;
}
.active_break_buttons
{
  background-color: #ff1100!important;
  color: #fff !important;
}
.override-policy label
{
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  margin: 0;
  padding: 0 20px 0 13px;
  position: relative;
  top: -0;
}
.override-policy{
  margin-top: 11px;
  position: relative;
  top: 10px;
}
.override-policy input[type="checkbox"]:after{
  border-color: #fff;
}
button.login_update_btn{
  background: #E6FAF8;
  border-color: #E6FAF8;
  color: #069482;
  max-width:180px;
  height: 40px;
  position: relative;
  top: -10px;
  font-weight: 500;
  border-radius: 8px;
}
button.login_update_btn:hover, button.login_update_btn:focus{
  background: #055949 !important;
  border-color: #055949 !important;
  color: #fff !important;
}
.cancel_btn_pop{
  max-width: 130px;
  width: 100% !important;
  background: #F93154;
  border-color: #F93154;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  border-radius: 8px;
  min-height: 49px;
}
button.cancel_btn_pop:hover{
  background:#dd2a48 ;
  border-color: #dd2a48;
  }
.send_btn_pop, .ext_br_brtn{
  max-width: 130px;
  width: 100% !important;
  background: #219186;
  border-color: #219186;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  border-radius: 8px !important;
  min-height: 49px;
}
button.send_btn_pop:hover, button.ext_br_brtn:hover{
  background: #004c3f !important;
  border-color: #004c3f !important;
  color: #fff !important;   
}
.end_break_btn{
  background: #FFEFF2;
  border-color:#FFEFF2;
  color:#F93154;
  font-size: 16px;
  font-weight: 600;
  max-width: 150px;
  border-radius: 8px;
  min-height: 49px;
  width: 100%;
}
button.end_break_btn:hover{
  background: #F93154 !important;
  border-color:#F93154 !important;
  color:#FFEFF2 !important;
}
.project-assignee-submit.ext_br_brtn{
  max-width: 250px !important;
  width: 100% !important;
 border-radius: 8px !important;

}
.login_portel_page .msg-top.shift-error{
  margin: 0 0 10px 0;
  width: 100%;
  border-radius: 8px;
  color:#F93154 !important;
  background: #FFEFF2;
}
.login_portel_page .msg-top.shift-error p{
  color:#F93154 !important;
  font-size: 16px;
  font-weight: 500;
}
button.inactive_break_buttons:hover{
 background: #E6FAF8 !important;
 color: #219186 !important; 
}
button.end_shift{
  color:#F93154 !important;
  background: #FFEFF2 !important;
}
.total_log_mob{
  display: none;
  padding: 25px 18px 0 18px;
}
.total_log_mob p{
  margin: 0;
  padding: 0;
}
.total_log_mob p span{
  font-weight: 600;
}
@media (max-width:580px) {
  .total_log_mob{
    width: 100%;
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: center;
  }
  .total_log{
    display: none;
  }
}
.mobile-block-msg
{
  display: block;
  text-align: center;
}

.mobile-block-shift-msg
{
  display: block;
  margin-top: 40px;
  font-weight: bold;
  color: #fff;
}