/* Components/Reports/LogTimer */
#date-list-contianer {
  padding: 15px;
}

#date-list-contianer #LoadingWrapper {
  margin-bottom: 15px;
  height: 20px;
}

#date-list-contianer.loading {
  padding: 0 15px;
}

@media only screen and ( max-width: 576px ){
  #date-list-contianer .card .name {
    width: 40%;
  }
  .mb-scroll-x {
    width: 60%;
    overflow: hidden;
    overflow-x: scroll;
  }
  #date-list-contianer .card {
    padding: 15px;
  }

  #date-list-contianer .head {
    display: none !important;
  }

  #date-list-contianer .list-card .single-project {
    flex-direction: column;
    padding: 10px 0;
    border-bottom: 1px solid rgba(0,0,0,0.05);
  }

  #date-list-contianer .list-card .single-project:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  #date-list-contianer .list-card .single-project .list-card {
    padding-bottom: 0;
  }

  span.project-name-title {
    margin-bottom: 5px;
  }
  
  #date-list-contianer .list-card .single-project p {
    width: 100% !important;
    padding: 0 0 8px 0;
  }

  #filter .filter-row {
    padding: 15px;
    background: #f7f8f8;
    border-radius: 5px;
  }

  #filter .react-datetime-picker, #filter .people select, #filter .hours select, .form-control {
    border: 1px solid #c5c7c7
  }

  #date-list-contianer .mb-scroll-x p {
    min-width: 125px
  }

  #date-list-contianer .mb-scroll-x .inner-width {
    width: 200px;
  }

  #date-list-contianer .user-active {
    padding: 10px;
    background: #c5c7c7
  }
}