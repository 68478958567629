/*
* CarousalContainer styles
*/
.caros-wrapper {
  
}

.caros-title {
  font-size: 16px;
  padding: 29px 0 24px 0;
}