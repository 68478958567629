 .logamend{
    /*right: 25%;
    position: fixed;
    top: 112px;*/
    background: #fff !important;
    max-width: 800px;
    width: 100%;
    padding: 15px;
    box-shadow: 0 0 10px 10px rgba(0,0,0,0.02);
    border-radius: 16px !important;
    min-height: 500px;
	overflow: hidden;
    overflow-y: scroll;
    position: absolute;
    top: 5%;
    left: -6%;
    right: 0;
    margin: 0 auto;
    z-index: 9999;
    
}
.center-fixed-modal{
    position: fixed !important;
    left: 0 !important;
   }
   
.projects-mytasks .logamend{
    overflow: hidden;
    max-width: 600px !important;
    width: 100%  !important;
    padding: 30px;

}
.projects-mytasks .logamend h4.page-sub-title{
    color: #212529;
    font-size: 18px;
    margin: 0;
    padding: 0 0 15px 0;
    text-align: left !important;
}
.projects-mytasks .logamend .member-not-found{
    color: #495057;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
    padding: 0 0 25px 0;
    text-align: left !important;
  
}
.projects-mytasks .project-assignee-submit{
    max-width: 200px;
    width: 100%;
    min-height: 50px;
    border-radius: 8px !important;
    margin: 15px 0 0 0;
}
.projects-mytasks  .change-assignee .react-tags{
    min-height: 150px !important;
}
.projects-mytasks .logamend button.close-icon{
    width: 35px;
    height: 35px;
    border-radius: 6px;
    background: #F4F6F9 !important;
    border: 1px solid #f4f6f9 !important;
    opacity: 1;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.projects-mytasks .logamend button.close-icon .material-icons{
    color: #000;
    font-size: 20px;
}
.projects-mytasks .logamend button.close-icon:hover, .projects-mytasks .logamend button.close-icon:focus{
    background: #004c3f !important;
    border-color: #004c3f !important;
}
.projects-mytasks .logamend button.close-icon:hover .material-icons, .projects-mytasks .logamend button.close-icon:focus .material-icons{
  color: #fff !important;
}


.overlay-log{
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgb(33 37 41 / 16%);
    z-index: 9991;
    top: 0;
    left: 0;
}


.logamend::-webkit-scrollbar {
	width: 2px;
}

.logamend::-webkit-scrollbar-thumb {
	background: #e8e8e8;
	border-radius: 10px;
}

.logamend::-webkit-scrollbar-track {
	background: #fff;
}

.logamend .has-border-right {
    border-right:0px solid #ccc;    
}
.logamend .change-log-entry{
    padding-left:20px;
}

.project-assignee-submit {
    margin: 15px auto;
    display:block;
    width: 100%;
}

.lga-spacer {
    width: 1px;
    height: 100%;
    background: #ccc;
}

.lga-coming-soon {
    margin-top: 43%;
    display: block;
}

.logamend .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 0.2;
    cursor: pointer;
    display: block;
    z-index: 999999;
}

.logamend .close-icon:hover {
    opacity: 1;
}

.logamend .required-field
{
    border:1px solid red!important;
}



.single-task  .empty-icon{width:75px;}
.trello-card .close-icon{opacity: 1;}
.trello-card .close-icon svg path{
    fill-rule: evenodd;
    fill: #069482!important;
}

.member-not-found.text-center
{
    display: block;
    text-align: center;
    margin-bottom: 10px;
}

.trello-card .trello-attachment{cursor: pointer;}
.trello-card .attachmet-label .trello-attachment{color:#007bff;}
.trello-card .attachmet-label .trello-attachment:hover{text-decoration: underline;color:#007bff;}

.logamend .all-project-users .col-sm-4{
    margin-top:5px;
}

.logamend-modal .modal{
    z-index: 999999999;
}
@media only screen and (max-width: 768px) {


.logamend{
    position: absolute;
    top: 5%;
    left: -18%;
    right: 0;
    margin: 0 auto;
    height: auto;
}

}

@media only screen and (max-width: 500px) {
    .logamend {
        position: absolute;
        top: 5%;
        left: 0;
        right: 0;
        margin: 0 auto;
        height: auto;
    }
}


.internal-comment-box {
    background: #f5f5f5;
    max-width: 740px;
    width: 100%;
    padding: 15px;
    box-shadow: 0 0 10px 10px rgb(0 0 0 / 2%);
    border-radius: 5px;
    min-height: 400px;
    overflow: hidden;
    overflow-y: scroll;
    position: absolute;
    top: 8% !important;
    left: 50% !important;
    -ms-transform: translate(-50%, -0%) !important;  
    transform: translate(-51%, 0%) !important;
    margin: 0 auto;
    z-index: 9999;
}

.internal-comment-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 47%);
    z-index: 9999;
    top: 0;
    left: 0;
}

.internal-comment-box textarea
{
    width: 96%;
    margin: 0 auto;
    height: 250px;
}
.internal-comment-label
{
    display: block;
    font-family: "ProximaNova-Bold";
    width: 96%;
    margin: 0 auto;
    margin-bottom: 10px;
}

.task-active-running .single-task {opacity: 1!important;}
.task-active-running .single-task.active {opacity: 1!important;}
/* .add-new-project.add-new-task{margin-top:70px!important} */
.task-running.access-message{text-align: center;}
.change-assignee .minimum-text
{
    display: block;
    text-align: center;
    color: red;
}
.reassign-my-task
{
height: 28px;
width: 28px;
margin-right: 18px;
}

.task-active-running .single-task .task-btn.box,
.task-active-running .single-task .box.task-total-time {
    opacity: 0.2!important;
}

.task-active-running .single-task.active .box.sl-no,
.task-active-running .single-task.active .box.task-details,
.task-active-running .single-task.active .task-btn.box,
.task-active-running .single-task.active .box.task-total-time {
    opacity: 1!important;
}

.search-filter-assignees input
{
    padding: 0px 10px;
    height: 40px;
    margin-bottom: 20px;
    border-radius: 3px;
    border: 1px solid #ededed;
}
















































































































































































































































