/**
 * Datalist Styles
 */

#date-list-contianer {
  padding: 35px;
  /* background: #fafbfb; */
  background: #f7f8f8;
  border-radius: 5px;
  height: 100%;
  width: 100%;
  border-radius: 5px;
}

#date-list-contianer .card {
  align-items: center;
  flex-direction: row;
  padding: 15px 27px;
  border-radius: 5px;
  /* box-shadow: 0px 2px 3px 0px rgba(193, 193, 193, 0.3); */
  margin-bottom: 10px;
  cursor: pointer;
  /* transition: all 0.1s linear; */
}

#date-list-contianer .card-brand {
  
}

#date-list-contianer .card:hover {
  box-shadow: 0px 5px 10px 0px rgba(193, 193, 193, 0.15);
}

#date-list-contianer .card p {
  margin: 0;
}

#date-list-contianer .card .name {
  width: 540px;
}

#date-list-contianer .card .time-entered {
  width: 270px;
}

#date-list-contianer .card .total-rate {
  width: 120px;
}

#date-list-contianer .card .total-rate p {
  margin-right: 15px;
}

#date-list-contianer .card .projects {
  width: 250px;
}

/* #date-list-contianer .simple-card.user-active .list-card.card,
#date-list-contianer .simple-card.user-active .card-brand .card,
#date-list-contianer .simple-card.user-active {
  border-radius: 25px;
} */

.listcard-single {
  box-shadow: none !important
}

#date-list-contianer .project-card.work-card .card .name, #date-list-contianer .project-card.work-card .card .card-body .project-name {
  width: 62%;
}

#date-list-contianer .project-card.shift-card .card .name, #date-list-contianer .project-card.shift-card .card .card-body .project-name {
  width: 44%;
}


/* #date-list-contianer .simple-card.user-active .listcard-single.project-card {
 padding-left: 15px; 
} */