.loan_manage_header{
    justify-content: space-between;
    padding: 30px;
}
.loan_manage_header h1.page-title{
    padding: 0 !important;
}
.loan_manage_header .people .form-control{
    border: 1px solid #ADB5BD;
    min-height: 49px;
    border-radius: 8px !important;
    padding-left: 15px;
    padding-right: 15px;
    width: 100% !important;
}