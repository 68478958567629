/* 
    New Side Menu
    path components/NewSideMenu
*/
/*
#new-side-menu {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 230px;
    box-shadow: 12.287px 8.604px 25px 0px rgba(179, 179, 179, 0.1);
    z-index: 9999;
    padding: 0 30px;
    background: #fff;
    min-height: 100vh;
    padding-bottom: 30px;
    overflow: hidden
}
*/
.nsmi_menu-icon div {
    width: 30px !important;
    height: 30px !important;
    padding: 3px;
    border-radius: 10px;
    background: #f2f2f2;
}
.nsmi_menu-icon{
   display: flex !important;
   align-items: center;
   justify-content: flex-start;
}
.nsmi_menu-icon .material-icons{
    color: #ADB5BD !important; 
}
#new-side-menu {
    min-height: 102vh;
    padding-bottom: 30px;
    overflow: hidden;
    padding-top: 100px;
    background: #fff;
  
}

.nsm_brand_logo {
    min-width: 240px;
    padding:0px;
    display: flex;
    align-items: center;
   
}
.nsm_brand_logo img{
    width: 126px;
    height: 33px;
}

.side-menu-wrapper {
    height: 100%;
    min-height: calc(100vh - 160px);
    overflow: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width:none;
    margin: 0 20px;
}

.side-menu-wrapper .nsmi_menu_wrapper {
    padding: 10px 15px;
    align-items: center;
    cursor: pointer;
    border-radius: 8px;
   
}
.side-menu-wrapper .nsmi_menu_wrapper:hover{
    background: #069482;
    color: #fff;
    text-decoration: none !important;
}
.nsmi_menu_wrapper {
    align-items: center;
    width: 100%;
    cursor: pointer;
}


.nsmi_menu_name {
    margin-bottom: 0;
    font-size: 15px !important;
    margin-left: 10px;
    color: #6C757D ;
}

.menu_icon_arrow {
    width: 11px;
    height: 6px;
    display: block;    
    background: url(../../images/sidemenu-icon-arrow.png) no-repeat center;
    background-size: 11px 12px;
    background-position: top center;
    -webkit-transition: all 0.1s linear;
}

.nsmi_submenu_open .menu_icon_arrow {
    background-position: bottom center;
}

.nsmi_submenu {
     background: #fff;
    height: 100%;
    list-style-type: disc;
    width: auto;
    padding: .8rem 2rem;
    border-radius: 8px;
    margin: 0 !important;
   padding-top: 5px;
    margin: 0;
}

.nsmi_submenu li {
    list-style-type: disc;
    color: #6C757D;
    cursor: pointer;
    padding: 5px 0;
    font-weight: 400;
    position: relative;
    list-style: none;
}
.nsmi_submenu li::before {
    border-radius: 7px;
    position: absolute;
    content: "〇";
    top: 8px;
    left: -20px;
    font-size: 11px;
  }
  .nsmi_submenu li li::before{
      display: none;
  }
.nsmi_submenu li a{
    color: #6C757D;
    cursor: pointer; 
    font-weight: 400;
}
.nsmi_submenu li:hover, .nsmi_submenu li a:hover {
    color: #219186;
    font-weight: 500;
}



#new-side-menu .nsmi_menu_wrapper a{color:#212529!important;text-decoration:none;}
#new-side-menu .nsmi_menu_wrapper:hover .nsmi_menu_name{
color: #fff;
}
#new-side-menu .nsmi_menu_wrapper:hover .nsmi_menu-icon .material-icons{
    color: #fff !important;
}
#new-side-menu .active{
    background: #069482;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    align-items: center;
    border-radius: 8px;
}
#new-side-menu .active .nsmi_menu-icon .material-icons{
    color: #fff !important;
}
#new-side-menu .active .nsmi_menu_name{
    color: #fff;
}

@media only screen and ( max-width: 1024px ) {
  #new-side-menu {
    padding-top: 90px;
    left: -245px;
  }

  #new-side-menu .mobile-active {
    padding-top: 0;
    left: 0;
  }
}
.top-bar-active #new-side-menu{
    padding-top: 104px;
}
.SideNavbar{
    position: fixed;
    width: 250px;
    background: #fff;
    height: 100vh;
    z-index: 99;

}
.barnd_logo{
    width: 136px;
    height: 33px;
    margin: 40px auto;
}
.side-menu-wrapper .nsmi_menu_wrapper:focus{
    background: #219186 !important;
}
.side-menu-wrapper .nsmi_menu_wrapper:focus .material-icons{
    color: #fff !important;
}
.side-menu-wrapper .nsmi_menu_wrapper:focus .nsmi_menu_name{
    color: #fff !important;
}
.side-menu-wrapper .nsmi_menu_wrapper:target{
    background: #219186 !important;
}
.menu_items {
gap: 5px;
display: flex;
flex-direction: column;
}
