/*
* Loan Summery head
*/

#loan-summery-head {
  min-height: 66px;
  background:#F0F6FA;
  padding: 0 20px;
  margin-bottom: 20px;
}

#loan-summery-wrapper {
   color: #fff
}

#loan-summery-wrapper .title {
  font-size: 15px;
  color: #212529;
  font-weight: 600;
  margin:0 30px;
  padding:0 0 10px 0;
  display: block;
}

#loan-summery-wrapper select,
#loan-summery-wrapper p {
  color: #6C757D;
  font-size: 15px;

}
#loan-summery-wrapper .amount.form-control {
  height: auto;
  border: none !important;
  width: auto;
  padding-left: 0;
}


#loan-summery-wrapper select option {
  color: #000
}

#loan-summery-head .date {
  width: 20%
}

#loan-summery-head .description {
  width: 50%
}

#loan-summery-head .status {
  width: 10%
}

#loan-summery-head .amount {
  width: 10%
}

#loan-summery-head select.form-control.amount {
  background: url(../../../images/downArrow.png) no-repeat 95% center;
  height: auto;
  border: none;
  background-size: 12%;
	background-position-x: 66%;
}

#loan-summery-head .type {
  width: 10%
}

#loan-summery-head .note {
  width: 10%
}
#loan-summery-wrapper .theme_table tbody td:last-child {
  text-align: right;
}
#loan-summery-wrapper .theme_table tbody td:nth-child(4) {
  text-align:left !important;
}
tbody.loan-summery-list-item td:last-child{
  font-weight: 600 !important;
  color: #00B74A;
}
tbody.waiting-approval td:last-child{
  color: #39C0ED;
  text-transform: capitalize;
}

#loan-summery-wrapper .theme_table tbody .loan-description-text
{
  width: 200px!important;
  max-width: 200px!important;
}

#loan-summery-wrapper .theme_table tbody .loan-description-text span.repayment-via 
{
  display: block;
}