/*
* Approval Modules styles
*/

.ApprovalModules {
  margin-bottom: 33px;
  background: #f9fafa;
  padding: 30px 35px;
}

.carousel .slide {
  background: transparent !important
}

.carousel .slide {
  max-width: 340px;
  min-width: 340px !important;
}
.appr-show-unapproved {
	padding: 0 0 28px 0;
}

.caros-title {
  margin: 0;
  padding: 0;
  align-items: center;
  align-content: center;
}


.caros-title.pending-exist {
  padding-left:14%;
}

.appr-ckbox-unapproved {
  padding-right: 22px;
}