/* Monthly Report */
#reportMonthly {
}

.mrHead {
  background: #dfdfdf;
}

#reportMonthly .filter-row > div {
  margin-right: 25px;
 /* min-width: 125px;*/
}

#report-monthly-head .container {
  margin-bottom: 0;
  color: #636363;
  font-size: 16px;

  padding: 15px 0;
  /* background: #f7f8f8;
  background: #f8f9fa; */
  max-width: 1111px;
  /* border-bottom: 1px solid #f7f8f8; */
}

#report-monthly-head .container .strong {
  color: #636363;
  font-weight: 400;
}

#date-list-contianer.monthly-report-data p {
  font-weight: normal!important;
  font-weight: 400;
}

#date-list-contianer .project-card.work-card.show-active-row,
#date-list-contianer .project-card.shift-card.show-active-row
{
  display: block;
}

#date-list-contianer .project-card.work-card.show-active-row .headings p,
#date-list-contianer .project-card.shift-card.show-active-row .headings p
{
  font-weight: 500;
}
.monthly-view-emp-name
{
  font-weight: 600;
  font-size: 16px;
}
.monthly-report-data .shift-day-bold p
{
color: red!important;;
}
.monthly-ename
{
  margin-bottom: 15px;
}
.expand_table #LoadingWrapper{
  display: contents;
}
.headings{
  background: #EDF4FA;
  min-height: 60px;
}
.showbox_expand, .shift-day-bold{
  display: contents;
}
#date-list-contianer {
  background: #fff;
}