#user-management-section .profile-fields
{
	margin-bottom:20px;
	padding:0 25px;
}

#user-management-section .User-profile,
#user-management-section .basecamp-profile,
#user-management-section .hub-notification-role,
#user-management-section .doto-position,
#user-management-section .leave-profile{padding:20px 0px;}

.switchUser{margin-bottom: 60px;}

#user-management-section .card {
    padding: 10px;
    border: none;
    border-radius: 5px;
    box-shadow: 12.287px 8.604px 25px 0px rgba(145, 145, 145, 0.1);
    background: #fff;
    margin-bottom:30px;
}

.leave-profile .form-control{
    width: 100%;

}
.topgap{
    padding-top: 25px;;
}
.leavchose{
    background-color: #f5f5f5 !important;
    border: 1px solid #f5f5f5 !important;
    font-size: 20px;
}
#user-management-section .User-profile .profile-fields{
	margin-bottom:15px;
}

#user-management-section .LoadingBoxSmall {
    margin: 30px 0px;
}

#user-management-section .equalHM {
    width: 50%;
    padding: 1rem !important;
}
/*----faheem----*/
#user-management-section h5{
    font-size: 20px;
    font-weight: 600;
    color: #636363;
    padding: 25px 0;
}
#user-management-section label{
    font-size: 16px;
    color: #979797;
    padding-bottom: 10px;
    margin: 0;
}
.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 14px);
    padding: .375rem .75rem;
    font-size: .90rem;
    font-weight: 400;
    line-height: 1.5;
    color: #636363;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #E8E8E8;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    font-weight: 500;
}
.dateicon{
    position: relative;
}

.savebtn{
    padding: 10px 30px;
    min-width: 370px;
    display: block;
    background: #069482;
    font-size: 15px;
    font-weight: 600;
    border: 0;
    border-radius: 8px;
    margin-top: 15px;
}
.savebtn:hover, .savebtn:focus{
    background: #004c3f !important;
    border: 0 !important;
    color: #fff;
}
.savebtn_50{
    min-width: 185px;
}
.form-control:focus{
    color: #636363;
    background-color: #fff;
    border-color: #069482;
    outline: 0;
    box-shadow: none !important;
}
[type="file"] {
    height: 0;
    overflow: hidden;
    width: 0;
  }

.fileuplod label {
    background: #fff;
    border: none;
    border-radius: 5px;
    color: #636363 !important;
    cursor: pointer;
    display: inline-block;
    font-size: inherit;
    font-weight: 500;
    margin-bottom: 1rem;
    outline: none;
    position: relative;
    transition: all 0.3s;
    width: 100%;
    border: 1px solid #E8E8E8;
    height: calc(1.5em + .75rem + 12px);
    padding: .375rem .75rem;
    font-size: .90rem;
    font-weight: 500;
    line-height: 2;
  }
.fileuplod label::after{
    width: 16px;
    height: 15px;
}
.fileuplod span{
    font-size: 16px;
    color: #979797;
    padding-bottom: 10px;
    margin: 0;
    display: block;

}
.dropposi select.form-control{
    background-position: 98% center !important;
}
.list-head {
    color: #6C757D;
    font-size: 15px;
    letter-spacing: .25px;
    background: #F0F6FA;
    min-height: 50px;
    line-height: 50px;
    font-weight: 600;
}
.list-body {
    height: 60px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #212529;
    position: relative;
}
.list-body:after {
    position: absolute;
    content: "";
    width: 93%;
    height: 1px;
    background: #F0F0F0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
}
.profile-fields #attachment input {
    width: 0;
    height: 0;
    font-size: 0;
}

.profile-fields #attachment .file-wrapper {
    padding-left: 0px;
    border:0px;
}

.user-list-view .list-body-cont
{
    background:#fff;
    padding:15px 0px;
    margin-bottom:10px;
}

.editt-icon path:hover{
   fill:#129482;
}

.delete-icon path:hover{
    fill:#129482;
 }

.seperator{
display:inline-block;
padding-right:20px;
color:#e0e0e0;
}


.delete-icon-white
{
    display: inline-block;
    padding-left: 5px;
    padding-top: 0px;
}
#user-management-section .react-tel-input .form-control{
    border: 1px solid #E8E8E8 !important;
    height: calc(1.5em + .75rem + 15px) !important;
    width: 100% !important;
}
#user-management-section .react-tel-input .flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: #fff;
    border: 1px solid #E8E8E8;
    border-radius: 5px 0 0 5px;
    border-right: 0;
}
.posted-by{
    display: flex;
}
.posted-by h3{
    margin: 0;
    padding: 0;
    font-size: 16px;
    color: #323232;
    flex-basis: 80%;
}
.notes-added{
    border-bottom: 1px solid #F0F0F0;
    padding: 25px 0;
}
.note-on{
    flex-basis: 20%;
    color: #969696;
    font-size: 14px;
}
.posted-note{
    color: #969696;
    font-size: 14px;
}
.add-revision{
    font-size: 15px;
    font-weight: 600;
    color: #495057;
    padding: 20px 0;
    position: relative;
    background: #EDF2F7;
    max-width: 200px;
    width: 100%;
    padding:0 15px;
    border-radius:8px;
    min-height: 48px;
    line-height: 48px;
    margin: 20px 0;
    cursor: pointer;
}
.add-revision::after{
    position: absolute;
    content: "";
    display: inline-block;
    background: url(../../images/add_plus.svg) no-repeat;
    width: 30px;
height: 36px;
background-position: 5px 16px;
}
.add-note{
    font-size: 15px;
    font-weight: 600;
    color: #495057;
    padding: 20px 0;
    position: relative;
    background: #EDF2F7;
    max-width: 200px;
    width: 100%;
    padding:0 15px;
    border-radius:8px;
    min-height: 48px;
    line-height: 48px;
    margin: 20px 0;
    cursor: pointer;
}
.add-note::after{
    position: absolute;
    content: "";
     display: inline-block;
    background: url(../../images/add_plus.svg) no-repeat;
    width: 30px;
    height: 36px;
    background-position: 5px 16px;
}
.seperator{
    display:inline-block;
    padding-right:20px;
    color:#e0e0e0;
    }

.delete-all{
    border: 1px solid #E5E5E5;
    min-width: 110px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-weight: 600;
    font-size:14px ;
    color: #BDBDBD;
    margin-right: 15px;
    cursor: pointer;
    transition: all .8s ease-in-out;
}
.delete-icon-white path{
    transition: all .8s ease-in-out;
}
.delete-all:hover{
    background: #069482;
    color: #fff;
    border: 1px solid #069482;
}
.delete-all:hover .delete-icon-white path{
fill: #fff;
}

#user-list-management-section .filter-bar{
    margin:0 30px;
}
.serchinput::placeholder{
    color: #ADB5BD !important;
    font-size: 15px;
    font-weight: 400;
}
.serchinput{
    position: relative;
    border: 1px solid #ADB5BD !important;
    min-height: 49px;
    border-radius: 8px !important;
    padding-left: 15px !important;
    padding-right: 65px !important;
    font-size: 15px;
    font-weight: 400;
    color: #495057;
    width: 100%;
}
.search_btn {
    width: 49px;
    height: 49px;
    background-color: #129482;
    position: absolute;
    top: 0;
    right: 16px;
    border: 0;
    -webkit-border-top-right-radius: 5px;
    -webkit-border-bottom-right-radius: 5px;
    -moz-border-radius-topright: 5px;
    -moz-border-radius-bottomright: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;

}
.search_btn .material-icons{
    color: #fff;
    font-size: 25px;
}
.search_btn:hover, .search_btn:focus  {
    background: #004c3f !important;
}
.search_btn:hover .material-icons{
    color: #fff;

}
.add-missing-task span {
    color: #BDBDBD;
    font-size: 20px;
    font-weight: 600;
}
.list-body-cont{
    margin: 0;
    padding: 0 !important;
    min-height: 43px;
    display: flex !important;
    align-items: center;
    font-size: 16px;
    color: #636363;
    border: 1px solid #fff;
    border-radius: 5px;
    transition: all .8s ease-in-out;

}
.list-body-cont:hover{
    border: 1px solid #129482;
    -webkit-box-shadow: 0px 10px 15px 0px rgba(193,193,193,.2);
    -moz-box-shadow: 0px 10px 15px 0px rgba(193,193,193,.2);
    box-shadow: 0px 10px 15px 0px rgba(193,193,193,.2);
}
.list-body-cont input[type="checkbox"]:after {
   border: 1px solid #BDBDBD;
   top: -7px !important;
}
.list-body-cont input[type="checkbox"]:checked:after {
    background-size: 10px;
    border: 1px solid #069482 !important;
}
.list-head-cont{
    margin: 0;
    font-size: 16px;
    font-weight: 600;
    color: #636363;
    height: 70px;
    align-items: center;

}
.list-head-cont input[type="checkbox"]:after {
    position: absolute;
    content: '';
    width: 15px;
    height: 15px;
    border-radius: 3px;
    border: 2.5px solid #636363;
    background: transparent;
    display: block;
    background-size: 20% auto;
    background-position: center;
    transition: all 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    left: 20px;
    top: -7px;
}
.editbox{
    padding-top: 5px;
}

#user-management-section #attachment .LoadingBoxSmall {
    margin: 0px;
}
.salary-revision .savebtn{
    min-width: 200px;
    margin-left: 0px;
    margin-bottom: 24px;
}

.pay-options input[type=radio] {
    -webkit-appearance: initial;
    appearance: initial;
    margin-left: 18px;
    position: relative;
}

.account-type input[type=radio] {
    -webkit-appearance: initial;
    appearance: initial;
    margin-left: 0;
    position: relative;
}
.lev_heder{
    padding:0!important;
    position: relative;
    margin-bottom:30px;

}
.banktype div{
    align-items: center;
    display: flex;
    margin-right: 18px;
    width: 50%;
    padding:15px 0 0 20px;
}
.lev_heder .pay-options {
    width: 100%;
}
.paymentchoose{
    padding-left: 15px;
    padding-right: 15px;
}

/* .lev_heder:after{
    position: absolute;
    content: '';
    width: 93%;;
    height: 1px;
    background-color: #e8e8e8;
    bottom: 0;
    left: 25px;
    right: 25px;
    margin: 0 auto;
} */
.lable_height{
    padding-top: 33.3px;
}

  .option-input {
    -webkit-appearance: none;
    -o-appearance: none;
    appearance: none;
    position: relative;
    top: -4px;
    right: 0;
    bottom: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background: #f5f5f5;
    border: none;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    margin-right: 0.5rem;
    outline: none;
    position: relative;
    z-index:999;
    border-radius: 25px;
    border: 1px solid #ddd;
  }
  .option-input:hover {
    background: #ddd;
  }
  .option-input:checked {
    background: #149a89;
    border: 1px solid #149a89;

  }
  .option-input:checked::before {
    height: 14px;
    width: 14px;
    position: absolute;
    content: '';
    background: #fff;
    display: inline-block;
    font-size: 10px;
    line-height: 19px;
    text-align: center;
    left: 2px;
    font-size: 50px;
    top: 2px;
    border-radius: 10px;
  }
 .flexbox{
    align-items: center;
    display: flex;
    justify-content: flex-end;
 }
  .option-input.radio {
    border-radius: 50%;
  }
  .option-input.radio::after {
    border-radius: 50%;
  }

  .enable-disable-profile .btn-grey
  {
      background: #808080;
  }

  span.with-attachment, span.with-attachment span
  {
      padding-bottom: 0px;
  }
  span.with-attachment .uploaded-file
  {
      margin-left: 20px;
  }

  .new-people-overlay
  {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0/47%);
    z-index: 999;
    top: 0;
    left: 0;
  }
  .new-people-content
  {
    background: #fff;
    max-width: 500px;
    min-height: 300px;
    width: 100%;
    padding: 15px;
    box-shadow: 0 0 10px 10px rgb(0 0 0 / 2%);
    border-radius: 8px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: -6%;
    right: 0;
    margin: 0 auto;
    z-index: 1000;
  }
  .new-people-content .field-item
  {
      margin:15px 0px;
  }
  .new-people-content .btn{margin-right: 10px;}

  .new-people-content .error-field{
      border:1px solid red;
  }
  .new-people-content .form-control{
      border-radius: 8px;
      border: 1px solid #adb5bd !important;
padding-left: 15px;
padding-right: 15px;
  }
  .lock-symbol
  {
      width:32px;
      height: 32px;
      cursor: pointer;
  }
  .salary-revision .hidetext
  {
      display: none;
  }
  .salary-revision
  {
      min-height: 110px;
  }

  button.deletbtn{
    width: 48px;
    height: 48px;
    border-radius: 8px;
    background: #EDF4FA;
    border-color: #EDF4FA;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
  button.deletbtn .material-icons{
    color:#6C757D;
    font-size: 22px;
  }
  button.deletbtn:hover, button.deletbtn:focus{
    background-color: #EA2027 !important;
    border: 1px solid #EA2027 !important;
  }
  button.deletbtn:hover .material-icons,  button.deletbtn:focus .material-icons{
    color: #fff;
  }
.add_new_userbtn{
    color: #fff;
    display: flex;
    align-items: center;
    background: #219186 !important;
    border: 1px solid #219186 !important;
    border-radius: 8px;
    max-width: 200px;
    width: 100%;
    min-height: 48px;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.00938em;
    cursor: pointer;
    margin-right: 10px;
}
.add_new_userbtn:hover{
    background: #004c3f !important;
    border-color: #004c3f !important;
    border: none;
    color: #fff;
}
.usermange_table{
    margin-top: 30px;
}
.edit-profile a{
    min-width: 33px;
    margin-right: 5px;
    width: 33px;
    height: 33px;
    border-radius: 6px;
    background: #E6FAF8;
    display: flex;
    align-items: center;
    justify-content: center;
}
.edit-profile a .material-icons{
    color: #219186;
    font-size: 18px;
}
.actions_user .user_delete{
    min-width: 33px;
    margin-right: 5px;
    width: 33px;
    height: 33px;
    border-radius: 6px;
    background: #FFEFF2;
    border: 1px solid #FFEFF2;
    display: flex;
    align-items: center;
    justify-content: center;
}
.actions_user .user_delete .material-icons{
    color:#F93154;
}
.actions_user .user_delete:hover, .actions_user .user_delete:focus {
    background: #F93154 !important;
    border: 1px solid #F93154 !important;
}
.actions_user .user_delete:hover .material-icons, .actions_user .user_delete:focus .material-icons{
    color: #FFEFF2;
}
.user_information_form{
    padding: 0 30px 30px 30px;
}
.user_information_form .react-tabs__tab-list{
    background:#E0F9F7;
    padding: 10px;
    border-radius: 8px;
    border-bottom: 0 !important;
    min-height: 70px;
}
.user_information_form .react-tabs__tab{
    color: #219186;
    font-size: 16px;
    font-weight: 400;
    flex: 0 0 33.33%;
    width: 33.33%;
    text-align: center;
    min-height: 49px;
    line-height: 37px;
}
.user_information_form .react-tabs__tab--selected{
    background: #219186;
    color:#fff;
    font-weight: 500;
    border: none;
    border-radius: 8px;
}
.inputform_box label, .inputform_box .fileuplod span{
    margin: 0 !important;
    padding: 0 !important;
    font-size: 12px;
    color: #495057;
    font-weight: 500;
    letter-spacing: 0.00938em;
}
.inputform_box .fileuplod span svg{
    font-size: 15px;
}
.inputform_box input::placeholder{
    color: #495057;
    font-size: 14px;
}
.user_information_form .form-group{
    margin-bottom: 24px;
}
.user_information_form  .inputform_box .form-control{
    margin: 0 !important;
    width: 100% !important;
    min-height: 48px !important;
    border-radius: 8px !important;
    border: 1px solid #ADB5BD ;
    line-height: 35px;
    min-width: 100% !important;
    padding-left: 14px;
    padding-right: 14px;
    font-weight: 400;
    color: #495057;
}
.user_information_form  .inputform_box .form-control:disabled, .form-control[readonly] {
    background-color: #EDF4FA !important;
    border:1px solid  #EDF4FA !important;
    opacity: 1;
    font-weight: 400;
    color: #6C757D;
  }
.phoneNo_input .react-tel-input .form-control{
    padding-left: 45px;
}
.phoneNo_input .react-tel-input .flag-dropdown{
    background: #EDF2F7;
    border-color:#ADB5BD;
    border-radius: 8px 0 0 8px !important;
}
.user_information_form  .inputform_box #attachment{
    margin-top: 0;
}
.user_information_form  .inputform_box .uploaded-file .material-icons{
    font-size: 15px;
    position: relative;
    top: 2px;
    left: 5px;

}
.user_information_form  .inputform_box  #attachment .file-wrapper{
    padding: 0 !important;
    border: 0 !important;
    background: none !important;
    min-height: auto;
}
.user_information_form  .inputform_box  #attachment .file-wrapper label{
    margin: 0 !important;
    width: 100% !important;
    min-height: 48px !important;
    border-radius: 8px !important;
    border: 1px solid #ADB5BD;
    line-height: 35px;
    min-width: 100% !important;
    padding-left: 14px;
    padding-right: 14px;
    font-weight: 400;
    color: #495057;
    position: relative;
}
.user_information_form  .inputform_box  #attachment .file-wrapper label p{
    margin: 0;
    padding: 0;
    text-align: left;
    width: 100%;
    padding: 0 15px;
    min-height: 42px;
}
.user_information_form  .inputform_box  #attachment .file-wrapper  .attachment-icon{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-top: 0;
}
.user_information_form .pay-options select.leavchose{
    background-color: #EDF4FA !important;
    min-height: 49px;
    border-radius: 8px !important;
    max-width: 230px;
    width: 100%;
    float: right;
}
.credentials_card .profile-fields{
    padding: 0 30px 30px 30px!important;
}
.credentials_card .profile-fields label{
    margin: 0 !important;
    padding: 0 !important;
    font-size: 12px;
    color: #495057;
    font-weight: 500;
    letter-spacing: 0.00938em;
}
.credentials_card select.form-control{
    margin: 0 !important;
width: 100% !important;
min-height: 48px !important;
border-radius: 8px !important;
border: 1px solid #ADB5BD;
line-height: 35px;
min-width: 100% !important;
padding-left: 14px;
padding-right: 14px;
font-weight: 400;
color: #495057;
}
.credentials_card {
    margin-bottom: 30px !important;
}
.add-note-block{
    margin: 0 auto;
    
}
.credentials_card .enable-disable-profile .her_title{
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-bottom: 20px;
}
.salary-revision .her_title{
    padding-left: 0  !important;
    padding-right: 0 !important;
    padding-bottom: 24px;
}
.user_information_form #react-tabs-1{
    margin-top: 30px;
}
@media(max-width: 1024px) {
    button.deletbtn{
        margin-right: 0;
        margin-top: 10px;
    }
    .add_new_userbtn{
        width: 100px;
        margin-top: 10px;
    }
    .plzro .search_btn {
        right: 16px !important;
      }
}

@media(max-width: 768px) {
    button.deletbtn{
        margin-right: 0;
        margin-top: 0px;
    }
    .add_new_userbtn{
        width: 100px;
        margin-top: 0px;
    }
}

.profile-settings .form-check-input {
    margin-top: -16px;
    margin-left: -1.25rem;
    margin-right: 37px !important;
}
.profile-settings
{
    line-height: 30px;
}
.flock-channel
{
    margin-top: 10px;
}
.error-field{
    border:1px solid red;
}
.blocked-msg
{
    margin: 10px 0px;
    color: red;
}
.hub-comments-view .comment-text{
    word-wrap: anywhere;
    width: 100%;
}
.refrshBtn{
    width: 40px;
    height: 40px;
    min-width: 40px;
    border-radius: 8px;
    border: 1px solid #dee2e6;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding: 0 !important;
    margin-left: auto;
    margin-top: 10px;
}
.refrshBtn:hover{
    background: #219186;
    border-color: #219186;
}
.refrshBtn:hover .material-icons{
    color: #fff;
}