/*
* Profile Page
*/
.footer_btns{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.delete-button{
  top: -11px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
.choose-file{
  width: 100% !important;
 
}
.ok_brn{
  background: #219186;
 border: 0 !important;
  min-height: 50px;
border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
}
.choose_imag_btns{
  width: 100px;
  height: 100px;
  margin: 0 auto;
  position: relative;
}
.profile-pic{
  width: 100px;
  height: 100px;
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #219186;
  position: relative;
  top: 15px;
  margin: 0 auto;
  opacity: 1 !important;
}
.profile-pic:hover, .profile-pic:focus{
  background: #004c3f !important;

}
.createAvatarDiv_content {
  margin-top: 35px !important;
}
.previewDiv{
  display: flex;
  align-items: center;
  justify-content: center;
  background: #000;
  width: 150px;
  height: 150px;
  margin: 10% auto;
}
.previewDiv img{
  width: 150px;
  height: 150px;
}
.konvajs-content{
  width: 100% !important;
  min-width: 270px !important;

  overflow: hidden;
  position: relative;
}
.konvajs-content canvas{
  width: 100% !important;

  overflow: hidden;
  position: absolute;
}
#avatar_container-ef78-b505-d166{
  background: none !important;
}

#profile {
  margin-top: 100px;
}

.profile-info {
  padding: 30px;
  text-align: center;
}
.profile-info h4{
  font-size: 16px;
  color: #212529;
  font-weight: 500;
  margin: 0;
  padding: 0;
}
.profile-info  .pr-desig{
  color: #777986;
  font-size: 15px;
  padding: 5px 0 30px 0;
  margin: 0;
}
.pr-pic {
  position: relative;
  width: 120px;
  height: 120px;
  margin: 30px auto;
  background: #dfdfdf;
  border-radius: 200px;

}

.pr-pic:hover .avatar-img {
  opacity: 0.5;
}
.profile-info .pr-email-address, .profile-info  .pr-shift-time, .profile-info  .pr-doj, .profile-info  .pr-ro{
  margin: 0;
  padding: 0 0 25px 0;
  color: #777986;
}

/* .pr-pic:hover:hover .edit-icon {
  display: block;
} */

.edit-icon {
  position: absolute;
  width: 45px;
  height: 45px;
  background: #069482;
  border-radius: 45px;
  padding: 9px;
  top: 66px;
  right: -22px;
}

.profile-edit {
  width: 100%;
  height: 100%;
  padding:0 30px 30px 30px;
}

.pr-details * {
  text-align: center;
}

.pr-details p {
  color: #bcbcbc;
  margin-bottom: 0;
}

.pr-details .pr-data {
  color: #979797;
  font-size: 18px;
}

.pr-details .pr-divider {
  width: 80px;
  height: 1px;
  display: block;
  margin: 25px auto;
  background: #f2f1f1;
}

.pr-l1 input,
.pr-l2 input,
.pr-l3 input,
.profile-edit input,
#shift_update,
.profile_label {
  height: 50px;
  border: 1px solid #f0efef;
  margin: 13px 0;
}

.profile-edit .row-2 input,
.profile-edit .row-2 label,
#shift_update {
  margin: 30px 0;
}

.profile_pass_alert {
  padding: 0;
  margin: 0;
  border: 0;
  background: transparent;
  color: red;
  margin-top: -23px;
}

#avatar_input {
  width:0;
  height: 0;
  border: none;
  padding: 0;
  font-size: 0
}

.profile_label {
  line-height: 37px;
}

.profile-edit button:hover {
  background: #069482;
  color: #fff
}

.pr-l1 input:first-child,
.pr-l2 input:first-child,
.pr-l3 input:first-child,
.pr-l4 input:first-child {
  margin-right: 15px;
}

/* .pr-l1 input:last-child,
.pr-l2 input:last-child,
.pr-l3 input:last-child {
  margin-left: 15px;
} */

.pr-r-title {
  font-weight: 600;
  color: #636363;
}

.save-personal-info {
  max-width: 200px;
  height: 50px;
  font-size: 16px;
  font-weight: 600;
  display: block;
  width: 100%;
  border-radius: 8px;
  margin-top: 30px;
  background: #219186;
  border-color:#219186 ;
}
button.save-personal-info:hover{
  background:#004c3f !important;
  color: #fff;
  border-color: #004c3f !important;
}
.profile-edit .row-2 > div {
  width: 50%
}

.profile-edit .row-2 > div:first-child {
  margin-right: 15px;
}

.profile-edit .row-2 > div:last-child {
  margin-left: 15px;
}

.profile-edit .row-2 h4 {
  margin-top: 74px;
  text-align: left;
}

.row-2 button {
  width: 100%
}

.full-name input
{
  margin:0 0 10px 0px!important;
}
.profile-edit .form-control{
  border: 1px solid #ADB5BD;
  border-radius: 8px;
  min-height: 48px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0;
}
.profile-edit label{
  display: block;
  font-size: 12px;
  color: #495057;
  margin: 0;
  padding: 0 0 3px 0;
  font-weight: 500;
}
.pr-l1, .pr-l2{
  margin-bottom: 24px;
}
.profile-type-error
{
  text-align: center;
  color: red;
}