/**
 * Header styles
 */
 iframe{
   display: none !important;
 }
 .doto_header{
  position: fixed;
  min-height: 80px; 
  z-index: 999;
  width: 100%;
  background: #fff; 
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;

  padding: 0 30px 0 30px;
  -webkit-box-shadow: 0px 4px 32px 0px rgba(108,161,206,0.12);
  -moz-box-shadow: 0px 4px 32px 0px rgba(108,161,206,0.12);
  box-shadow: 0px 4px 32px 0px rgba(108,161,206,0.12);
}
 .alert_messages {
  width: 99.2vw;
  background: #Ff1000;
  position: fixed;
  z-index: 100;
  left: 0;
  right: 0;
  top: 80px;
  color: #fff;
  text-align: center;
  padding: 10px;
 }
 .alert_messages p{
   margin: 0;
   padding: 0;
 }
 .alert_messages  .closebtn{
   width: 25px;
   height: 25px;
   background: #fff;
   border: none;
   margin: 0;
   position: absolute;
   right: 8px;
   top: 8px;
   z-index: 99991;
 }
 .alert_messages  .closebtn .material-icons{
   font-size: 15px;
   color: #F93154;
 }
 .user-cont .icon {
  width: 30px !important;
  height: 30px !important;
  padding: 3px;
  border-radius: 10px;
  background: #f2f2f2;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-cont .icon img {
  width: 17px !important;
  height: 18px !important;
}
 .main-header-cls{
  margin-top: 0px;
  }


/* header {
  padding: 25px 0;
  /* box-shadow: 12.287px 8.604px 25px 0px rgba(145, 145, 145, 0.1); */
  /* background: #fff; 
  border-bottom: 1px solid #f0efef;
	max-width: 1100px;
  margin: 0 auto 50px auto;
  position: relative;  
} */


header select {
  border-radius: 50px;
}

header .doto-logo {
  width: 150px;
  margin-left: -22px;
}

header .avtar-img img, header .power-icon img, header .settings-icon img {
  width: 27px;
}



header .bell-icon img {
  width: 24px;
}

.welcome-time-box > p {
  padding-top: 17px;
}

header .user-cont {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
  margin-left: 15px;
}

header .user-cont p {
  margin-bottom: 0;
  margin-right: 15px;
}

header .welcome-time-box span {
  font-size: 18px;
  color: #636363
}

header .welcome-time-box .time {
  margin-left: auto;
}

header .welcome-time-box .year {
  margin-right: 25px;
}

header .welcome-time-box {
  height: 100%;
  align-items: center;
}

header .welcome-message {
  font-size: 17px;
  font-weight: 600;
  color: #212529;
  display: flex;
  align-items: center;
  /* padding-right: 31px;
	background: url(../../images/smilly.png) no-repeat center right;
	background-size: 21px auto;
	line-height: 24px; 
	display: inline-block; */
}
header .welcome-message  span{
  font-size: 17px;
  color: #6C757D;
  font-weight: 400;
  padding-right: 6px;
}
header .user-cont .welcome-message {
  margin: 0 15px 0 0 !important;
}

/* header .user-cont img:last-child {
  margin-right: 0 !important;
} */

.logo-container {
  align-items: center;
  align-content: center;
}

.logo-container p {
  margin-bottom: 0;
}

.react-datetime-picker {
  border-radius: 50px;
}
/*
.top-bar-active  .doto_header{
  top: 60px;
}
.top-bar-inactive  .doto_header{
  top: 0px !important;
}
 .top-bar-active  .theme_active_header{
  margin-top: 160px !important;
}
.top-bar-inactive  .theme_active_header{
  margin-top: 0px !important;
} */


.header_rightbox{
   display: flex;
   align-items: center;
   justify-content: flex-end;
}
.sprate_border{
  color: #ADB5BD !important;
  margin: 0 10px;
  font-weight: 200 !important;
}
.header_datentime span{
  color: #6C757D;
  font-size: 14px;
  font-weight: 500;
}
.header_dropdown{
  min-width: 200px;
}
.header_dropdown button{
  background: #EFF5F9;
  max-width: 200px;
  width: 100%;
  border: none;
  display: flex;
  border-radius: 8px;
  padding: 5px !important;
  color: #212529;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 43px;
  margin-left: 10px;
}
.header_dropdown button img{
  width: 33px;
  height: 33px;
  border-radius: 8px !important;
  margin-right: 10px;
}
.header_dropdown button:hover{
  background: #EFF5F9;
  color: #212529;
}
.header_dropdown button:focus{
  background: #EFF5F9 !important;
  color: #212529 !important;
  box-shadow: none;
}
.noty_btn{
  margin-right: 20px;
}
.popover{
  max-width: 200px;
  width: 100%;
  margin: 5px 0 0 0;
  padding: 8px;
  border: 1px solid #EBF3FB;
  border-radius: 8px;
  box-shadow: 0px 4px 32px 0px rgba(108,161,206,0.12);

}
.popover .arrow::after, .popover .arrow::before{
  display: none;
}
.popover-body{
  padding: 0;
}
.popover-body a{
  background-color: transparent;
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  text-decoration: none;
  color: #212529;
}
.popover-body a svg{
    margin-right: 8px;
}
.popover-body a:hover{
  background: #F2FCFA;
  color: #219186;
}
.mobileflex .menuicon_box{display: none;}
.mobileflex .mobile-none{display: block;}
/**********Media Quries*****************/
@media only screen and ( max-width: 576px ) {  

  /* header .welcome-time-box .time {
    text-align: left !important;
    display: block;
  } */

  header .welcome-time-box span {
    font-size: 14px;
    text-align: center !important;
    float: initial !important;
  }

  header .welcome-time-box {
    padding: 0;
    text-align: center;
    float: initial !important;
  }
.welcome-message{
  display: block;
max-width: 170px;
margin: 0 auto;
width: 100%;
display: block;
margin: 0;
max-width: 100%;
}
.welcome-message{
  background-image: none !important;
  padding-right: 0 !important;
}
  header {
    width: 100%;
    box-shadow: 12.287px 8.604px 25px 0px rgba(145, 145, 145, 0.1);
    background: #fff;
    width: 100%;
    max-width: 100%;
    margin-bottom: 15px;
  }
header .welcome-time-box span.time{
  text-align: center !important;
}
  .welcome-time-box > p {
    padding-top: 6px;
  }
  
  header .doto-logo {
    width: 97px;
  }

  header .user-cont .icon {
    padding: 6px;
  }

  header .user-cont .icon > img {
    width: 20px;
  }

  header .user-cont .icon.bell-icon > img {
    width: 17px;
  }
  .container{padding:0px;}
  header .container{padding:0 15px;}
  .welcome-time-box .space.space-12{
    width: auto !important;
  }
  .doto_header{
    padding:0 10px;
  }
  #new-side-menu{
    top: 123px;
  }
}
@media screen and (max-width: 1024px) {
  .welcome-message, .header_datentime{display: none !important;}
  .mobileflex .menuicon_box{display: block;}
  .mobileflex .mobile-none{display: none;}
}
@media screen and (max-width: 768px) {
  
  header {
    margin-bottom: 15px;
  }
  header .welcome-time-box {
    padding:0;
    flex-wrap: wrap;
 
}
header .welcome-time-box span{
  font-size: 14px;
}
header .welcome-time-box span.time{
  text-align: left ;
  float: initial !important;
  margin: 0;
}
.doto_header{
  padding-top: 10px;
}
}
@media (max-width: 992px) {
  .welcome-message, .header_datentime{display: none !important;}
 
  
}

.logout-me
{
  cursor: pointer;
  display: flex;
  padding: 12px;
  border-radius: 6px;
}

.logout-me:hover
{
 background:#F2FCFA;
 color:#219186;
}
.logout-me .MuiSvgIcon-root
{
  margin-right: 10px;
}
.header_search {
  margin-left: 20px;
  margin-top:3px;
}
.header_search .btn-search{
  background-color: #EFF5F9 !important;
  border-color: #EFF5F9 !important;
  box-shadow: none !important;
}
@media (max-width: 1280px){
  .header_search .search_box_bc_module {
  max-width: 1220px !important;
  width: calc(93vw - 0) !important;
  }
  .nsm_brand_logo {
    min-width: 180px !important;
   }
}
