/* Employee Leave Status */
.leave-balance-details .leave-status-card .leave-status-col.status {
  width: 16%;
}
.leave-balance-details .leave-status-card .leave-status-col.day
{
  width: 18%;
}
.leave-balance-details .leave-status-card .leave-status-col.type {
  width: 16%;
}

.leave-balance-details .single-user-balance
{
  margin-bottom:40px;
}

.leave-balance-details .leave-status-card
{
  margin-bottom: 15px;
}

.issue-card .btn
{
  padding:0px 10px;
  height: 25px;
}
.issue-card.head
{
    padding: 15px;
    padding-bottom: 10px;
}

.total-emp-leave .btn-green.btn-small
{
  padding: 0px 10px;
  font-size: 16px;
}
.leave-days-col
{
  width: 30%!important;
}