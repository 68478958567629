/* 
  Name: Sidemenu.css
  Path: components/sidemenu
*/

#side-menu {
  padding-top: 0px;
  position: fixed;
  left: 0;
  top: 0;
  width: auto;
  display: block;
  height: 100%;
  background: #fff;
  box-shadow: 12.287px 8.604px 25px 0px rgba(179, 179, 179, 0.1);
  z-index: 9999;
}

.menu-trigger {
  position: absolute;
	top: 76px;
	z-index: 99;
	right: -25px;
	width: 25px;
	height: 25px;
}

.menu-icon .cont svg {
  margin: 0 auto;
  display: block;
}

.menu-icon .cont {
  padding: 15px 15px;
  z-index: 9999;
  position: relative;
  background: #fff;
  color: #dbdbdb;
  border-bottom: 1px solid #f0efef;
  cursor: pointer;
}

.menu-icon .cont:hover {
  background: #069482;
}

.menu-icon {
  position: relative;
  color: #dbdbdb;
  display: block;
}

.menu-icon, .menu-icon svg, .menu-icon:after {
  transition: all 0.2s ease-in;
}

.menu-icon .cont:hover, .menu-icon .cont:hover svg path {
  color: #fff !important;
  fill: #fff !important;
}

.menu-icon:hover, .menu-icon.active .cont {
  border-bottom-color: #069482;
}

.menu-icon.active .cont {
  color: #fff;
  background: #069482;
}

.menu-icon .arrow {
  content: '';
  position: absolute;
  top: 50%;
  right: 10px;
  width: 20px;
  height: 20px;
  display: block;
  background: #069482;
  transform: translate(0, -50%) rotate(45deg);
  z-index: -999;
  display: block;
  visibility: hidden;
}

.menu-icon .arrow {
  transition: all 0.1s ease-in-out;
}

.menu-icon.active .arrow, .menu-icon:hover .arrow {
  visibility: visible;
  right: -10px;
}

.menu-icon.active p, .menu-icon:hover p {
  color: #fff
}

.menu-icon.active svg path {
  fill: #fff !important
}

.menu-icon svg path {
  fill:#dbdbdb !important;
}

.menu-icon p {
  color: #dbdbdb;
  font-weight: 600;
}

#sidemenu-reports {
  position: relative;
}

#reports-submenu {
  position: absolute;
  top: 0;
  right: -180px;
	width: 180px;	
	/* background: #069482; */
  z-index: 999;
  height: 100%;  
  padding-left: 15px;  
}

.sidemenu-submenu-item {
  display: none
}

#reports-submenu a {
  color: #636363;
  display: block;
}

#reports-submenu li {
  list-style: none;
	padding: 0;
	border-bottom: 1px solid #fff;
	width: 97%;
}

#sidemenu-reports ul a:hover {
  text-decoration: none;
}

#reports-submenu {
  box-shadow: 12.287px 8.604px 25px 0px rgba(179, 179, 179, 0.1);
  position: absolute;
  top: 50%;
  right: -180px;
  padding-left: 20px;
	width: 180px;
  z-index: 999;
  height: 100%;
  display: block;
  padding-left: 20px;
  margin: 0;  
  /* transition: all 0.30s cubic-bezier(.17,.67,.9,1.19); */
  transition: all 0.25s cubic-bezier(.43,.56,.83,1.11);
	transform: translateX(-20px) scale(0) skew(55deg, 15deg) rotateX(30deg);
	transform-origin: 0 0;
  opacity: 0;
  z-index: -999;
}
#sidemenu-reports:hover ul {
  top: 0;  
  display: block;
	transform: translateX(0px) scale(1) skew(0) rotateX(0);
	opacity: 1;
}

#reports-submenu a {
  display: block;
  text-decoration: none;
}

#reports-submenu li {
  background: #fcfcfc;
    list-style: none;
    padding: 0;
    width: 180px;
    padding: 14px 15px;
    box-shadow: 8.426px 7.071px 28px 0px rgba(179, 179, 179, 0.2);
}

#reports-submenu li:hover, #reports-submenu li:hover a {
  background: #069482;
}

#reports-submenu li:hover a {
  color: #fff;
}



/**********Media Quries*****************/
@media only screen and ( max-width: 576px ) {
  
}

@media only screen and ( max-width: 768px ) {

}

@media only screen and ( max-width: 992px ) {
  
}

@media only screen and ( max-width: 1024px ) {
  #side-menu {
    padding-top: 0;
    left: -110px;
  }

  #side-menu.mobile-active {
    padding-top: 0;
    left: 0;
  }

  

  /* #side-menu .menu-trigger:after {
    content: '\f0da';
    font-size: 32px;
    display: block;
    font-family: 'fontawesome';
    position: absolute;
    top: 0;
    right: 0;
    color: #069482;
    z-index: 9999;
  } */
}