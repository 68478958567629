.report_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.report_header .row{
    width: 100%;
}
.data_sync_card{
    display: flex;
    padding-right: 30px;
    align-items: center;
}
.data_sync_card p{
    margin: 0;
    padding: 0;
    font-size: 16px;
    color:#212529;
    font-weight: 500;
}
.report_header .refresh-my-boards{
  width:40px;
  height: 40px;
  background: #f2f6f9;
  border-radius: 8px;  
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.data_search_card{
    display: flex;
     justify-content: flex-end;
     position: relative;
     top: 18px;
    
  
}
.data_search_card .rbt-input-main{
    border: 1px solid #ADB5BD;
    min-height: 49px;
    border-radius: 8px;
    padding-left: 15px;
    padding-right: 15px;
}
.data_search_card  .rbt-menu{
    border-radius: 0px 0 8px 8px;
    overflow-x: hidden !important;
    margin-top: -6px;
    border: 1px solid #ADB5BD;
    border-top: 0 !important;
    position: relative;
}
.data_search_card    .rbt-menu::before {
    width: 95%;
    height: 1px;
    background: #DEE2E6;
    content: "";
    position: absolute;
    top: 6;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  .data_search_card  .rbt-menu .dropdown-item{
    padding: 10px 15px;
    color: #212529;
  }
  .data_search_card  .rbt-menu .dropdown-item:hover {
    background: #F3FAFF;
  }
  .data_search_card  .search_btn{
      right: 0;
  }
  .data_search_card .stop-active-search{
      margin: 0;
  }

  .redio_section_box{
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 30px;
  }
  .custom_radio input[type="radio"] + label::before, .custom_radio input[type="radio"] + label::after{
      width: 16px;
      height: 16px;
  }
 