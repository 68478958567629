/*
* BreakCard
*/

.boq.approved .appr-approved-msg {
  margin-top: 0
}

.boq.appr-pending .appr-msg {
  margin-bottom: 33px;
}

.boq.approved {
  border: none;
  box-shadow: 12.586px 15.543px 40px 0px rgba(202, 202, 202, 0.15);  
}