/**
 * Simple Styles
 */
 
 .listcard-single {
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0px 10px 15px 0px rgba(193, 193, 193, 0.2);
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.1s linear;
}

.listcard-single .brand-date {
  font-weight: 16px;
  color: #bdbdbd;
}

.listcard-single .project-name {
  font-weight: 14px;
  color: #636363;
  font-weight: 600;
}

.simple-card {
  height: auto;
  /* transition: all 0.15s ease-in */
}

#date-list-contianer .card {
  border-radius: 5px;
}

#date-list-contianer .card p {
  margin: 0;
}

.simple-card > .card > p {
  font-size: 16px;
  color: #636363
}

#date-list-contianer .card .name {
  width: 500px;
}

#date-list-contianer .simple-card.card-brand .card .name {
  width: 386px;
}

#date-list-contianer .simple-card.card-brand .card .time-entered {
  width: 276px;
}

#date-list-contianer .simple-card.card-brand .card .total-rate {
  width: 190px;
}

#date-list-contianer .card .time-entered {
  width: 310px;  
}

#date-list-contianer .card .total-rate {
  width: 120px;
}

#date-list-contianer .card .total-rate p {
  margin-right: 15px;
}

#date-list-contianer .card .projects {
  width: 250px;
}

.simple-card.user-active > .card > p {
  font-size: 20px !important;
  font-weight: 600;
  color: #069482 !important;
}

.simple-card.user-active > .card {
  box-shadow: none !important;
  border: none !important;
  background: transparent !important;
}

.simple-card.user-active > .row {
  margin-right: 0;
  margin-left: 0;
}

.simple-card.card-brand {
  margin-bottom: 21px;
}

.simple-card.card-brand > .card > p {
  font-weight: 600;
  color: #636363;
}

.simple-card.card-brand > .card {
  box-shadow: 0px 10px 15px 0px rgba(193, 193, 193, 0.2);  
}