/* DownArrow */
#downArrow {
  width: 55px;
  height: 55px;
  border-radius: 55px;
  display: block;
  margin: 0 auto;
  background-size: 20px 12px;
  cursor: pointer;
  transform: translateY(26px)
}