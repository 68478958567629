/* Leave Header  */
#leave_header {
  width: auto;
  text-align: center;
  padding: 10px;
  border-radius: 8px;
  background: #E0F9F7;
  box-sizing: border-box;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
}

#leave_header p {
  width: 33.33%;
  margin: 0;
  font-size: 16px;
  color: #219186 !important;
  font-weight: 400;
  border-radius: 8px !important;
}

#leave_header .leave-menu-anchor {
  color: #219186;
  text-decoration: none !important;
  display: block;
	width: 100%;
	height: 100%;
  min-height: 54px;
  padding: 15px;
  border-radius: 8px;
  text-transform: capitalize !important;
}

#leave_header .menuActive .leave-menu-anchor {
  color: #fff !important;
  border-radius: 8px;
  font-weight: 500;
  text-transform: capitalize !important;
}

.menuActive, #leave_header p:hover, #leave_header p:hover a {
  background: #069482 !important;
  color: #fff !important;
  font-weight: 500;
  text-transform: capitalize !important;
}

#leave_header p:first-child{
  border-top-left-radius: 5px;
}


#leave_header p:last-child {
  border-top-right-radius: 5px;
}

.leave_content_body [type=button]:not(:disabled), 
.leave_content_body [type=reset]:not(:disabled), 
.leave_content_body [type=submit]:not(:disabled), 
.leave_content_body button:not(:disabled) {
  height: auto;
}

.react-datetime-picker__calendar, .react-datetime-picker__clock{
  z-index: 99999 !important;
 }

@media only screen and ( max-width: 576px ) {
  #leave_header .leave-menu-anchor {
    /* text-transform: capitalize !important; */
  }
}