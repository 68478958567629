/* 
  Name: AddNewFeed
  Path: components/Feeds/AddNewFeed
*/

#addNewFeedWrapper {
  height: 49px;
  /* box-shadow: 12.287px 8.604px 25px 0px rgba(179, 179, 179, 0.1); */
  justify-content: center;
}

.addNewFeedAnchor, #addNewFeedWrapper {
  margin-bottom: 15px;
  position: relative;
}

#addNewFeedWrapper.sticky {
  width: 635px;
  position: fixed;
  top: 25px;
  z-index: 9999;
}

#addNewFeed_input.form-control {
  width: 100%;
  height: 100%;
  border-radius: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: none !important;
  border-color: #ADB5BD;
  font-size: 14px !important;
  min-height: 58px;
  border-radius: 8px;
  color: #495057;
  font-weight: 400;
  position: relative;
}

#addNewFeed_input.form-control:focus {
  border-color: #069482;
}

.addNewFeed_label {
  margin: 0;
  height: 45px;
  width: 20%;
	background-color: #069482;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	text-align: center;
	line-height: 33px;
	font-size: 16px;
	color: #fff;
  position: absolute;
  right: 6px;
  top: 6px;
  border-radius: 8px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}


.addNewFeed_label:hover {
  background: #08796b
}