/* Leave Apply css */

#leave_apply {

}

.leave_content_body .row-1 > div,
.leave_content_body .row-2 > div,
.leave_content_body .row-3 > div {
  width: 33.333%
}

.leave_content_body .row-1 > div,
.leave_content_body .row-2 > div,
.leave_content_body .row-3 > div {
  margin: 0 15px;
}

.leave_content_body .row-1 > div:first-child,
.leave_content_body .row-2 > div:first-child,
.leave_content_body .row-3 > div:first-child {
  margin-left: 0;
}

.leave_content_body .row-1 > div:last-child,
.leave_content_body .row-2 > div:last-child,
.leave_content_body .row-3 > div:last-child {
  margin-right: 0;
}

.leave_content_body .row-1,
.leave_content_body .row-2,
.leave_content_body .row-3 {
  margin-bottom: 35px;
}

.leave_body .title, #leave_balence .title, #leave-balence .title {
  display: none;
}

.leave_body {
 padding: 0 30px 30px;

}
.leave_body .text-area label{
  margin: 0 !important;
  padding: 0 !important;
  font-size: 12px;
  color: #495057;
  font-weight: 500;
  letter-spacing: 0.00938em;
  display: block;
  width: 100%;
}
.leave_content_body .form-group label{
  margin: 0 !important;
  padding: 0 !important;
  font-size: 12px;
  color: #495057;
  font-weight: 500;
  letter-spacing: 0.00938em;
  display: block;
  width: 100%;
}
.leave_content_body .attch-items{
  display: flex;
}
.leave_content_body .attch-items .bg-medium-attachment{
  display: flex;
  width: 100%;
  gap: 10px;
  background: #fff;
  align-items: center;
}
.leave_content_body .attachment-items > div {
  width: 100%;
  display: flex;
}
.leave_content_body #attachment label{
  padding: 15px !important;
}
.leave_content_body .attch-items .attach_img_close{
  max-width: 100% !important;
  width: 100% !important;
}
.leave_content_body .attch-items .remove_img_btn{
  top: -7px;
  left: -8px;
}
.leave_content_body .attch-items .bg-medium-attachment img{
  width: 60px;
  height: 60px;
  min-width: 60px;
  object-fit: cover;
}

.leave_content_body .react-datetime-picker{
  width: 100%;
  min-height: 49px !important;
  border-radius: 8px !important;
  border: 1px solid #ADB5BD !important;
  margin: 0 !important;

}
.leave_content_body select.form-control{
  border-radius: 8px !important;
  border: 1px solid #ADB5BD;
  min-height: 49px;
  color: #495057;
  font-size: 14px;
  font-weight: 400;
}

/* .leave_body input, .leave_body select {
  padding-left: 16px;
} */

/* .leave_body input[type="text"], .leave_body input[type="date"], .leave_body input[type="number"], .leave_body select,
 #cc-people .people,
.leave-details-wrapper {
  /* border: 1px solid #f8f8f8; 
  height: 36px;
} */

.leave-details label{
  margin: 0 !important;
  padding: 0 !important;
  font-size: 12px;
  color: #495057;
  font-weight: 500;
  letter-spacing: 0.00938em;
  display: block;
  width: 100%;
}
.leave-details-wrapper{
  width: 100%;
  min-height: 49px ;
  border-radius: 8px ;
  border: 1px solid #ADB5BD ;
  margin: 0 ;
}
.leave-details-wrapper p{
  margin: 0;
}
.leave_content_body .cols {
  width: 260px;
  margin: 0 35px
}

.leave_content_body .col-one {
  margin-left: 0;
}

.leave_content_body .col-three {
  margin-top: 111px;
  margin-right: 0;
}
.leave_content_body  .react-datetime-picker__wrapper {
  border: none;
  line-height: 35px;
}
.leave_content_body .form-group{
  margin-bottom: 25px;
}
#attachment input {
  width: 0px;
  height: 0px;
  font-size: 0;
}
#attachment{
  margin-top: 10px;
}
#attachment .file-wrapper {
  padding: 15px;
  background: #F3FAFF;
  border:1px dashed #CED4DA;
  border-radius: 8px;
  min-height: 97px;
}
#attachment .file-wrapper img{
  width: 40px;
}
#attachment p {
 color: #495057;
 font-size: 12px;
 font-weight: 500;
 margin: 0;
 padding: 0;
}

#attachment label {
  margin-bottom: 0;
  display: flex;
flex-direction: column-reverse;
align-items: center;
}
#attachment label p{
  color: #6C757D;
  font-size: 12px;
  font-weight: 400;
  padding-top: 8px;
}
#attachment .attachment-icon {
  margin-left: auto;
}



#cc-people .btn-add-cc-person {
  width: 49px;
  height: 49px;
  position: absolute;
  top: -1px;
  right: -1px;
}
#cc-people .btn-add-cc-person:focus{
  background: #069482 !important; 
  border-color: #069482 !important;
}
#cc-people {
  position: relative;
}

#cc-people .people {
  padding: 0 18px;
  width: 100%;
  border: 1px solid #ADB5BD;
  min-height: 49px;
  height: auto;
  margin-right: 12px;
  border-radius: 5px;
  position: relative;
  border-radius: 8px;
}

#cc-people .people p {
  margin: 0;
  line-height: 36px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  flex: 1;
}

.add-cc-people {
  width: 8px;
  height: 8px;
  display: block
}

#cc-people .cc-person {
  margin-right: 4px;
  cursor: pointer;
}

.cc-person-wrapper {
  margin-right: 8px;
}

.btn-add-cc-person {
  line-height: 0;
  border-radius: 8px;  
}

/* Date Time Picker styles */
#leave_apply .react-datetime-picker {
  border: 1px solid #f8f8f8;
  height: 36px;
  border-radius: 5px;
  width: 100%;
}

#leave_apply .react-datetime-picker input {
  height: auto;
  border: none;
  padding-left: 0;
}

.react-datetime-picker__inputGroup__input--hasLeadingZero {
	padding-left: calc(1px + 0.54em) !important;
}

#leave_apply .calender-icon {
  width: 18px;
  height: auto;
}
/* 
#to-date {
  margin-top: 83px;
} */
.leave-app-for {
  line-height: 45px;
  padding: 0 15px;
  color: #495057;
  font-size: 14px;
  font-weight: 400;
}
#leave_apply .leave-bal {
  padding: 0 10px;
  line-height: 36px;
}

#leave_apply .leave-bal p:last-child, .leave-app-for p:last-child {
  margin-left: auto
}

#leave_apply .leave-bal {
  margin-bottom: 45px;
}

textarea.leave-reason-textarea {
  width: 100%;
  height: 140px;
  border-radius: 8px;
  border: 1px solid #CED4DA;
  resize: none  ;
  padding: 15px;
  font-weight: 400;
}
textarea.leave-reason-textarea::placeholder{
  font-size: 14px;
  color: #6C757D;
  font-weight: 400 !important;
}
button.btn-leave-submit {
  height: 49px !important;
  min-width: 200px;
  border-radius: 8px;
  text-align: center;
  line-height: 36px;
  padding: 0;
  align-self:flex-end;
  color: #fff !important;
  background: #219186;
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.00938em;
}
button.btn-leave-submit:hover{
  background: #16746B;
  color: #fff;
}
button.btn-leave-submit:focus{
  background: #16746B !important;
}
.leave_submit-btns {
  text-align: center;
	margin-top: 25px;
}
.leave-details-wrapper  .leave-bal{
line-height: 45px;
color: #495057;
font-size: 14px;
font-weight: 400;
}

.leave_submit-btns button {
  margin: 0 15px;
}
#contact-details .form-control{
  border-radius: 8px !important;
  border: 1px solid #ADB5BD;
  min-height: 49px;
  color: #495057;
  font-size: 14px;
  font-weight: 400;
}
.cc-all-peoples.show-all{
  display: block;max-height: 155px;
  /* overflow-y: scroll; */
}
.leave_body .cc-all-peoples{
  min-width: 98%;
  border: 1px solid #EBF3FB;
  border-radius: 8px;
  box-shadow: 0px 4px 32px 0px rgba(108,161,206,0.12);
}
.over-limit-leave
{
  border:1px solid red;
}

.leave_body .field-error{border:1px solid red!important;}

@media only screen and ( max-width: 576px ) {
  .leave_body {
    padding: 15px
  }
  .leave_content_body .row-1, .leave_content_body .row-2, .leave_content_body .row-3 {
    flex-direction: column;
    margin-bottom: 15px;
  }

  .leave_content_body .row-1 > div, .leave_content_body .row-2 > div, .leave_content_body .row-3 > div {
    width: 100%;
    margin: 0 0 10px 0;
  }
}

.leave-hidden-field select, .leave-hidden-field input,
.leave-hidden-field .react-datetime-picker__calendar-button,
.leave-hidden-field .react-datetime-picker,
.leave-hidden-field .leave-details-wrapper,
.leave-hidden-field .file-wrapper,
.leave-hidden-field#leave-cc button,
.leave-hidden-field textarea,
.leave-hidden-field.leave_submit-btns button   {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
}

#attachment .attchfiles_wrap {
  background: none;
  padding: 0px;
  border-radius: 12px;
  margin-bottom: 25px;
  box-shadow: none;
  margin-top: 10px;
  border: 0px;
}