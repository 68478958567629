/*
* AlertMessage
*/

#alert-message {
  border: 1px solid #f39a27;
  border-radius: 5px;
  background: #fff;
  box-shadow: 12.287px 8.604px 25px 0px rgba(179, 179, 179, 0.1);
  height: 50px;
  margin: 10px 15px;
  /* margin-left: 32px; */
  width: 100%;
}

#alert-message.alert-orange {
  border: 1px solid #f39a27;
}

#alert-message.alert-red {
  border: 1px solid #c34c4b;
}

#alert-message.alert-red .alert-type {
  background: #c34c4b;
}

#alert-message.alert-dr-orange {
  border: 1px solid #d56130;
}

#alert-message.alert-dr-orange .alert-type {
  background: #d56130;
}

#alert-message {
  width: 100%
}

#alert-message p {
  margin: 0;
}

.alert-type {
  position: relative;
  width: 25%;
  font-size: 16px;
  color: #fff;
  padding: 0 15px
}

.alert-type svg {
  margin-right: 8px;
  font-size: 18px
}

.alert-type:after {
  content: '';
	position: absolute;
	right: -5px;
	top: 50%;
	width: 15px;
	height: 15px;
	background: inherit;
	transform: translateY(-50%) rotateZ(45deg);
	display: block;
}

#alert-message.alert-orange .alert-type {
  background-color: #f39a27
}

.alert-type, .alert-message {
  align-items: center;
}

.alert-message {
  font-size: 15px;
  margin: 0 19px;
  width: 75%
}

.alert-message p {
  margin-right: 15px !important;
}

.alert-message svg {
  font-size: 18px;
  color: #636363;
  cursor: pointer;
}