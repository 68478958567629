/* 
* ApprovalCommentForm styles
*/

/* .apprCommentBox {
  padding: 30px;
  border: 1px solid rgb(242, 242, 242);
  box-shadow: 12.287px 8.604px 25px 0px rgba(179, 179, 179, 0.1);
  position: absolute;
	top: 30%;
	z-index: 99;
	right: 57%;
	background: #fff;
	width: 340px;
} */

.apprCommentBox {
  padding: 30px;
  border: 1px solid rgb(242, 242, 242);
  box-shadow: 12.287px 8.604px 25px 0px rgba(179, 179, 179, 0.1);
  position: absolute;
  bottom: -100%;
  z-index: 99;
  right: 9px;
  background: #fff;
  width: 95%;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.apprCommentBox.show-form {
  bottom: 10px !important;
}

.apprCommentBox p {
  font-size: 16px;
  font-family: "ProximaNova-Bold";
}

.apprCommentBox textarea {
  height: 100px;
  margin-bottom: 20px;
}

.apprCommentBox textarea:focus {
  border-color: #069482;
	box-shadow: 0 0 0 0.2rem rgba(6, 148, 130, 0.2);
}

.apprCommentBox button {
  width: 100%;
}

.checkbox-wrapper {
  margin-bottom: 10px;
}

.checkbox-wrapper .input-group {
  width: 38%
}

.close-comment-form {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  font-size: 15px;
  font-family: 'Courier New', Courier, monospace;
  background: #dfdfdf;
  color: #000;
  font-family: "ProximaNova-Bold";
  height: 25px;
  width: 25px;
  text-align: center;
  line-height: 25px;
  cursor: pointer;
  border-radius: 25px
}

.close-comment-form:hover {
  background: #000;
  color: #fff;
}