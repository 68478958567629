/*
* CarousalCard
*/

.caro-box {
  max-width: 340px;
  border: 1px solid;
  padding: 30px 34px;
  border-radius: 7px;
  margin: 0 15px;
  background: #fff;
  position: relative;
}

.appr-pending {
  border-color: #7ac5bc;
}

.appr-done {
  border-color: #7ac5bc;
  box-shadow: 12.586px 15.543px 40px 0px rgba(202, 202, 202, 0.15);
}

.appr-approved-msg {
  border-top: 1px solid #ededed;
  padding-top: 15px;
  margin-top: 10px;
}

.appr-approved-msg p {
  margin: 0;
}

.appr-btn-approve {
  width: 100%;
}

.appr-btn-approve:hover {
  color: #fff;
}

/* slick slider reset */
.slick-prev:before,
.slick-next:before {
  color: #7ac5bc;
}
