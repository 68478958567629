.source-management-wrap h5 {
  font: normal normal 600 18px/28px Poppins;
}
.source-management-wrap .task_status_change {
  padding: 14px 24px;
  min-width: 200px;
  max-width: 100% !important;
  height: auto !important;
  width: auto !important;
  margin-left: 0px;
  margin-right: 20px;
}
.source-management-wrap .task_status_change:hover {
  background: #00806c !important;
}
.source-management-wrap .sfm-close-icon {
  position: absolute;
  right: 30px;
  width: 40px;
  height: 40px;
  background: #eff5f9;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  z-index: 99;
}
.source-management-wrap .sfm-close-icon:hover {
  background: #ffeceb;
  border-radius: 6px;
}
.source-management-wrap .sfm-close-icon:focus{
  background: #ffeceb; 
}
.source-management-wrap .sfm-close-icon:focus .material-icons {
  color: #fe2712;
}
.source-management-wrap .sfm-close-icon:hover .material-icons {
  color: #fe2712;
}
.source-management-wrap button.btn-secondary.sfm-close-icon:focus{
  background: #eff5f9 !important;
}
.source-management-wrap button.btn-secondary.sfm-close-icon:focus .material-icons {
  color: #6e7072;
}
.sub-file-route {
  color: #6c757d;
  font-size: 14px;
  margin: 0;
  padding: 0;
  letter-spacing: 0.1px;
  line-height: 20px;
}
.folder-wrapper-som {
  padding: 15px;
  background: #f7fafc;
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
}
.source-management-wrap .folder_box_som {
  margin: 0;
  padding: 0;
  margin-bottom: 35px;
}
.source-management-wrap .uploaded-versions {
  display: flex;
  flex-wrap: wrap;
  gap: 15px !important;
 
}
.source-management-wrap .folder_box_som .folder_name {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  padding: 0 !important;
  justify-content: center;
}
.source-management-wrap .folder_box_som .revision-name {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.11px;
  line-height: 15px;
  max-width: 250px;
}
.source-management-wrap .folder_box_som .revision-date {
  font: normal normal normal 12px/24px Poppins;
  letter-spacing: 0.1px;
  color: #212529;
  opacity: 1;
}
.folder-wrapper-som .material-icons {
  font-size: 50px;
  color: #239186;
  margin-right: 9px;
}
.folder-wrapper-som {
  transition: background 0.5s ease-in-out;
  position: relative;
  box-sizing: border-box;
}
.folder-wrapper-som:hover {
  box-shadow: 0px 8px 12px #6ca1ce29;
  background: #fff;
  cursor: pointer;
}
.revision-bread .bread-link {
  letter-spacing: 0.1px;
  color: #212529;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  padding: 0;
  line-height: 25px;
}
.revision-bread .bread-item {
  color: #6c757d;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
}
.revision-bread .material-icons {
  color: #212529;
}
.folder-wrapper-som:hover .quick_actions_som .quick_action_btn {
  display: flex;
  align-items: center;
  justify-content: center;
}
.quick_actions_som {
  display: flex;
  gap: 5px !important;
  margin-left: 0px;
  transform-style: preserve-3d;
  transition: all 1s linear;
  align-items: center;
  justify-content: center;
}

.quick_actions_som_inner {
  width:100%;
}

.quick_actions_som .quick_action_btn {
  min-width: 28px;
  width: 28px;
  height: 28px;
  border-radius: 4px;
  background: #eff5f9;
  border: 0;
  margin: 0;
  padding: 0;
 display: none;
}
.quick_actions_som .quick_action_btn:hover {
  background: #239186;
}
.quick_actions_som .quick_action_btn:hover .material-icons {
  color: #fff;
}
.quick_actions_som .quick_action_btn:focus {
  background: #eff5f9 !important;
}
.quick_actions_som .quick_action_btn:focus .material-icons {
  color: #fff;
}
.quick_actions_som .quick_action_btn .material-icons {
  font-size: 15px;
  margin: 0;
  color: rgba(0, 0, 0, 0.54);

}

.card-source-management.publisher-active .source-management-wrap
{
  width: 100%;
  display: flex;
}
.card-source-management.publisher-active .source-management-wrap .outer-wrapp
{
  width: 49%;
  height: calc(100% - 108px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.card-source-management.publisher-active .source-management-wrap .outer-wrapp::-webkit-scrollbar {
  display: none;
}
.card-source-management.publisher-active .source-management-wrap .outer-wrapp::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #aaa; /* or add it to the track */
}
.card-source-management.publisher-active .source-management-wrap .outer-wrapp::-webkit-scrollbar-thumb {
  background: #000;
}
.compose_message-container{
  width: 100%;
}
.compose_message-container::-webkit-scrollbar {
  display: none;
}
.compose_message-container::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #aaa; /* or add it to the track */
}
.compose_message-container::-webkit-scrollbar-thumb {
  background: #000;
}
.source-management-wrap .sfm-close-icon{
  margin-top: 24px;
}
.card-source-management.publisher-active .source-management-wrap .outer-wrapp.hub-source
{
  width: 51%;
  /* border-right: 1px solid #ccc; */
  position: relative;
}
.publisher-active .fms_fristbox{
  position: relative;
}
.publisher-active .fms_fristbox::after{
  position: fixed;
  content: "";
  width: 5px;
  background: #f0f6fa;
  bottom: 0;
  top: 0;
  right: 49%;
  height: 100%;
  z-index: 0;
}
.source-360 .revision-files {
  width: 100%;
  max-height: 550px;
}

.source-360 .folder-wrapper-som {
  padding: 0px 15px;
  background: none;
  cursor: pointer;
}

.source-360 .folder_name {
 display: grid;
 font-size: 13px;
}
.btn-default {
  opacity: 0.3;
  cursor: default!important;
}

button.btn-unpublish, button.btn-unpublish:focus, button.btn-unpublish:active {
  opacity: 0.3;
  cursor: default!important;
  background: red!important;
  border: 1px solid red!important;
  cursor: pointer;
}
.btn-unpublish.unpublish360active 
{
  opacity: 1;
  cursor: pointer!important;
}

.published-data
{
  display: grid;
}
.publish360active
{
  opacity: 1;
  cursor: pointer!important;
}
.publish-compose
{
  justify-content: space-around;
}
.compose-360 #new-comment
{
  width: 98%;
  box-sizing: border-box;
  margin-bottom: 30px;
  clear: both;
}

.composer-360 #new-comment
{
  margin-bottom: 60px;

}

.compose-360 #new-comment .ql-container {
  min-height: 130px;
}
.copy-share-items {
  padding: 15px;
  border: 1px solid #ccc;
  display: block;
  border-radius: 15px;
  background: #f6fcfb;
}
.share-buttons
{
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}
.link-expiry
{
  display: flex;
  justify-content: center;
  margin-top:10px;
}
.new-lik-generate-msg
{
  margin-top: 15px;
}
.regenerate-buttons
{
  justify-content: center;
  gap: 30px;
  margin-top: 20px;
  display: flex;
}

.btn-regenerate, .btn-copy-link{
  padding: 6px 14px;
  min-width: 120px;
  max-width: 100% !important;
  height: auto !important;
  width: auto !important;
  border-radius: 6px;
}
.btn-regenerate{
  background: #e0f9f7;
  color: #52aca3;
  border-color: #e0f9f7;
}

.copy-share-items.link-copied {
  background: #069482;
  color: #ffff;
}
.source-folder-items
{
  flex-direction: column!important;
}
.source-folder-actions {
  display: none;
}
.source-folder-items:hover .source-folder-actions
{
  display: block!important;
  border-top: 1px solid #ccc;
  margin-top: 10px;
  padding-top: 10px;
  width: 100%;
}

.public-source-head
{
  margin-top: 30px;
  padding:40px;
  border-radius: 0px;
  margin-bottom: 20px;
}

.public-view-source .revision-files {
  width: 97%;
  max-height: 500px;
  margin:0 auto;
  margin-top: 20px;
}
.public-view-source .attachment-bg.bg-small {
  border: 0px solid #fff;

}
.public-view-source .source-folder-items {
  flex-direction: row!important;
}

.public-view-source  .source-folder-items:hover .source-folder-actions {
  width: auto;
  margin-left: 10px;
  border: 0px;
}

.public-view-source .key-expired
{
  width: 100%;
  height: 200px;
  align-items: center;
}
.view-source-bread
{
  width: 97%;
  margin: 0 auto;
}
.view-source-bread .bread-link
{
  cursor: pointer;
}
.preparing-files
{
  width: 100%;
  margin:15px 0px;
  font-weight: bold;
}

.download-all-source
{
  display: flex;
  justify-content: flex-end;
  margin-right: 0px;
  cursor: pointer;
}
.copy-share-wrap.folder_box_som
{
  gap:20px;
  flex-wrap: wrap;
}
.move-here-source
{
  margin-top: 30px;
  border-top: 1px solid #ccc;
  padding-top: 20px;
}
.move-here-source .actions
{
  justify-content: space-between;
}
.not-allowed-msg
{
  color:red;
}

.markedasfinal
{
  width: 10px;
  height: 10px;
  border: 1px solid #111;
  padding: 8px!important;
  border-radius: 11px;
}
.marked-as-final
{
  font-weight: 600;
}
.folderwrap
{
  margin-bottom: 30px;
}
.folderwrap .fieldset
{
  display: flex;
  margin-bottom: 15px;
}
.master-folders
{
  flex-wrap: wrap;
  gap:10px;
}
span.master-folder-items {
  background: #dedede;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer;
}
span.master-folder-items.folder-selected {
  background: #069482;
  color: #fff;
}

.source-management-wrap .folder_box_som.approved {
  border: 2px solid #239186;
    border-radius: 6px;
}
.approved-folder-files{
  background: #eff5f9;
    border-radius: 6px;
    margin-bottom: 15px;
    padding: 15px;
}
.sfm_hub_wrapper .approved-folder{
   position: relative;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
}
.sfm_hub_wrapper .approved-folder span{
  font-size: 18px;
  font-weight: 700;
 }
 .sfm_hub_wrapper .approved-folder .downlaod_btn .material-icons{
  width: 30px;
  height: 30px;
  color: #fff;
  padding: 7px;
  font-size: 16px;
 }
 .sfm_hub_wrapper .approved-folder .downlaod_btn{
  width: 30px;
    height: 30px;
    border: 0;
    background: #219186;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    min-width: 30px;
    max-width: 30px;
    margin: 0;
    padding: 0;
}


.sfm_hub_wrapper .approved-folder-files .file-name{
  background: #eff5f9;
  padding: 10px 0;
  border-bottom: 1px solid #d9e1e8
}

.sfm_hub_wrapper .approved-files-wrapper .fileicon-size{
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}
.sfm_hub_wrapper .approved-folder-files .fileicon-size .attachment-bg { 
  margin-left: 0;
  padding: 10px;
  border: 1px solid #239186;
  border-radius: 5px;
}
.sfm_hub_wrapper .approved-folder-files .fileiconsize{
  font-size: 11px;
    margin: 0;
    padding-top: 5px;
    font-weight: 600;
  
}
.sfm_hub_wrapper .file_detail_so{
  display: flex;
  flex-direction: column;
}
.sfm_hub_wrapper .file_detail_so h3{
  font-weight: 500;
  color: #686868;
  font-size: 15px;
  margin: 0;
  padding: 0 0 8px 0;
}
.sfm_hub_wrapper .file_detail_so p{
  font-size: 12px;
  margin: 0;
  padding: 0;
}

button.btn-inactive{
  opacity: 0.5;
  cursor: not-allowed;
}

.folder_box_som1.approved .folder-wrapper-som{
  background: #f2fffe;
  /* border: 2px solid #239186 !important; */
}

.folder_box_som1.approved .folder-wrapper-som .revision-name{
  color: #00806c;
  font-weight: 500;
}
.download_all_text_btn{
  display: flex;
  color: #00806c;
  font-size: 16px;
  line-height: 32px;
  font-weight: 500;
  align-items: center;
  cursor: pointer;
  position: relative;
}
.download_all_text_btn::after{
  width: 100%;
  position: absolute;
  content: "";
  height: 1.6px;
  background: #00806c;
  left: 0;
  bottom: 3px;
  display: none;
}
.download_all_text_btn:hover::after
{
  display: block;
}

.download_all_text_btn .material-icons{
  margin-left: 5px;
  color: #00806c;
  font-size: 18px;
}
.compose-attachments img.compose-attaches
{
  width: 54px;
  height: 54px;
  border-radius: 8px;
  object-fit: cover;
}
.comment-fields-wrap-hub{
  flex-direction: column !important;
}
.compose-attachments.hub-compose-attachments{
  max-height: calc(100% - 0px) !important;
  width: 100%;
}
.compose-attachments {
  padding-top:0;
  margin-bottom: 15px;
  max-height: calc(100% - 385px);
  overflow-y: scroll;
  overflow-x: hidden;

}

.compose-attachments::-webkit-scrollbar {
  display: none;
}
.compose-attachments::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #aaa; /* or add it to the track */
}
.compose-attachments::-webkit-scrollbar-thumb {
  background: #000;
}

.compose-attachments h3{
  color: #212529;
  margin: 0;
  padding: 16px 0 16px 0;
  font-weight: 600;
  font-size: 16px;

}
.attach_img_discription{
  margin-top: 10px;
}
.attach_img_discription .attachment-notes{
  resize: none;
}
.compose-attach-items
{
  display: flex;
  padding: 15px 0;
  border-bottom: 1px solid #6CA1CE1F;
}
.compose-attach-items > div{
  width: 100%;
}
.compose-attach-items img{
  border-radius: 8px;
}
.compose-attach-items .items
{
  gap: 20px;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
span.compose-file-ext {
  background: #ececed;
  padding: 10px 37px;
  font-weight: bold;
  text-transform: uppercase;
}
.compose-attach-items .items .unlink-attach {
  cursor: pointer;
}
.compose-attach-items .items .unlink-attach .material-icons
{
  color: #fe2712; 
  cursor: pointer;
}
.compose-project-data
{
  display: inline-block;
  margin-right: 10px;
}
.source-file-actions{
  gap: 10px;
}
.source-file-actions .material-icons{
  color: #ADB5BD;
  font-size: 22px;
} 
.compose-file-name{
  display: flex;
  align-items: center;
  color: #212529;
  font-size: 14px;
  font-weight: 500;
  margin-left: 15px;

}
.actions-grop-table{
  display: flex;
  gap: 10px;
}
.actions-grop-table .copytoclipboard{
  align-items: flex-end;
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  height: 22px;
}
.actions-grop-table .copytoclipboard span{
  height: 20px;
}
.actions-grop-table  .abottom{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 25px;
}

.actions-grop-table .move-icon,
.actions-grop-table .delete-source-file
{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  height: 26px;
}

.uploaded-versions.d-flex.publish360.row {
margin-top:10px;
}

.master-folders .folders {
  flex-wrap: wrap;
  grid-gap: 10px;
  gap: 10px;
}
.master-folders.other-folders-wrap
{
  justify-content: space-between;
}
.add-other-folder
{
  display: block;
  background: #219186;
  padding: 5px 13px;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  border-radius: 6px;
  cursor: pointer;
  min-width: 42px;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;

}
.add-other-folder i{
  color: #fff !important;
}
.add-other-folder:hover{
  background: #00806c !important;
}
.otjer-folder-creation{
  display: flex;
  gap: 10px;
}
.add-newfolder-input{
  width: 100%;
  background: #fff;
  border-radius: 6px;
  border: 1px solid #adb5bd !important;
  padding-left: 15px !important;
  padding-right: 15px;
  min-height: 47px;
}
.add-newfolder-input-error
{
  border: 1px solid red !important;
}
.add-folder-add-btn{
  background: #219186 !important;
  color: #fff !important;
}
.add-folder-add-btn:hover{
  background: #00806c !important;
}
.add-folder-add-btn i{
  color: #fff !important;
}
.add-folder-cancel-btn,
.add-folder-add-btn{
  border-radius: 8px;
  background: #FFECEB; 
  align-items: center;
  justify-content: center;
  display: flex;
  border: 0;
  padding: 5px 13px;
  color: #FE2712;
  min-width: 100px !important;
}
.add-folder-cancel-btn:hover{
 background: #FE2712;

}
.add-folder-cancel-btn:hover i{
  color: #fff !important; 
}
.add-folder-cancel-btn i{
  color: #FE2712 !important;
}
.attach-file-withchekbox label{
  padding: 0 0 0 5px;
  color: #6C757D;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif !important;
  letter-spacing: 0.01071em;
  border: 0 !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.attach-file-withchekbox .form-check-input{
  margin-top: 9px;
}
.compose_message-container.with-hub .ql-toolbar.ql-snow{
  border: 0 !important;
  padding: 10px 0 15px 15px;
}
.compose_message-container.with-hub .ql-container.ql-snow{
  border: 1px solid #adb5bd !important;
  border-radius: 12px;
}
.compose_message-container.with-hub .ql-editor.ql-blank::before{
  padding-left: 0;
}

.rejected-file
{
  color:red;
  font-size: 18px;
}
.hub-comments-view .cmd_viewbox{
  border: 1px solid #f4f9fc;
  background: #f7fafc;
  padding: 20px;
  margin-bottom: 50px;
  width: 100%;
}
.hub-comments-view .post-date-time{
  flex-direction: column;
  color: #212529;
}
.hub-comments-view .daten_time{
  display: flex;

}
.hub-comments-view .daten_time .posted-time{
  font-size: 13px;
  color: #BFBFBF;
  font-weight: 400;
}
.hub-comments-view .comd_pop_head .posted-on{
  font-size: 13px;
  color: #BFBFBF;
  font-weight: 400;
  display: flex;
}
.hub-comments-view .comd_pop_head{
  width: 100% !important;
}
.hub-comments-view .attachment-items{
  width: 100%;
}
.show_cmd_btn span{
  border: 1px solid #00806c;
  background: #cde8e3;
  color:#00806c;
  padding:12px 15px;
  border-radius: 8px;
  cursor: pointer;
}
.showcmd-btn-wrapper{
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}
.showcmd-btn-wrapper button.task_status_change{
  margin-top: 0;
}
.revision-files .attachment-type .bg-medium-attachment img
{
  width: 100px;
  height: auto;
}
.attachment_list_revision {
  display: contents;
}
.attachment_list_revision > div {
  display: contents;
}
.revision-files .attachment-type{
  padding-top: 0;
}
.revision-files .filename{
  display: flex;
  flex-direction: column;
}
.revision-files .filename h6 {
  color: #222;
  font-weight: 500 !important;
  margin: 0;
  padding: 0 0 5px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 430px;
}
.revision-files .filename span{
  font-size: 13px;
  color: #6c757d;
}
.source-folder-actions {
  position: absolute;
  bottom: 0;
  top: 70px;
  background: #fff;
  height: fit-content;
}
.modal-dialog.public_share_wrapper
{
  width: 700px;
}

.source-view-folders{
  margin: 15px 0px;
  display: grid !important;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 15px;
}

.source-view-folders .folder-type-name {
  display: block;
  font-weight: 600;
  margin-bottom: 10px;
}
.source-view-folders span.foldername
a {
padding: 4px 13px;
background: #EFF5FA;
border-radius: 5px;
cursor: pointer;
}
.source-view-folders .folder-items
{
gap: 5px;
flex-wrap: wrap;
}

.source-view-folders span.foldername a {
  color: #6C757D;
  display: flex;
}
.cmh_foldercopy_wrap{
  position: relative;
}
.cmh_foldercopy_wrap .folder_remove_btn{
  position: absolute;
  right: -7px;
  top: -12px;
  height: 16px;
  display: none;
  cursor: pointer;
}
.cmh_foldercopy_wrap:hover .folder_remove_btn{
  display: block;
}
.cmh_foldercopy_wrap .folder_remove_btn .material-icons{
  color: red;
  font-size: 18px;
}

.disable-further
{
  opacity: 0.5;
  pointer-events: none;
cursor: default;
}

button.quick_action_btn.multilevel-folder.btn.btn-secondary {
  background: orange;
}

.revision-files .theme_table thead th:last-child {
  padding-right: 30px;
  text-align: left;
}

.comment-new-sec h6{
  margin: 0;
  padding: 0;
  color: #212529;
  font-size: 600;
  font-size: 16px;
}
.comment-new-sec  .down-all-files{
  margin: 0 !important;
  border-radius: 8px;
  color: #fff;
  background: #fff;
  font-weight: 400;
  background: #219186;
  padding: 0px 23px;
  min-height: 36px;
}
.comment-new-sec .comment-view-newstyle .down-all-files:hover{
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
 
}
@media screen and (min-width: 480px) {
  .modal-dialog.addtodo_wrapper.public_share_wrapper {
    max-width: 700px!important;
  }
}
.cross_iconbox input[type="checkbox"]:checked:after{
  background-color: red;
  border: 1px solid red;
  background-image: url(../../../images/close-white.png);
  background-size: 50%;
}

.attach_img_discription .attachment-notes, .comment-fields-wrap textarea, .comment-fields-wrap textarea:hover {
  min-height: 45px;
}
.simple-download
{
  cursor: pointer;
}

.task_detail_footer .save_btn.disable-button, .task_status_change.disable-button
{
  opacity: 0.5;
  cursor:not-allowed;
  pointer-events:none;
}
.versions-left-box{
  display: flex;
  flex-wrap: wrap;
}
.versions-left-box .new-folder-adding{
  min-width: 80px;
  height: 80px;
  border-radius: 4px;
  font-size: 22px;
  color: #fff;
  background: #219186;
  border: none;
  font-weight: 600;
  line-height: 18px;
}
.versions-left-box .new-folder-adding:hover,
.versions-left-box .new-folder-adding:focus{
  background: #5a6268 !important;
  color: #fff !important;
}
.new-folder-adding span {
  font-size: 14px;
  display: block;
  font-weight: 400;
}
.new-version-upload .attchfiles_wrap label{
  min-height: 180px;
  padding-top: 55px !important;
  margin-top: -45px;
}
.upload-ext-error {
  font-weight: 700;
  text-align: center;
  color: #ff1000;
}
.approved-files-wrapper{
  margin-left: 30px;
}
.active-source-folder-actions .quick_actions_som .quick_action_btn{
  display: block !important;
}
.active-source-folder-actions .source-folder-actions{
  display: block!important;
  border-top: 1px solid #ccc;
  margin-top: 10px;
  padding-top: 10px;
  width: 100%;
}

.compose_message-container  .field-error
{
  border: 0px!important;
}

.field-error .ql-editor.ql-blank
{
  border: 1px solid #ff1100!important;
  border-radius: 6px;
}

.card-source-management .revision-files .table-responsive{
  overflow: unset;
}
.card-source-management .revision-files .theme_table > thead{
  position: sticky;
  top: 0;
  z-index: 999;
}
.post-360-button
{
  margin-left: -14px;
}
.bg-medium-attachment.gif-medium
{
  margin-right: 0px;
}
.new-version-upload > .attchfiles_wrap{
  width: 100%;
}
.revidata_newfolder_btn.file-folder-view
{
  right: 340px;
}
.revision-files .filename h6.small-window-view
{
  width: 230px;
}
#card-source-management .ql-editor
{
  min-height: 125px;
}

.compose-attachments.hub-compose-attachments .compose-file-name, .composer-360  .compose-file-name,
.compose-attachments.hub-compose-attachments .compose-file-name p, .composer-360  .compose-file-name p{
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.kfm_popup_close_action{
  position: absolute;
  right: 0;
  top: 24px;
  display: flex;
  min-width: 115px;
  z-index: 99;
}
.kfm_popup_close_action .sfm-close-icon{
  margin-top: 0 !important;
}
.kfm_popup_close_action .sfm-mini-icon{
  width: 40px;
  height: 40px;
  min-width: 40px;
  border-radius: 6px;
  padding: 0;
  margin: 0;
  background: #239186;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
 
}
.kfm_popup_close_action  .sfm-mini-icon .material-icons{
  color: #fff;
}
.compose_message-container.with-hub .ql-mention-list-container-bottom.ql-mention-list-container-bottom-bottom
{
  z-index: 99999999999;
}

.compose_message-container.with-hub .ql-mention-list-container-bottom .ql-mention-list {
  max-height: 219px;
  overflow: hidden;
  background: #fff;
}
.source-view-minimised
{
  display: none;
}

.source-management-wrap .task_status_change.revidata_newfolder_btn {
  margin-right: 34px!important;
  margin-top: 22px;
}

.source-management-wrap .new-version-upload .task_status_change {
  padding: 10px 24px;
  min-width: 200px;
  max-width: 100% !important;
  height: auto !important;
  width: auto !important;
  margin-right: 15px;
}
.comment-text .source-output-other-folder-view
{
  margin-top: 15px;
}
.sticky-parent
{
  position: fixed;
  bottom: 66px!important;
  right: 15px;
  display: flex;
  flex-direction: column;
  grid-gap: 10;
  gap: 10;
  width: 318px;
  z-index: 999999;

}
.upload-progress-sticky {
  width: 318px;
  height: auto;
  background: #1B1E23;
  box-shadow: 0px 24px 16px -16px rgba(112, 147, 196, 0.16);
  border-radius: 8px;
  padding: 15px;
  z-index: 9999999999999999999;
  margin-bottom: 5px;
  border: none;
}
.CircularProgressbar .CircularProgressbar-trail{
  stroke: #219186;
  stroke-linecap: round;
}
.upload-progress-sticky .floating-percentage{
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
  margin-right: 8px;
}
.upload-progress-sticky
{
  display: none;
}
.upload-progress-sticky.show-sticky
{
  display: flex;
  text-align: center;
  align-items: center;
}
.upload-progress-sticky .progress
{
  margin-bottom: 15px;
}
.upload-progess-circle
{
  margin-bottom: 0px;
}
.upload-progress-sticky .uploading-item-name
{
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 0.11px;
  margin: 0;
  text-align: left;
  padding-left: 4px;
}

.upload-progress-sticky .CircularProgressbar .CircularProgressbar-path {
  stroke: #fff;
}
.upload-progress-sticky  .uploading-item-name{
  color: #fff;
  margin-left: 10px;
}
.upload-progress-sticky  .floating-percentage
{
  width: 40px;
}
.upload-progress-sticky .CircularProgressbar .CircularProgressbar-text{
  fill: #fff;
  font-size: 32px;
}
.upload-progress-sticky .fileuploader-cancel
{
  display: block;
  padding:0;
  border-radius: 6px;
  cursor: pointer;
  margin-left: auto;
  width: 23px;
  height: 23px;
  min-width: 23px;
  right: 6px;
  top: 1px;
  color: #111;
  background: #fff;
  padding-top: 2px;
}
.upload-progress-sticky .fileuploader-cancel svg{
  color: #fff;
  fill: #fff;
}

.post-360-button.icon-btn{
  width: 50px !important;
  height: 50px !important;
  min-height: 50px !important;
  min-width: 50px;
  border-radius: 8px;
  background-color: #F1731E;
  background-image: url(../../../images/iconsax-send.svg);
  background-repeat: no-repeat;
  padding: 0;
  background-position: 9px 10px;
  transition: all .0s ease-in-out;
  cursor: pointer;
 }
 .post-360-button.icon-btn:hover{
  background-position: 9px -30px !important;
  background-image: url(../../../images/iconsax-send.svg) !important;
  background-repeat: no-repeat !important;
  background-color:#FEEDE1 !important;
 }
 .post-360-button.icon-btn:focus{
  background-position: 9px -30px !important;
  background-image: url(../../../images/iconsax-send.svg) !important;
  background-repeat: no-repeat !important;
  background-color:#FEEDE1 !important;
 }
 .notifier > div:nth-child(1) div.toast-Cancel-theme:nth-child(1) {
    background: #EE473D !important;
    border: 1px solid #ED463C;
    box-shadow: 0px 1px 4px #F0C5C3;
 }
 .notifier > div:nth-child(1) div.toast-Cancel-theme:nth-child(1) > div{
  background: #EE473D !important;
 }

.unapprove-client {
  margin-left: auto;
  cursor: pointer;
  background: red;
  height: 22px;
  width: 23px;
  padding: -1px;
  /* padding: 8px 10px; */
  text-align: center;
  color: #fff!important;
  border-radius: 6px;
  padding-top: 3px;
}

.unapprove-client .material-icons.md-dark {
  color: #fff;
  font-size: 16px;
  margin-top: 3px;
}