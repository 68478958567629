/**
 * Admin Comment
 */

 #admin-comment {
   position: fixed;
   /* top: 91px; */
   top: 0;
   right: 0;
   width: 100%;
   height: calc( 100% );
   z-index: 999;
   background: #fff;
   max-width: 680px;
   padding: 40px;
   overflow-y: auto;
   overflow-x: hidden;
   border-radius: 5px;
   box-shadow: -20px 0px 25px 0px rgba(145, 145, 145, 0.1);
 }

 #comment-form {
  justify-content: center;
  align-items: center;
 }

 #comment-form .textarea {
   width: 80% !important
 }

 #comment-form button {
   height: 37px;
   width: 20%;
 }

 #admin-comment .title {
   font-size: 24px;
   margin-bottom: 24px;
   font-family: "ProximaNova-Bold";
   width: 80%;
 }

 #admin-comment.show {
   display: block;
 }

 #admin-comment.hide {
   display: none;
 }

 .close-comment-box {
   position: absolute;
   top: 0;
   right: 0;
   margin: 15px;
   font-size: 20px;
   color: #e0e3e4;
   cursor: pointer;
 }

 .close-comment-box:hover {
  color: #2e2f2f
 }

 .comment-header .title {
   width: 85%;
 }
 
 .comment-header button {
    width: 20%;
    height: 37px;
 }

 .comment-header .btn-gray-outline{
  background-color: #fff!important;
  color: #000!important;
  background: #fff;
  border: 1px solid #dbdbdb;
 }

 .comment-header .btn-green
 {
   background-color: #069482;
 }

 .single-comment p {
   white-space: pre-line
 }

 .single-comment .comment-box p {
  padding: 10px;
  font-weight: 400;
  font-size: 13px;
  color: #505050;
 }