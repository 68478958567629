.meet_btn{
    min-width: 180px;
    height: 45px;
    margin-right: 30px;
    background: #069482;
    font-size: 15px;
    font-weight: 500;
    border: none;
    border-radius: 8px;
    padding: 0 25px;
}
.meet_btn:hover{
    background:#004c3f;
}
.newmeeting_modal .modal-content{
    border: none !important;
    border-radius: 15px;
}
.newmeeting_modal .modal-header{
    border-top-left-radius: 15px !important;
    border-top-right-radius: 15px !important;
}
.newmeeting_modal .form-group label{
    color: #495057;
    font-size: 12px;
    font-weight: 500;
    margin: 0;
    padding: 0 0 4px 0;
}
.newmeeting_modal .form-group .form-control{
    border-radius: 8px;
    border: 1px solid #adb5bd;
    font-size: 15px;
    color: #212529;
    padding-left: 15px;
    padding-right: 15px;
    background: #fff !important;
    min-height: 48px;
}
.newmeeting_modal .form-group .form-control::placeholder{
    font-size: 13px;
    font-weight: 400;
    color: #838383;
}
.select_custom_meetiing .css-1s2u09g-control, .select_custom_meetiing .css-1pahdxg-control {
    border-radius: 8px;
    border: 1px solid #adb5bd !important;
    font-size: 15px;
    color: #212529;
    background: #fff !important;
    min-height: 48px;
    font-size: 13px;
    font-weight: 400;
    color: #838383;
    padding-left: 5px;
    box-shadow: none;
}
.sub_btn_meet{
    background: #069482;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    border: none !important;
    min-height: 45px;
    min-width: 150px;
}
.sub_btn_meet:hover{
    background: #004c3f;
}
.btn-can_btn_meet{
    background: #f22613;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    border: none !important;
    min-height: 45px;
    color: #fff;
}
.meeting_teams{
    display: inline-flex;
    flex-wrap: wrap;
}
.meeting_teams li{
    list-style: none;
    background: #84f4e6;
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 5px;
    font-size: 13px;
    border-radius: 5px;
    color: #004c3f;
}
.meeting_edit{
    width: 35px;
    height: 35px;
    background: #069482;
    border: none;
    min-width: 35px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    margin-right:5px;
}
.meeting_edit .material-icons, .meeting_delete .material-icons{
    color: #fff;
    font-size: 17px;
}
.meeting_delete{
    width: 35px;
    height: 35px;
    background: #f22613;
    border: none;
    min-width: 35px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff; 
}
.meeting_edit.text_btn{
    min-width: 100px;
    padding: 0 15px;
}
.meeting_delete.text_btn{
    min-width: 100px;
    padding: 0 15px;
    background: #284387;
}
.meeting_details{
    background: #DBE5EC;
    padding: 30px;
    display: flex;
    gap: 30px;
    justify-content: space-evenly;
}
.meeting_details div span{
    color: #495057;
    font-size: 12px;
    font-weight: 500;
    margin: 0;
    padding: 0 0 6px 0;
}
.meeting_details div h3{
    font-size: 16px;
    color: #212529;
}
.add_meeting_deatil{
    padding: 2rem;
}
.add_meeting_deatil .ql-toolbar{
    border: none;
}
.add_meeting_deatil .ql-container{
    border-radius: 12px;
    border: 1px solid #838383 !important;
    min-height: 150px;
}
.add_meeting_deatil h3.section_tittile{
    color: #212529;
    padding:30px 0 15px 0;
    margin: 0;
    font-size: 20px;
}
.add_checklist {
    display: flex;
}
.add_checklist input{
    width: 80%;
    min-height: 50px;
    border: 1px solid #838383;
    padding:0 15px;
    border-radius: 8px;
}
.add_checklist .add_btn{
    min-width: 50px;
    max-width: 50px;
    width: 50px;
    height: 50px;
    background: #069482;
    border: none !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
}
.add_checklist .add_btn:hover{
    background: #004c3f;
}
.add_checklist .add_btn .material-icons{
    color: #fff;
}
.add_checklist .add_btn:focus{
    background: #004c3f !important;
    color: #fff;
}
ul.check_list_prev{
    margin: 0;
    padding: 0;
}
ul.check_list_prev li{
    font-size: 15px;
    color: #212529;
    list-style: none;
    position: relative;
    margin-top: 12px;
    padding-left: 20px;
    display: flex;
    align-items: center;
}
ul.check_list_prev li::after{
    position: absolute;
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background: #fff;
    border: 1px solid #004c3f;
    left: 0;
    top: 11px;
}
ul.check_list_prev li .close_icon{
    width: 30px;
    height: 30px;
    background: #f22613;
    border: none !important;
    border-radius: 8px;
    margin-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
ul.check_list_prev li .close_icon .material-icons{
    color: #fff;
    font-size: 14px;
}
.preview_meeting_details{
    margin-top: 30px;
    background: #fff;
    border: 1px solid #e6effa;
    border-radius: 12px;
    padding: 25px;
    transition: all 0.5s ease-in-out;
    border-radius: 12px;
    border: 0px solid #bfc4ca !important;
    position: relative;
}
.preview_meeting_details p{
    color: #6c757d;
    font-size: 14px;
    line-height: 26px;
}
.check_list_previw_box p{
    font-weight: 500;
    color: #212529;
    padding-left: 10px;
}
.add_comment_meeting{
    background: #fff;
    margin-top: 30px;
    border-radius: 12px;
}
.momdetailpage .edit_btn{
    position: absolute;
    background: transparent !important;
    border-color: transparent  !important;
    color: #069482;
    border-radius:8px;
    right: 15px;
    top: 10px;
}
.momdetailpage .edit_btn:focus{
    color: #069482 !important;
    border:none !important ; 
    background:transparent !important;
}
.add_comment_meeting .comment_previw{
    padding: 20px;
    background: #eff5f9;
    margin-top: 20px;
    border-radius: 12px;
    position: relative;
}
.add_comment_meeting .comment_previw .user_pic{
    min-width: 35px;
    width: 35px;
    height: 35px;
    border-radius: 35px;
    background: #284387;
    margin-right: 10px;
}
.add_comment_meeting .comment_previw  .user_details h3{
    font-size: 15px;
    color: #212529;
    font-weight: 500;
    margin: 0;
    padding: 0 0 3px 0;
}
.add_comment_meeting .comment_previw  .user_details h6{
    color: #838383;
    font-size: 12px;
    margin: 0;
    padding: 0;
    font-weight: 400;
    
}
.add_comment_meeting .comment_previw .comment_contnet_preview{
    margin-top: 20px;
  
}
.add_comment_meeting .comment_previw .comment_contnet_preview p{
    font-size: 14px;
}
